/* tslint:disable */
/* eslint-disable */
/**
 * oncokb-curation API
 * oncokb-curation API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AbstractTextDTO
 */
export interface AbstractTextDTO {
  /**
   *
   * @type {string}
   * @memberof AbstractTextDTO
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof AbstractTextDTO
   */
  nlmCategory?: string;
  /**
   *
   * @type {string}
   * @memberof AbstractTextDTO
   */
  value?: string;
}
/**
 *
 * @export
 * @interface AddEnsemblGeneBody
 */
export interface AddEnsemblGeneBody {
  /**
   *
   * @type {string}
   * @memberof AddEnsemblGeneBody
   */
  referenceGenome: string;
  /**
   *
   * @type {number}
   * @memberof AddEnsemblGeneBody
   */
  entrezGeneId: number;
  /**
   *
   * @type {string}
   * @memberof AddEnsemblGeneBody
   */
  ensemblGeneId: string;
  /**
   *
   * @type {boolean}
   * @memberof AddEnsemblGeneBody
   */
  canonical?: boolean;
}
/**
 *
 * @export
 * @interface AddTranscriptBody
 */
export interface AddTranscriptBody {
  /**
   *
   * @type {number}
   * @memberof AddTranscriptBody
   */
  entrezGeneId: number;
  /**
   *
   * @type {string}
   * @memberof AddTranscriptBody
   */
  referenceGenome: string;
  /**
   *
   * @type {string}
   * @memberof AddTranscriptBody
   */
  ensemblTranscriptId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AddTranscriptBody
   */
  flags: Array<AddTranscriptBodyFlagsEnum>;
  /**
   *
   * @type {boolean}
   * @memberof AddTranscriptBody
   */
  canonical?: boolean;
}

export const AddTranscriptBodyFlagsEnum = {
  ManeSelect: 'MANE_SELECT',
  ManePlusClinical: 'MANE_PLUS_CLINICAL',
  GnCanonical: 'GN_CANONICAL',
  Oncokb: 'ONCOKB',
  EnsemblCanonical: 'ENSEMBL_CANONICAL',
} as const;

export type AddTranscriptBodyFlagsEnum = (typeof AddTranscriptBodyFlagsEnum)[keyof typeof AddTranscriptBodyFlagsEnum];

/**
 *
 * @export
 * @interface AdditionalInfoDTO
 */
export interface AdditionalInfoDTO {
  /**
   *
   * @type {Array<AbstractTextDTO>}
   * @memberof AdditionalInfoDTO
   */
  abstractTexts?: Array<AbstractTextDTO>;
  /**
   *
   * @type {JournalDTO}
   * @memberof AdditionalInfoDTO
   */
  journal?: JournalDTO;
  /**
   *
   * @type {string}
   * @memberof AdditionalInfoDTO
   */
  completedDate?: string;
  /**
   *
   * @type {string}
   * @memberof AdditionalInfoDTO
   */
  revisedDate?: string;
  /**
   *
   * @type {Array<DataBankDTO>}
   * @memberof AdditionalInfoDTO
   */
  dataBanks?: Array<DataBankDTO>;
}
/**
 *
 * @export
 * @interface AllReferenceTranscriptSuggestionVM
 */
export interface AllReferenceTranscriptSuggestionVM {
  /**
   *
   * @type {TranscriptSuggestionVM}
   * @memberof AllReferenceTranscriptSuggestionVM
   */
  grch37?: TranscriptSuggestionVM;
  /**
   *
   * @type {TranscriptSuggestionVM}
   * @memberof AllReferenceTranscriptSuggestionVM
   */
  grch38?: TranscriptSuggestionVM;
}
/**
 *
 * @export
 * @interface AlleleState
 */
export interface AlleleState {
  /**
   *
   * @type {number}
   * @memberof AlleleState
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AlleleState
   */
  name: string;
  /**
   *
   * @type {Array<GenomicIndicator>}
   * @memberof AlleleState
   */
  genomicIndicators?: Array<GenomicIndicator>;
}
/**
 *
 * @export
 * @interface Alteration
 */
export interface Alteration {
  /**
   *
   * @type {number}
   * @memberof Alteration
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  type: AlterationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  alteration: string;
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  proteinChange: string;
  /**
   *
   * @type {number}
   * @memberof Alteration
   */
  start?: number;
  /**
   *
   * @type {number}
   * @memberof Alteration
   */
  end?: number;
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  refResidues?: string;
  /**
   *
   * @type {string}
   * @memberof Alteration
   */
  variantResidues?: string;
  /**
   *
   * @type {Array<Flag>}
   * @memberof Alteration
   */
  flags?: Array<Flag>;
  /**
   *
   * @type {Array<Gene>}
   * @memberof Alteration
   */
  genes?: Array<Gene>;
  /**
   *
   * @type {Array<Transcript>}
   * @memberof Alteration
   */
  transcripts?: Array<Transcript>;
  /**
   *
   * @type {Consequence}
   * @memberof Alteration
   */
  consequence?: Consequence;
  /**
   *
   * @type {Array<Association>}
   * @memberof Alteration
   */
  associations?: Array<Association>;
}

export const AlterationTypeEnum = {
  GenomicChange: 'GENOMIC_CHANGE',
  CdnaChange: 'CDNA_CHANGE',
  ProteinChange: 'PROTEIN_CHANGE',
  Mutation: 'MUTATION',
  CopyNumberAlteration: 'COPY_NUMBER_ALTERATION',
  StructuralVariant: 'STRUCTURAL_VARIANT',
  Any: 'ANY',
  Unknown: 'UNKNOWN',
  Na: 'NA',
} as const;

export type AlterationTypeEnum = (typeof AlterationTypeEnum)[keyof typeof AlterationTypeEnum];

/**
 *
 * @export
 * @interface AlterationAnnotationStatus
 */
export interface AlterationAnnotationStatus {
  /**
   *
   * @type {Alteration}
   * @memberof AlterationAnnotationStatus
   */
  entity?: Alteration;
  /**
   *
   * @type {string}
   * @memberof AlterationAnnotationStatus
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof AlterationAnnotationStatus
   */
  type?: AlterationAnnotationStatusTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AlterationAnnotationStatus
   */
  queryId?: string;
  /**
   *
   * @type {AnnotationDTO}
   * @memberof AlterationAnnotationStatus
   */
  annotation?: AnnotationDTO;
  /**
   *
   * @type {boolean}
   * @memberof AlterationAnnotationStatus
   */
  ok?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AlterationAnnotationStatus
   */
  warning?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AlterationAnnotationStatus
   */
  error?: boolean;
}

export const AlterationAnnotationStatusTypeEnum = {
  Ok: 'OK',
  Warning: 'WARNING',
  Error: 'ERROR',
} as const;

export type AlterationAnnotationStatusTypeEnum =
  (typeof AlterationAnnotationStatusTypeEnum)[keyof typeof AlterationAnnotationStatusTypeEnum];

/**
 *
 * @export
 * @interface AlterationCriteria
 */
export interface AlterationCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof AlterationCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {AlterationTypeFilter}
   * @memberof AlterationCriteria
   */
  type?: AlterationTypeFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof AlterationCriteria
   */
  name?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof AlterationCriteria
   */
  alteration?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof AlterationCriteria
   */
  proteinChange?: StringFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof AlterationCriteria
   */
  start?: IntegerFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof AlterationCriteria
   */
  end?: IntegerFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof AlterationCriteria
   */
  refResidues?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof AlterationCriteria
   */
  variantResidues?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof AlterationCriteria
   */
  flagId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof AlterationCriteria
   */
  geneId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof AlterationCriteria
   */
  transcriptId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof AlterationCriteria
   */
  consequenceId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof AlterationCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof AlterationCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface AlterationTypeFilter
 */
export interface AlterationTypeFilter {
  /**
   *
   * @type {string}
   * @memberof AlterationTypeFilter
   */
  equals?: AlterationTypeFilterEqualsEnum;
  /**
   *
   * @type {string}
   * @memberof AlterationTypeFilter
   */
  notEquals?: AlterationTypeFilterNotEqualsEnum;
  /**
   *
   * @type {boolean}
   * @memberof AlterationTypeFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof AlterationTypeFilter
   */
  in?: Array<AlterationTypeFilterInEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof AlterationTypeFilter
   */
  notIn?: Array<AlterationTypeFilterNotInEnum>;
}

export const AlterationTypeFilterEqualsEnum = {
  GenomicChange: 'GENOMIC_CHANGE',
  CdnaChange: 'CDNA_CHANGE',
  ProteinChange: 'PROTEIN_CHANGE',
  Mutation: 'MUTATION',
  CopyNumberAlteration: 'COPY_NUMBER_ALTERATION',
  StructuralVariant: 'STRUCTURAL_VARIANT',
  Any: 'ANY',
  Unknown: 'UNKNOWN',
  Na: 'NA',
} as const;

export type AlterationTypeFilterEqualsEnum = (typeof AlterationTypeFilterEqualsEnum)[keyof typeof AlterationTypeFilterEqualsEnum];
export const AlterationTypeFilterNotEqualsEnum = {
  GenomicChange: 'GENOMIC_CHANGE',
  CdnaChange: 'CDNA_CHANGE',
  ProteinChange: 'PROTEIN_CHANGE',
  Mutation: 'MUTATION',
  CopyNumberAlteration: 'COPY_NUMBER_ALTERATION',
  StructuralVariant: 'STRUCTURAL_VARIANT',
  Any: 'ANY',
  Unknown: 'UNKNOWN',
  Na: 'NA',
} as const;

export type AlterationTypeFilterNotEqualsEnum = (typeof AlterationTypeFilterNotEqualsEnum)[keyof typeof AlterationTypeFilterNotEqualsEnum];
export const AlterationTypeFilterInEnum = {
  GenomicChange: 'GENOMIC_CHANGE',
  CdnaChange: 'CDNA_CHANGE',
  ProteinChange: 'PROTEIN_CHANGE',
  Mutation: 'MUTATION',
  CopyNumberAlteration: 'COPY_NUMBER_ALTERATION',
  StructuralVariant: 'STRUCTURAL_VARIANT',
  Any: 'ANY',
  Unknown: 'UNKNOWN',
  Na: 'NA',
} as const;

export type AlterationTypeFilterInEnum = (typeof AlterationTypeFilterInEnum)[keyof typeof AlterationTypeFilterInEnum];
export const AlterationTypeFilterNotInEnum = {
  GenomicChange: 'GENOMIC_CHANGE',
  CdnaChange: 'CDNA_CHANGE',
  ProteinChange: 'PROTEIN_CHANGE',
  Mutation: 'MUTATION',
  CopyNumberAlteration: 'COPY_NUMBER_ALTERATION',
  StructuralVariant: 'STRUCTURAL_VARIANT',
  Any: 'ANY',
  Unknown: 'UNKNOWN',
  Na: 'NA',
} as const;

export type AlterationTypeFilterNotInEnum = (typeof AlterationTypeFilterNotInEnum)[keyof typeof AlterationTypeFilterNotInEnum];

/**
 *
 * @export
 * @interface AnnotateAlterationBody
 */
export interface AnnotateAlterationBody {
  /**
   *
   * @type {string}
   * @memberof AnnotateAlterationBody
   */
  queryId?: string;
  /**
   *
   * @type {string}
   * @memberof AnnotateAlterationBody
   */
  referenceGenome?: AnnotateAlterationBodyReferenceGenomeEnum;
  /**
   *
   * @type {Alteration}
   * @memberof AnnotateAlterationBody
   */
  alteration?: Alteration;
}

export const AnnotateAlterationBodyReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type AnnotateAlterationBodyReferenceGenomeEnum =
  (typeof AnnotateAlterationBodyReferenceGenomeEnum)[keyof typeof AnnotateAlterationBodyReferenceGenomeEnum];

/**
 *
 * @export
 * @interface AnnotationDTO
 */
export interface AnnotationDTO {
  /**
   *
   * @type {HotspotInfoDTO}
   * @memberof AnnotationDTO
   */
  hotspot?: HotspotInfoDTO;
  /**
   *
   * @type {Array<ProteinExonDTO>}
   * @memberof AnnotationDTO
   */
  exons?: Array<ProteinExonDTO>;
}
/**
 *
 * @export
 * @interface Article
 */
export interface Article {
  /**
   *
   * @type {number}
   * @memberof Article
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  type: ArticleTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  uid?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  link?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  authors?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  additionalInfo?: string;
  /**
   *
   * @type {Array<Flag>}
   * @memberof Article
   */
  flags?: Array<Flag>;
  /**
   *
   * @type {Array<Synonym>}
   * @memberof Article
   */
  synonyms?: Array<Synonym>;
  /**
   *
   * @type {Array<Association>}
   * @memberof Article
   */
  associations?: Array<Association>;
  /**
   *
   * @type {Array<FdaSubmission>}
   * @memberof Article
   */
  fdaSubmissions?: Array<FdaSubmission>;
}

export const ArticleTypeEnum = {
  Pubmed: 'PUBMED',
  Abstract: 'ABSTRACT',
  Reference: 'REFERENCE',
  FdadrugLetter: 'FDADRUG_LETTER',
  FdadrugLabel: 'FDADRUG_LABEL',
  FdadrugSummary: 'FDADRUG_SUMMARY',
  FdadrugSummaryReview: 'FDADRUG_SUMMARY_REVIEW',
} as const;

export type ArticleTypeEnum = (typeof ArticleTypeEnum)[keyof typeof ArticleTypeEnum];

/**
 *
 * @export
 * @interface ArticleCriteria
 */
export interface ArticleCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof ArticleCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {ArticleTypeFilter}
   * @memberof ArticleCriteria
   */
  type?: ArticleTypeFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ArticleCriteria
   */
  uid?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ArticleCriteria
   */
  link?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ArticleCriteria
   */
  authors?: StringFilter;
  /**
   *
   * @type {InstantFilter}
   * @memberof ArticleCriteria
   */
  date?: InstantFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ArticleCriteria
   */
  flagId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ArticleCriteria
   */
  synonymId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ArticleCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof ArticleCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface ArticleTypeFilter
 */
export interface ArticleTypeFilter {
  /**
   *
   * @type {string}
   * @memberof ArticleTypeFilter
   */
  equals?: ArticleTypeFilterEqualsEnum;
  /**
   *
   * @type {string}
   * @memberof ArticleTypeFilter
   */
  notEquals?: ArticleTypeFilterNotEqualsEnum;
  /**
   *
   * @type {boolean}
   * @memberof ArticleTypeFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ArticleTypeFilter
   */
  in?: Array<ArticleTypeFilterInEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof ArticleTypeFilter
   */
  notIn?: Array<ArticleTypeFilterNotInEnum>;
}

export const ArticleTypeFilterEqualsEnum = {
  Pubmed: 'PUBMED',
  Abstract: 'ABSTRACT',
  Reference: 'REFERENCE',
  FdadrugLetter: 'FDADRUG_LETTER',
  FdadrugLabel: 'FDADRUG_LABEL',
  FdadrugSummary: 'FDADRUG_SUMMARY',
  FdadrugSummaryReview: 'FDADRUG_SUMMARY_REVIEW',
} as const;

export type ArticleTypeFilterEqualsEnum = (typeof ArticleTypeFilterEqualsEnum)[keyof typeof ArticleTypeFilterEqualsEnum];
export const ArticleTypeFilterNotEqualsEnum = {
  Pubmed: 'PUBMED',
  Abstract: 'ABSTRACT',
  Reference: 'REFERENCE',
  FdadrugLetter: 'FDADRUG_LETTER',
  FdadrugLabel: 'FDADRUG_LABEL',
  FdadrugSummary: 'FDADRUG_SUMMARY',
  FdadrugSummaryReview: 'FDADRUG_SUMMARY_REVIEW',
} as const;

export type ArticleTypeFilterNotEqualsEnum = (typeof ArticleTypeFilterNotEqualsEnum)[keyof typeof ArticleTypeFilterNotEqualsEnum];
export const ArticleTypeFilterInEnum = {
  Pubmed: 'PUBMED',
  Abstract: 'ABSTRACT',
  Reference: 'REFERENCE',
  FdadrugLetter: 'FDADRUG_LETTER',
  FdadrugLabel: 'FDADRUG_LABEL',
  FdadrugSummary: 'FDADRUG_SUMMARY',
  FdadrugSummaryReview: 'FDADRUG_SUMMARY_REVIEW',
} as const;

export type ArticleTypeFilterInEnum = (typeof ArticleTypeFilterInEnum)[keyof typeof ArticleTypeFilterInEnum];
export const ArticleTypeFilterNotInEnum = {
  Pubmed: 'PUBMED',
  Abstract: 'ABSTRACT',
  Reference: 'REFERENCE',
  FdadrugLetter: 'FDADRUG_LETTER',
  FdadrugLabel: 'FDADRUG_LABEL',
  FdadrugSummary: 'FDADRUG_SUMMARY',
  FdadrugSummaryReview: 'FDADRUG_SUMMARY_REVIEW',
} as const;

export type ArticleTypeFilterNotInEnum = (typeof ArticleTypeFilterNotInEnum)[keyof typeof ArticleTypeFilterNotInEnum];

/**
 *
 * @export
 * @interface Association
 */
export interface Association {
  /**
   *
   * @type {number}
   * @memberof Association
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Association
   */
  name?: string;
  /**
   *
   * @type {Array<Rule>}
   * @memberof Association
   */
  rules?: Array<Rule>;
  /**
   *
   * @type {Array<Alteration>}
   * @memberof Association
   */
  alterations?: Array<Alteration>;
  /**
   *
   * @type {Array<Article>}
   * @memberof Association
   */
  articles?: Array<Article>;
  /**
   *
   * @type {Array<CancerType>}
   * @memberof Association
   */
  cancerTypes?: Array<CancerType>;
  /**
   *
   * @type {Array<Drug>}
   * @memberof Association
   */
  drugs?: Array<Drug>;
  /**
   *
   * @type {Evidence}
   * @memberof Association
   */
  evidence?: Evidence;
  /**
   *
   * @type {Array<ClinicalTrial>}
   * @memberof Association
   */
  clinicalTrials?: Array<ClinicalTrial>;
  /**
   *
   * @type {Array<ClinicalTrialArm>}
   * @memberof Association
   */
  clinicalTrialArms?: Array<ClinicalTrialArm>;
  /**
   *
   * @type {Array<EligibilityCriteria>}
   * @memberof Association
   */
  eligibilityCriteria?: Array<EligibilityCriteria>;
  /**
   *
   * @type {Array<FdaSubmission>}
   * @memberof Association
   */
  fdaSubmissions?: Array<FdaSubmission>;
  /**
   *
   * @type {Array<GenomicIndicator>}
   * @memberof Association
   */
  genomicIndicators?: Array<GenomicIndicator>;
}
/**
 *
 * @export
 * @interface AuthInfoVM
 */
export interface AuthInfoVM {
  /**
   *
   * @type {string}
   * @memberof AuthInfoVM
   */
  issuer?: string;
  /**
   *
   * @type {string}
   * @memberof AuthInfoVM
   */
  clientId?: string;
}
/**
 *
 * @export
 * @interface BooleanFilter
 */
export interface BooleanFilter {
  /**
   *
   * @type {boolean}
   * @memberof BooleanFilter
   */
  equals?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BooleanFilter
   */
  notEquals?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BooleanFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<boolean>}
   * @memberof BooleanFilter
   */
  in?: Array<boolean>;
  /**
   *
   * @type {Array<boolean>}
   * @memberof BooleanFilter
   */
  notIn?: Array<boolean>;
}
/**
 *
 * @export
 * @interface CancerType
 */
export interface CancerType {
  /**
   *
   * @type {number}
   * @memberof CancerType
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CancerType
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CancerType
   */
  color?: string;
  /**
   *
   * @type {number}
   * @memberof CancerType
   */
  level: number;
  /**
   *
   * @type {string}
   * @memberof CancerType
   */
  mainType: string;
  /**
   *
   * @type {string}
   * @memberof CancerType
   */
  subtype?: string;
  /**
   *
   * @type {string}
   * @memberof CancerType
   */
  tissue?: string;
  /**
   *
   * @type {string}
   * @memberof CancerType
   */
  tumorForm: CancerTypeTumorFormEnum;
  /**
   *
   * @type {Array<CancerType>}
   * @memberof CancerType
   */
  children?: Array<CancerType>;
  /**
   *
   * @type {Array<Synonym>}
   * @memberof CancerType
   */
  synonyms?: Array<Synonym>;
  /**
   *
   * @type {CancerType}
   * @memberof CancerType
   */
  parent?: CancerType;
  /**
   *
   * @type {Array<Association>}
   * @memberof CancerType
   */
  associations?: Array<Association>;
}

export const CancerTypeTumorFormEnum = {
  Solid: 'SOLID',
  Liquid: 'LIQUID',
  Mixed: 'MIXED',
} as const;

export type CancerTypeTumorFormEnum = (typeof CancerTypeTumorFormEnum)[keyof typeof CancerTypeTumorFormEnum];

/**
 *
 * @export
 * @interface CancerTypeCriteria
 */
export interface CancerTypeCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof CancerTypeCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof CancerTypeCriteria
   */
  code?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof CancerTypeCriteria
   */
  color?: StringFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof CancerTypeCriteria
   */
  level?: IntegerFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof CancerTypeCriteria
   */
  mainType?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof CancerTypeCriteria
   */
  subtype?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof CancerTypeCriteria
   */
  tissue?: StringFilter;
  /**
   *
   * @type {TumorFormFilter}
   * @memberof CancerTypeCriteria
   */
  tumorForm?: TumorFormFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof CancerTypeCriteria
   */
  childrenId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof CancerTypeCriteria
   */
  synonymId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof CancerTypeCriteria
   */
  parentId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof CancerTypeCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof CancerTypeCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface CategoricalAlteration
 */
export interface CategoricalAlteration {
  /**
   *
   * @type {number}
   * @memberof CategoricalAlteration
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CategoricalAlteration
   */
  alterationType: CategoricalAlterationAlterationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CategoricalAlteration
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CategoricalAlteration
   */
  name: string;
  /**
   *
   * @type {Consequence}
   * @memberof CategoricalAlteration
   */
  consequence?: Consequence;
}

export const CategoricalAlterationAlterationTypeEnum = {
  GenomicChange: 'GENOMIC_CHANGE',
  CdnaChange: 'CDNA_CHANGE',
  ProteinChange: 'PROTEIN_CHANGE',
  Mutation: 'MUTATION',
  CopyNumberAlteration: 'COPY_NUMBER_ALTERATION',
  StructuralVariant: 'STRUCTURAL_VARIANT',
  Any: 'ANY',
  Unknown: 'UNKNOWN',
  Na: 'NA',
} as const;

export type CategoricalAlterationAlterationTypeEnum =
  (typeof CategoricalAlterationAlterationTypeEnum)[keyof typeof CategoricalAlterationAlterationTypeEnum];

/**
 *
 * @export
 * @interface ClinicalTrial
 */
export interface ClinicalTrial {
  /**
   *
   * @type {number}
   * @memberof ClinicalTrial
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicalTrial
   */
  nctId?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicalTrial
   */
  briefTitle: string;
  /**
   *
   * @type {string}
   * @memberof ClinicalTrial
   */
  phase?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicalTrial
   */
  status?: string;
  /**
   *
   * @type {Array<ClinicalTrialArm>}
   * @memberof ClinicalTrial
   */
  clinicalTrialArms?: Array<ClinicalTrialArm>;
  /**
   *
   * @type {Array<EligibilityCriteria>}
   * @memberof ClinicalTrial
   */
  eligibilityCriteria?: Array<EligibilityCriteria>;
  /**
   *
   * @type {Array<Association>}
   * @memberof ClinicalTrial
   */
  associations?: Array<Association>;
}
/**
 *
 * @export
 * @interface ClinicalTrialArm
 */
export interface ClinicalTrialArm {
  /**
   *
   * @type {number}
   * @memberof ClinicalTrialArm
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicalTrialArm
   */
  name: string;
  /**
   *
   * @type {Array<Association>}
   * @memberof ClinicalTrialArm
   */
  associations?: Array<Association>;
  /**
   *
   * @type {ClinicalTrial}
   * @memberof ClinicalTrialArm
   */
  clinicalTrial?: ClinicalTrial;
}
/**
 *
 * @export
 * @interface ClinicalTrialArmCriteria
 */
export interface ClinicalTrialArmCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof ClinicalTrialArmCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ClinicalTrialArmCriteria
   */
  name?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ClinicalTrialArmCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ClinicalTrialArmCriteria
   */
  clinicalTrialId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof ClinicalTrialArmCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface ClinicalTrialCriteria
 */
export interface ClinicalTrialCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof ClinicalTrialCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ClinicalTrialCriteria
   */
  nctId?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ClinicalTrialCriteria
   */
  briefTitle?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ClinicalTrialCriteria
   */
  phase?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ClinicalTrialCriteria
   */
  status?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ClinicalTrialCriteria
   */
  clinicalTrialArmId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ClinicalTrialCriteria
   */
  eligibilityCriteriaId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ClinicalTrialCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof ClinicalTrialCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface ClustalOResp
 */
export interface ClustalOResp {
  /**
   *
   * @type {string}
   * @memberof ClustalOResp
   */
  fasta?: string;
  /**
   *
   * @type {string}
   * @memberof ClustalOResp
   */
  clustalo?: string;
  /**
   *
   * @type {string}
   * @memberof ClustalOResp
   */
  mview?: string;
}
/**
 *
 * @export
 * @interface CompanionDiagnosticDevice
 */
export interface CompanionDiagnosticDevice {
  /**
   *
   * @type {number}
   * @memberof CompanionDiagnosticDevice
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CompanionDiagnosticDevice
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CompanionDiagnosticDevice
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof CompanionDiagnosticDevice
   */
  indicationDetails?: string;
  /**
   *
   * @type {string}
   * @memberof CompanionDiagnosticDevice
   */
  platformType?: string;
  /**
   *
   * @type {string}
   * @memberof CompanionDiagnosticDevice
   */
  lastUpdated?: string;
}
/**
 *
 * @export
 * @interface CompanionDiagnosticDeviceCriteria
 */
export interface CompanionDiagnosticDeviceCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof CompanionDiagnosticDeviceCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof CompanionDiagnosticDeviceCriteria
   */
  name?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof CompanionDiagnosticDeviceCriteria
   */
  manufacturer?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof CompanionDiagnosticDeviceCriteria
   */
  indicationDetails?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof CompanionDiagnosticDeviceCriteria
   */
  platformType?: StringFilter;
  /**
   *
   * @type {InstantFilter}
   * @memberof CompanionDiagnosticDeviceCriteria
   */
  lastUpdated?: InstantFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof CompanionDiagnosticDeviceCriteria
   */
  fdaSubmissionId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof CompanionDiagnosticDeviceCriteria
   */
  specimenTypeId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof CompanionDiagnosticDeviceCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface Consequence
 */
export interface Consequence {
  /**
   *
   * @type {number}
   * @memberof Consequence
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Consequence
   */
  term: string;
  /**
   *
   * @type {string}
   * @memberof Consequence
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof Consequence
   */
  isGenerallyTruncating: boolean;
  /**
   *
   * @type {string}
   * @memberof Consequence
   */
  description?: string;
}
/**
 *
 * @export
 * @interface ConsequenceCriteria
 */
export interface ConsequenceCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof ConsequenceCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ConsequenceCriteria
   */
  term?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ConsequenceCriteria
   */
  name?: StringFilter;
  /**
   *
   * @type {BooleanFilter}
   * @memberof ConsequenceCriteria
   */
  isGenerallyTruncating?: BooleanFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof ConsequenceCriteria
   */
  description?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ConsequenceCriteria
   */
  alterationId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof ConsequenceCriteria
   */
  categoricalAlterationId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof ConsequenceCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface DataBankDTO
 */
export interface DataBankDTO {
  /**
   *
   * @type {string}
   * @memberof DataBankDTO
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataBankDTO
   */
  accessionNumbers?: Array<string>;
}
/**
 *
 * @export
 * @interface Drug
 */
export interface Drug {
  /**
   *
   * @type {number}
   * @memberof Drug
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Drug
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof Drug
   */
  name?: string;
  /**
   *
   * @type {NciThesaurus}
   * @memberof Drug
   */
  nciThesaurus?: NciThesaurus;
  /**
   *
   * @type {Array<FdaDrug>}
   * @memberof Drug
   */
  fdaDrugs?: Array<FdaDrug>;
  /**
   *
   * @type {Array<Flag>}
   * @memberof Drug
   */
  flags?: Array<Flag>;
  /**
   *
   * @type {Array<Association>}
   * @memberof Drug
   */
  associations?: Array<Association>;
}
/**
 *
 * @export
 * @interface DrugCriteria
 */
export interface DrugCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof DrugCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof DrugCriteria
   */
  uuid?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof DrugCriteria
   */
  name?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof DrugCriteria
   */
  ncitCode?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof DrugCriteria
   */
  flagId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof DrugCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof DrugCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface EligibilityCriteria
 */
export interface EligibilityCriteria {
  /**
   *
   * @type {number}
   * @memberof EligibilityCriteria
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof EligibilityCriteria
   */
  type: EligibilityCriteriaTypeEnum;
  /**
   *
   * @type {number}
   * @memberof EligibilityCriteria
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof EligibilityCriteria
   */
  criteria?: string;
  /**
   *
   * @type {Array<Association>}
   * @memberof EligibilityCriteria
   */
  associations?: Array<Association>;
  /**
   *
   * @type {ClinicalTrial}
   * @memberof EligibilityCriteria
   */
  clinicalTrial?: ClinicalTrial;
}

export const EligibilityCriteriaTypeEnum = {
  Inclusion: 'INCLUSION',
  Exclusion: 'EXCLUSION',
} as const;

export type EligibilityCriteriaTypeEnum = (typeof EligibilityCriteriaTypeEnum)[keyof typeof EligibilityCriteriaTypeEnum];

/**
 *
 * @export
 * @interface EligibilityCriteriaCriteria
 */
export interface EligibilityCriteriaCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof EligibilityCriteriaCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {EligibilityCriteriaTypeFilter}
   * @memberof EligibilityCriteriaCriteria
   */
  type?: EligibilityCriteriaTypeFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof EligibilityCriteriaCriteria
   */
  priority?: IntegerFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof EligibilityCriteriaCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof EligibilityCriteriaCriteria
   */
  clinicalTrialId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof EligibilityCriteriaCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface EligibilityCriteriaTypeFilter
 */
export interface EligibilityCriteriaTypeFilter {
  /**
   *
   * @type {string}
   * @memberof EligibilityCriteriaTypeFilter
   */
  equals?: EligibilityCriteriaTypeFilterEqualsEnum;
  /**
   *
   * @type {string}
   * @memberof EligibilityCriteriaTypeFilter
   */
  notEquals?: EligibilityCriteriaTypeFilterNotEqualsEnum;
  /**
   *
   * @type {boolean}
   * @memberof EligibilityCriteriaTypeFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof EligibilityCriteriaTypeFilter
   */
  in?: Array<EligibilityCriteriaTypeFilterInEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof EligibilityCriteriaTypeFilter
   */
  notIn?: Array<EligibilityCriteriaTypeFilterNotInEnum>;
}

export const EligibilityCriteriaTypeFilterEqualsEnum = {
  Inclusion: 'INCLUSION',
  Exclusion: 'EXCLUSION',
} as const;

export type EligibilityCriteriaTypeFilterEqualsEnum =
  (typeof EligibilityCriteriaTypeFilterEqualsEnum)[keyof typeof EligibilityCriteriaTypeFilterEqualsEnum];
export const EligibilityCriteriaTypeFilterNotEqualsEnum = {
  Inclusion: 'INCLUSION',
  Exclusion: 'EXCLUSION',
} as const;

export type EligibilityCriteriaTypeFilterNotEqualsEnum =
  (typeof EligibilityCriteriaTypeFilterNotEqualsEnum)[keyof typeof EligibilityCriteriaTypeFilterNotEqualsEnum];
export const EligibilityCriteriaTypeFilterInEnum = {
  Inclusion: 'INCLUSION',
  Exclusion: 'EXCLUSION',
} as const;

export type EligibilityCriteriaTypeFilterInEnum =
  (typeof EligibilityCriteriaTypeFilterInEnum)[keyof typeof EligibilityCriteriaTypeFilterInEnum];
export const EligibilityCriteriaTypeFilterNotInEnum = {
  Inclusion: 'INCLUSION',
  Exclusion: 'EXCLUSION',
} as const;

export type EligibilityCriteriaTypeFilterNotInEnum =
  (typeof EligibilityCriteriaTypeFilterNotInEnum)[keyof typeof EligibilityCriteriaTypeFilterNotInEnum];

/**
 *
 * @export
 * @interface EnsemblGene
 */
export interface EnsemblGene {
  /**
   *
   * @type {number}
   * @memberof EnsemblGene
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof EnsemblGene
   */
  referenceGenome?: EnsemblGeneReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof EnsemblGene
   */
  ensemblGeneId: string;
  /**
   *
   * @type {boolean}
   * @memberof EnsemblGene
   */
  canonical: boolean;
  /**
   *
   * @type {number}
   * @memberof EnsemblGene
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof EnsemblGene
   */
  end: number;
  /**
   *
   * @type {number}
   * @memberof EnsemblGene
   */
  strand: number;
  /**
   *
   * @type {Array<Transcript>}
   * @memberof EnsemblGene
   */
  transcripts?: Array<Transcript>;
  /**
   *
   * @type {Gene}
   * @memberof EnsemblGene
   */
  gene?: Gene;
  /**
   *
   * @type {SeqRegion}
   * @memberof EnsemblGene
   */
  seqRegion?: SeqRegion;
}

export const EnsemblGeneReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type EnsemblGeneReferenceGenomeEnum = (typeof EnsemblGeneReferenceGenomeEnum)[keyof typeof EnsemblGeneReferenceGenomeEnum];

/**
 *
 * @export
 * @interface EnsemblGeneCriteria
 */
export interface EnsemblGeneCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof EnsemblGeneCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {ReferenceGenomeFilter}
   * @memberof EnsemblGeneCriteria
   */
  referenceGenome?: ReferenceGenomeFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof EnsemblGeneCriteria
   */
  ensemblGeneId?: StringFilter;
  /**
   *
   * @type {BooleanFilter}
   * @memberof EnsemblGeneCriteria
   */
  canonical?: BooleanFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof EnsemblGeneCriteria
   */
  start?: IntegerFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof EnsemblGeneCriteria
   */
  end?: IntegerFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof EnsemblGeneCriteria
   */
  strand?: IntegerFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof EnsemblGeneCriteria
   */
  transcriptId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof EnsemblGeneCriteria
   */
  geneId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof EnsemblGeneCriteria
   */
  seqRegionId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof EnsemblGeneCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface EnsemblTranscript
 */
export interface EnsemblTranscript {
  /**
   *
   * @type {string}
   * @memberof EnsemblTranscript
   */
  transcriptId?: string;
  /**
   *
   * @type {string}
   * @memberof EnsemblTranscript
   */
  geneId?: string;
  /**
   *
   * @type {string}
   * @memberof EnsemblTranscript
   */
  proteinId?: string;
  /**
   *
   * @type {number}
   * @memberof EnsemblTranscript
   */
  proteinLength?: number;
  /**
   *
   * @type {Array<PfamDomainRange>}
   * @memberof EnsemblTranscript
   */
  pfamDomains?: Array<PfamDomainRange>;
  /**
   *
   * @type {Array<string>}
   * @memberof EnsemblTranscript
   */
  hugoSymbols?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof EnsemblTranscript
   */
  refseqMrnaId?: string;
  /**
   *
   * @type {string}
   * @memberof EnsemblTranscript
   */
  ccdsId?: string;
  /**
   *
   * @type {Array<Exon>}
   * @memberof EnsemblTranscript
   */
  exons?: Array<Exon>;
  /**
   *
   * @type {Array<UntranslatedRegion>}
   * @memberof EnsemblTranscript
   */
  utrs?: Array<UntranslatedRegion>;
}
/**
 *
 * @export
 * @interface EntityAuditEvent
 */
export interface EntityAuditEvent {
  /**
   *
   * @type {number}
   * @memberof EntityAuditEvent
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof EntityAuditEvent
   */
  entityId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityAuditEvent
   */
  entityType?: string;
  /**
   *
   * @type {string}
   * @memberof EntityAuditEvent
   */
  action?: string;
  /**
   *
   * @type {string}
   * @memberof EntityAuditEvent
   */
  entityValue?: string;
  /**
   *
   * @type {number}
   * @memberof EntityAuditEvent
   */
  commitVersion?: number;
  /**
   *
   * @type {string}
   * @memberof EntityAuditEvent
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof EntityAuditEvent
   */
  modifiedDate?: string;
}
/**
 *
 * @export
 * @interface Evidence
 */
export interface Evidence {
  /**
   *
   * @type {number}
   * @memberof Evidence
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  evidenceType: string;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  knownEffect?: string;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Evidence
   */
  note?: string;
}
/**
 *
 * @export
 * @interface EvidenceCriteria
 */
export interface EvidenceCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof EvidenceCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof EvidenceCriteria
   */
  uuid?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof EvidenceCriteria
   */
  evidenceType?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof EvidenceCriteria
   */
  knownEffect?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof EvidenceCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof EvidenceCriteria
   */
  levelOfEvidenceId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof EvidenceCriteria
   */
  geneId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof EvidenceCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface Exon
 */
export interface Exon {
  /**
   *
   * @type {string}
   * @memberof Exon
   */
  exonId?: string;
  /**
   *
   * @type {number}
   * @memberof Exon
   */
  exonStart?: number;
  /**
   *
   * @type {number}
   * @memberof Exon
   */
  exonEnd?: number;
  /**
   *
   * @type {number}
   * @memberof Exon
   */
  rank?: number;
  /**
   *
   * @type {number}
   * @memberof Exon
   */
  strand?: number;
  /**
   *
   * @type {number}
   * @memberof Exon
   */
  version?: number;
}
/**
 *
 * @export
 * @interface FdaDrug
 */
export interface FdaDrug {
  /**
   *
   * @type {number}
   * @memberof FdaDrug
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FdaDrug
   */
  applicationNumber: string;
  /**
   *
   * @type {string}
   * @memberof FdaDrug
   */
  sponsorName?: string;
  /**
   *
   * @type {string}
   * @memberof FdaDrug
   */
  overallMarketingStatus?: string;
  /**
   *
   * @type {Array<FdaSubmission>}
   * @memberof FdaDrug
   */
  fdaSubmissions?: Array<FdaSubmission>;
  /**
   *
   * @type {Drug}
   * @memberof FdaDrug
   */
  drug?: Drug;
}
/**
 *
 * @export
 * @interface FdaDrugCriteria
 */
export interface FdaDrugCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof FdaDrugCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FdaDrugCriteria
   */
  applicationNumber?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FdaDrugCriteria
   */
  sponsorName?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FdaDrugCriteria
   */
  overallMarketingStatus?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FdaDrugCriteria
   */
  fdaSubmissionId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FdaDrugCriteria
   */
  drugId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof FdaDrugCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface FdaSubmission
 */
export interface FdaSubmission {
  /**
   *
   * @type {number}
   * @memberof FdaSubmission
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FdaSubmission
   */
  number: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmission
   */
  supplementNumber: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmission
   */
  deviceName: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmission
   */
  genericName?: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmission
   */
  dateReceived?: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmission
   */
  decisionDate?: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmission
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof FdaSubmission
   */
  curated: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FdaSubmission
   */
  genetic: boolean;
  /**
   *
   * @type {string}
   * @memberof FdaSubmission
   */
  note?: string;
  /**
   *
   * @type {Array<Article>}
   * @memberof FdaSubmission
   */
  articles?: Array<Article>;
  /**
   *
   * @type {Array<Association>}
   * @memberof FdaSubmission
   */
  associations?: Array<Association>;
  /**
   *
   * @type {CompanionDiagnosticDevice}
   * @memberof FdaSubmission
   */
  companionDiagnosticDevice?: CompanionDiagnosticDevice;
  /**
   *
   * @type {FdaDrug}
   * @memberof FdaSubmission
   */
  fdaDrug?: FdaDrug;
  /**
   *
   * @type {FdaSubmissionType}
   * @memberof FdaSubmission
   */
  type?: FdaSubmissionType;
}
/**
 *
 * @export
 * @interface FdaSubmissionCriteria
 */
export interface FdaSubmissionCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof FdaSubmissionCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FdaSubmissionCriteria
   */
  number?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FdaSubmissionCriteria
   */
  supplementNumber?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FdaSubmissionCriteria
   */
  deviceName?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FdaSubmissionCriteria
   */
  genericName?: StringFilter;
  /**
   *
   * @type {InstantFilter}
   * @memberof FdaSubmissionCriteria
   */
  dateReceived?: InstantFilter;
  /**
   *
   * @type {InstantFilter}
   * @memberof FdaSubmissionCriteria
   */
  decisionDate?: InstantFilter;
  /**
   *
   * @type {BooleanFilter}
   * @memberof FdaSubmissionCriteria
   */
  curated?: BooleanFilter;
  /**
   *
   * @type {BooleanFilter}
   * @memberof FdaSubmissionCriteria
   */
  genetic?: BooleanFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FdaSubmissionCriteria
   */
  articleId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FdaSubmissionCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FdaSubmissionCriteria
   */
  companionDiagnosticDeviceId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FdaSubmissionCriteria
   */
  fdaDrugId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FdaSubmissionCriteria
   */
  typeId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof FdaSubmissionCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface FdaSubmissionType
 */
export interface FdaSubmissionType {
  /**
   *
   * @type {number}
   * @memberof FdaSubmissionType
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FdaSubmissionType
   */
  type: FdaSubmissionTypeTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FdaSubmissionType
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmissionType
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmissionType
   */
  submissionPrefix?: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmissionType
   */
  submissionLink?: string;
  /**
   *
   * @type {string}
   * @memberof FdaSubmissionType
   */
  description?: string;
}

export const FdaSubmissionTypeTypeEnum = {
  DevicePma: 'DEVICE_PMA',
  DeviceDenovo: 'DEVICE_DENOVO',
  DeviceHde: 'DEVICE_HDE',
  DevicePmn: 'DEVICE_PMN',
  DrugNda: 'DRUG_NDA',
  DrugBla: 'DRUG_BLA',
} as const;

export type FdaSubmissionTypeTypeEnum = (typeof FdaSubmissionTypeTypeEnum)[keyof typeof FdaSubmissionTypeTypeEnum];

/**
 *
 * @export
 * @interface Flag
 */
export interface Flag {
  /**
   *
   * @type {number}
   * @memberof Flag
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Flag
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Flag
   */
  flag: string;
  /**
   *
   * @type {string}
   * @memberof Flag
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Flag
   */
  description?: string;
  /**
   *
   * @type {Array<Alteration>}
   * @memberof Flag
   */
  alterations?: Array<Alteration>;
  /**
   *
   * @type {Array<Article>}
   * @memberof Flag
   */
  articles?: Array<Article>;
  /**
   *
   * @type {Array<Drug>}
   * @memberof Flag
   */
  drugs?: Array<Drug>;
  /**
   *
   * @type {Array<Gene>}
   * @memberof Flag
   */
  genes?: Array<Gene>;
  /**
   *
   * @type {Array<Transcript>}
   * @memberof Flag
   */
  transcripts?: Array<Transcript>;
}
/**
 *
 * @export
 * @interface FlagCriteria
 */
export interface FlagCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof FlagCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FlagCriteria
   */
  type?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FlagCriteria
   */
  flag?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof FlagCriteria
   */
  name?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FlagCriteria
   */
  alterationId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FlagCriteria
   */
  articleId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FlagCriteria
   */
  drugId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FlagCriteria
   */
  geneId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof FlagCriteria
   */
  transcriptId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof FlagCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface Gene
 */
export interface Gene {
  /**
   *
   * @type {number}
   * @memberof Gene
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Gene
   */
  entrezGeneId: number;
  /**
   *
   * @type {string}
   * @memberof Gene
   */
  hugoSymbol: string;
  /**
   *
   * @type {string}
   * @memberof Gene
   */
  hgncId?: string;
  /**
   *
   * @type {Array<EnsemblGene>}
   * @memberof Gene
   */
  ensemblGenes?: Array<EnsemblGene>;
  /**
   *
   * @type {Array<Evidence>}
   * @memberof Gene
   */
  evidences?: Array<Evidence>;
  /**
   *
   * @type {Array<Transcript>}
   * @memberof Gene
   */
  transcripts?: Array<Transcript>;
  /**
   *
   * @type {Array<Flag>}
   * @memberof Gene
   */
  flags?: Array<Flag>;
  /**
   *
   * @type {Array<Synonym>}
   * @memberof Gene
   */
  synonyms?: Array<Synonym>;
  /**
   *
   * @type {Array<Alteration>}
   * @memberof Gene
   */
  alterations?: Array<Alteration>;
}
/**
 *
 * @export
 * @interface GeneCriteria
 */
export interface GeneCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof GeneCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof GeneCriteria
   */
  entrezGeneId?: IntegerFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof GeneCriteria
   */
  hugoSymbol?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof GeneCriteria
   */
  hgncId?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GeneCriteria
   */
  ensemblGeneId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GeneCriteria
   */
  evidenceId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GeneCriteria
   */
  transcriptId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GeneCriteria
   */
  flagId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GeneCriteria
   */
  synonymId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GeneCriteria
   */
  alterationId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof GeneCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface GenomeFragment
 */
export interface GenomeFragment {
  /**
   *
   * @type {number}
   * @memberof GenomeFragment
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof GenomeFragment
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof GenomeFragment
   */
  end: number;
  /**
   *
   * @type {number}
   * @memberof GenomeFragment
   */
  strand: number;
  /**
   *
   * @type {string}
   * @memberof GenomeFragment
   */
  type: GenomeFragmentTypeEnum;
  /**
   *
   * @type {SeqRegion}
   * @memberof GenomeFragment
   */
  seqRegion?: SeqRegion;
  /**
   *
   * @type {Transcript}
   * @memberof GenomeFragment
   */
  transcript?: Transcript;
}

export const GenomeFragmentTypeEnum = {
  Gene: 'GENE',
  Exon: 'EXON',
  FivePrimeUtr: 'FIVE_PRIME_UTR',
  ThreePrimeUtr: 'THREE_PRIME_UTR',
} as const;

export type GenomeFragmentTypeEnum = (typeof GenomeFragmentTypeEnum)[keyof typeof GenomeFragmentTypeEnum];

/**
 *
 * @export
 * @interface GenomeFragmentCriteria
 */
export interface GenomeFragmentCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof GenomeFragmentCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof GenomeFragmentCriteria
   */
  start?: IntegerFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof GenomeFragmentCriteria
   */
  end?: IntegerFilter;
  /**
   *
   * @type {IntegerFilter}
   * @memberof GenomeFragmentCriteria
   */
  strand?: IntegerFilter;
  /**
   *
   * @type {GenomeFragmentTypeFilter}
   * @memberof GenomeFragmentCriteria
   */
  type?: GenomeFragmentTypeFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GenomeFragmentCriteria
   */
  seqRegionId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GenomeFragmentCriteria
   */
  transcriptId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof GenomeFragmentCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface GenomeFragmentTypeFilter
 */
export interface GenomeFragmentTypeFilter {
  /**
   *
   * @type {string}
   * @memberof GenomeFragmentTypeFilter
   */
  equals?: GenomeFragmentTypeFilterEqualsEnum;
  /**
   *
   * @type {string}
   * @memberof GenomeFragmentTypeFilter
   */
  notEquals?: GenomeFragmentTypeFilterNotEqualsEnum;
  /**
   *
   * @type {boolean}
   * @memberof GenomeFragmentTypeFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof GenomeFragmentTypeFilter
   */
  in?: Array<GenomeFragmentTypeFilterInEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof GenomeFragmentTypeFilter
   */
  notIn?: Array<GenomeFragmentTypeFilterNotInEnum>;
}

export const GenomeFragmentTypeFilterEqualsEnum = {
  Gene: 'GENE',
  Exon: 'EXON',
  FivePrimeUtr: 'FIVE_PRIME_UTR',
  ThreePrimeUtr: 'THREE_PRIME_UTR',
} as const;

export type GenomeFragmentTypeFilterEqualsEnum =
  (typeof GenomeFragmentTypeFilterEqualsEnum)[keyof typeof GenomeFragmentTypeFilterEqualsEnum];
export const GenomeFragmentTypeFilterNotEqualsEnum = {
  Gene: 'GENE',
  Exon: 'EXON',
  FivePrimeUtr: 'FIVE_PRIME_UTR',
  ThreePrimeUtr: 'THREE_PRIME_UTR',
} as const;

export type GenomeFragmentTypeFilterNotEqualsEnum =
  (typeof GenomeFragmentTypeFilterNotEqualsEnum)[keyof typeof GenomeFragmentTypeFilterNotEqualsEnum];
export const GenomeFragmentTypeFilterInEnum = {
  Gene: 'GENE',
  Exon: 'EXON',
  FivePrimeUtr: 'FIVE_PRIME_UTR',
  ThreePrimeUtr: 'THREE_PRIME_UTR',
} as const;

export type GenomeFragmentTypeFilterInEnum = (typeof GenomeFragmentTypeFilterInEnum)[keyof typeof GenomeFragmentTypeFilterInEnum];
export const GenomeFragmentTypeFilterNotInEnum = {
  Gene: 'GENE',
  Exon: 'EXON',
  FivePrimeUtr: 'FIVE_PRIME_UTR',
  ThreePrimeUtr: 'THREE_PRIME_UTR',
} as const;

export type GenomeFragmentTypeFilterNotInEnum = (typeof GenomeFragmentTypeFilterNotInEnum)[keyof typeof GenomeFragmentTypeFilterNotInEnum];

/**
 *
 * @export
 * @interface GenomicIndicator
 */
export interface GenomicIndicator {
  /**
   *
   * @type {number}
   * @memberof GenomicIndicator
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GenomicIndicator
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof GenomicIndicator
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof GenomicIndicator
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GenomicIndicator
   */
  description?: string;
  /**
   *
   * @type {Array<AlleleState>}
   * @memberof GenomicIndicator
   */
  alleleStates?: Array<AlleleState>;
  /**
   *
   * @type {Array<Association>}
   * @memberof GenomicIndicator
   */
  associations?: Array<Association>;
}
/**
 *
 * @export
 * @interface GenomicIndicatorCriteria
 */
export interface GenomicIndicatorCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof GenomicIndicatorCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof GenomicIndicatorCriteria
   */
  uuid?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof GenomicIndicatorCriteria
   */
  type?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof GenomicIndicatorCriteria
   */
  name?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GenomicIndicatorCriteria
   */
  alleleStateId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof GenomicIndicatorCriteria
   */
  associationId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof GenomicIndicatorCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface HotspotDTO
 */
export interface HotspotDTO {
  /**
   *
   * @type {string}
   * @memberof HotspotDTO
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof HotspotDTO
   */
  alteration?: string;
}
/**
 *
 * @export
 * @interface HotspotInfoDTO
 */
export interface HotspotInfoDTO {
  /**
   *
   * @type {Array<HotspotDTO>}
   * @memberof HotspotInfoDTO
   */
  associatedHotspots?: Array<HotspotDTO>;
  /**
   *
   * @type {boolean}
   * @memberof HotspotInfoDTO
   */
  hotspot?: boolean;
}
/**
 *
 * @export
 * @interface Info
 */
export interface Info {
  /**
   *
   * @type {number}
   * @memberof Info
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Info
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Info
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof Info
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof Info
   */
  lastUpdated?: string;
}
/**
 *
 * @export
 * @interface InstantFilter
 */
export interface InstantFilter {
  /**
   *
   * @type {string}
   * @memberof InstantFilter
   */
  equals?: string;
  /**
   *
   * @type {string}
   * @memberof InstantFilter
   */
  notEquals?: string;
  /**
   *
   * @type {boolean}
   * @memberof InstantFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof InstantFilter
   */
  in?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof InstantFilter
   */
  notIn?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof InstantFilter
   */
  greaterThan?: string;
  /**
   *
   * @type {string}
   * @memberof InstantFilter
   */
  lessThan?: string;
  /**
   *
   * @type {string}
   * @memberof InstantFilter
   */
  greaterThanOrEqual?: string;
  /**
   *
   * @type {string}
   * @memberof InstantFilter
   */
  lessThanOrEqual?: string;
}
/**
 *
 * @export
 * @interface IntegerFilter
 */
export interface IntegerFilter {
  /**
   *
   * @type {number}
   * @memberof IntegerFilter
   */
  equals?: number;
  /**
   *
   * @type {number}
   * @memberof IntegerFilter
   */
  notEquals?: number;
  /**
   *
   * @type {boolean}
   * @memberof IntegerFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof IntegerFilter
   */
  in?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof IntegerFilter
   */
  notIn?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof IntegerFilter
   */
  greaterThan?: number;
  /**
   *
   * @type {number}
   * @memberof IntegerFilter
   */
  lessThan?: number;
  /**
   *
   * @type {number}
   * @memberof IntegerFilter
   */
  greaterThanOrEqual?: number;
  /**
   *
   * @type {number}
   * @memberof IntegerFilter
   */
  lessThanOrEqual?: number;
}
/**
 *
 * @export
 * @interface IntegerRange
 */
export interface IntegerRange {
  /**
   *
   * @type {number}
   * @memberof IntegerRange
   */
  start?: number;
  /**
   *
   * @type {number}
   * @memberof IntegerRange
   */
  end?: number;
}
/**
 *
 * @export
 * @interface JournalDTO
 */
export interface JournalDTO {
  /**
   *
   * @type {string}
   * @memberof JournalDTO
   */
  issn?: string;
  /**
   *
   * @type {string}
   * @memberof JournalDTO
   */
  volume?: string;
  /**
   *
   * @type {string}
   * @memberof JournalDTO
   */
  issue?: string;
  /**
   *
   * @type {string}
   * @memberof JournalDTO
   */
  pages?: string;
  /**
   *
   * @type {string}
   * @memberof JournalDTO
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof JournalDTO
   */
  isoAbbreviation?: string;
}
/**
 *
 * @export
 * @interface LevelOfEvidence
 */
export interface LevelOfEvidence {
  /**
   *
   * @type {number}
   * @memberof LevelOfEvidence
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof LevelOfEvidence
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof LevelOfEvidence
   */
  level: string;
  /**
   *
   * @type {string}
   * @memberof LevelOfEvidence
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof LevelOfEvidence
   */
  htmlDescription: string;
  /**
   *
   * @type {string}
   * @memberof LevelOfEvidence
   */
  color: string;
  /**
   *
   * @type {Array<Evidence>}
   * @memberof LevelOfEvidence
   */
  evidences?: Array<Evidence>;
}
/**
 *
 * @export
 * @interface LongFilter
 */
export interface LongFilter {
  /**
   *
   * @type {number}
   * @memberof LongFilter
   */
  equals?: number;
  /**
   *
   * @type {number}
   * @memberof LongFilter
   */
  notEquals?: number;
  /**
   *
   * @type {boolean}
   * @memberof LongFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof LongFilter
   */
  in?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof LongFilter
   */
  notIn?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof LongFilter
   */
  greaterThan?: number;
  /**
   *
   * @type {number}
   * @memberof LongFilter
   */
  lessThan?: number;
  /**
   *
   * @type {number}
   * @memberof LongFilter
   */
  greaterThanOrEqual?: number;
  /**
   *
   * @type {number}
   * @memberof LongFilter
   */
  lessThanOrEqual?: number;
}
/**
 *
 * @export
 * @interface MatchTranscriptVM
 */
export interface MatchTranscriptVM {
  /**
   *
   * @type {TranscriptPairVM}
   * @memberof MatchTranscriptVM
   */
  transcript?: TranscriptPairVM;
  /**
   *
   * @type {string}
   * @memberof MatchTranscriptVM
   */
  targetReferenceGenome?: MatchTranscriptVMTargetReferenceGenomeEnum;
}

export const MatchTranscriptVMTargetReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type MatchTranscriptVMTargetReferenceGenomeEnum =
  (typeof MatchTranscriptVMTargetReferenceGenomeEnum)[keyof typeof MatchTranscriptVMTargetReferenceGenomeEnum];

/**
 *
 * @export
 * @interface MultiValueMapStringString
 */
export interface MultiValueMapStringString {
  [key: string]: Array<string> | any;

  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof MultiValueMapStringString
   */
  all?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof MultiValueMapStringString
   */
  empty?: boolean;
}
/**
 *
 * @export
 * @interface NciThesaurus
 */
export interface NciThesaurus {
  /**
   *
   * @type {number}
   * @memberof NciThesaurus
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof NciThesaurus
   */
  version: string;
  /**
   *
   * @type {string}
   * @memberof NciThesaurus
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof NciThesaurus
   */
  preferredName?: string;
  /**
   *
   * @type {string}
   * @memberof NciThesaurus
   */
  displayName?: string;
  /**
   *
   * @type {Array<Synonym>}
   * @memberof NciThesaurus
   */
  synonyms?: Array<Synonym>;
}
/**
 *
 * @export
 * @interface NciThesaurusCriteria
 */
export interface NciThesaurusCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof NciThesaurusCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof NciThesaurusCriteria
   */
  version?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof NciThesaurusCriteria
   */
  code?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof NciThesaurusCriteria
   */
  preferredName?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof NciThesaurusCriteria
   */
  displayName?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof NciThesaurusCriteria
   */
  synonymId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof NciThesaurusCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface Pageable
 */
export interface Pageable {
  /**
   *
   * @type {number}
   * @memberof Pageable
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof Pageable
   */
  size?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Pageable
   */
  sort?: Array<string>;
}
/**
 *
 * @export
 * @interface PfamDomainRange
 */
export interface PfamDomainRange {
  /**
   *
   * @type {string}
   * @memberof PfamDomainRange
   */
  pfamDomainId?: string;
  /**
   *
   * @type {number}
   * @memberof PfamDomainRange
   */
  pfamDomainStart?: number;
  /**
   *
   * @type {number}
   * @memberof PfamDomainRange
   */
  pfamDomainEnd?: number;
}
/**
 *
 * @export
 * @interface ProteinExonDTO
 */
export interface ProteinExonDTO {
  /**
   *
   * @type {IntegerRange}
   * @memberof ProteinExonDTO
   */
  range?: IntegerRange;
  /**
   *
   * @type {number}
   * @memberof ProteinExonDTO
   */
  exon?: number;
}
/**
 *
 * @export
 * @interface PubMedDTO
 */
export interface PubMedDTO {
  /**
   *
   * @type {number}
   * @memberof PubMedDTO
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PubMedDTO
   */
  type?: PubMedDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PubMedDTO
   */
  pmid?: string;
  /**
   *
   * @type {string}
   * @memberof PubMedDTO
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof PubMedDTO
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof PubMedDTO
   */
  link?: string;
  /**
   *
   * @type {string}
   * @memberof PubMedDTO
   */
  authors?: string;
  /**
   *
   * @type {string}
   * @memberof PubMedDTO
   */
  date?: string;
  /**
   *
   * @type {AdditionalInfoDTO}
   * @memberof PubMedDTO
   */
  additionalInfo?: AdditionalInfoDTO;
  /**
   *
   * @type {Array<Flag>}
   * @memberof PubMedDTO
   */
  flags?: Array<Flag>;
  /**
   *
   * @type {Array<Synonym>}
   * @memberof PubMedDTO
   */
  synonyms?: Array<Synonym>;
}

export const PubMedDTOTypeEnum = {
  Pubmed: 'PUBMED',
  Abstract: 'ABSTRACT',
  Reference: 'REFERENCE',
  FdadrugLetter: 'FDADRUG_LETTER',
  FdadrugLabel: 'FDADRUG_LABEL',
  FdadrugSummary: 'FDADRUG_SUMMARY',
  FdadrugSummaryReview: 'FDADRUG_SUMMARY_REVIEW',
} as const;

export type PubMedDTOTypeEnum = (typeof PubMedDTOTypeEnum)[keyof typeof PubMedDTOTypeEnum];

/**
 *
 * @export
 * @interface ReferenceGenomeFilter
 */
export interface ReferenceGenomeFilter {
  /**
   *
   * @type {string}
   * @memberof ReferenceGenomeFilter
   */
  equals?: ReferenceGenomeFilterEqualsEnum;
  /**
   *
   * @type {string}
   * @memberof ReferenceGenomeFilter
   */
  notEquals?: ReferenceGenomeFilterNotEqualsEnum;
  /**
   *
   * @type {boolean}
   * @memberof ReferenceGenomeFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ReferenceGenomeFilter
   */
  in?: Array<ReferenceGenomeFilterInEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof ReferenceGenomeFilter
   */
  notIn?: Array<ReferenceGenomeFilterNotInEnum>;
}

export const ReferenceGenomeFilterEqualsEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type ReferenceGenomeFilterEqualsEnum = (typeof ReferenceGenomeFilterEqualsEnum)[keyof typeof ReferenceGenomeFilterEqualsEnum];
export const ReferenceGenomeFilterNotEqualsEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type ReferenceGenomeFilterNotEqualsEnum =
  (typeof ReferenceGenomeFilterNotEqualsEnum)[keyof typeof ReferenceGenomeFilterNotEqualsEnum];
export const ReferenceGenomeFilterInEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type ReferenceGenomeFilterInEnum = (typeof ReferenceGenomeFilterInEnum)[keyof typeof ReferenceGenomeFilterInEnum];
export const ReferenceGenomeFilterNotInEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type ReferenceGenomeFilterNotInEnum = (typeof ReferenceGenomeFilterNotInEnum)[keyof typeof ReferenceGenomeFilterNotInEnum];

/**
 *
 * @export
 * @interface RelevantCancerTypeBody
 */
export interface RelevantCancerTypeBody {
  /**
   *
   * @type {Array<RelevantCancerTypeQuery>}
   * @memberof RelevantCancerTypeBody
   */
  relevantCancerTypeQueries?: Array<RelevantCancerTypeQuery>;
  /**
   *
   * @type {Array<RelevantCancerTypeQuery>}
   * @memberof RelevantCancerTypeBody
   */
  excludedRelevantCancerTypeQueries?: Array<RelevantCancerTypeQuery>;
}
/**
 *
 * @export
 * @interface RelevantCancerTypeQuery
 */
export interface RelevantCancerTypeQuery {
  /**
   *
   * @type {string}
   * @memberof RelevantCancerTypeQuery
   */
  mainType?: string;
  /**
   *
   * @type {string}
   * @memberof RelevantCancerTypeQuery
   */
  code?: string;
}
/**
 *
 * @export
 * @interface Rule
 */
export interface Rule {
  /**
   *
   * @type {number}
   * @memberof Rule
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Rule
   */
  entity: string;
  /**
   *
   * @type {string}
   * @memberof Rule
   */
  rule?: string;
  /**
   *
   * @type {string}
   * @memberof Rule
   */
  name?: string;
  /**
   *
   * @type {Association}
   * @memberof Rule
   */
  association?: Association;
}
/**
 *
 * @export
 * @interface SearchResultDTO
 */
export interface SearchResultDTO {
  /**
   *
   * @type {Array<FdaSubmission>}
   * @memberof SearchResultDTO
   */
  fdaSubmissions?: Array<FdaSubmission>;
  /**
   *
   * @type {Array<CompanionDiagnosticDevice>}
   * @memberof SearchResultDTO
   */
  companionDiagnosticDevices?: Array<CompanionDiagnosticDevice>;
  /**
   *
   * @type {Array<Article>}
   * @memberof SearchResultDTO
   */
  articles?: Array<Article>;
  /**
   *
   * @type {Array<Drug>}
   * @memberof SearchResultDTO
   */
  drugs?: Array<Drug>;
  /**
   *
   * @type {Array<Gene>}
   * @memberof SearchResultDTO
   */
  genes?: Array<Gene>;
  /**
   *
   * @type {Array<Alteration>}
   * @memberof SearchResultDTO
   */
  alterations?: Array<Alteration>;
}
/**
 *
 * @export
 * @interface SeqRegion
 */
export interface SeqRegion {
  /**
   *
   * @type {number}
   * @memberof SeqRegion
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SeqRegion
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SeqRegion
   */
  chromosome?: string;
  /**
   *
   * @type {string}
   * @memberof SeqRegion
   */
  description?: string;
}
/**
 *
 * @export
 * @interface Sequence
 */
export interface Sequence {
  /**
   *
   * @type {number}
   * @memberof Sequence
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Sequence
   */
  sequenceType: SequenceSequenceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Sequence
   */
  sequence?: string;
  /**
   *
   * @type {Transcript}
   * @memberof Sequence
   */
  transcript?: Transcript;
}

export const SequenceSequenceTypeEnum = {
  Protein: 'PROTEIN',
  Cdna: 'CDNA',
  Genomic: 'GENOMIC',
} as const;

export type SequenceSequenceTypeEnum = (typeof SequenceSequenceTypeEnum)[keyof typeof SequenceSequenceTypeEnum];

/**
 *
 * @export
 * @interface SequenceCriteria
 */
export interface SequenceCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof SequenceCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {SequenceTypeFilter}
   * @memberof SequenceCriteria
   */
  sequenceType?: SequenceTypeFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof SequenceCriteria
   */
  transcriptId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof SequenceCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface SequenceTypeFilter
 */
export interface SequenceTypeFilter {
  /**
   *
   * @type {string}
   * @memberof SequenceTypeFilter
   */
  equals?: SequenceTypeFilterEqualsEnum;
  /**
   *
   * @type {string}
   * @memberof SequenceTypeFilter
   */
  notEquals?: SequenceTypeFilterNotEqualsEnum;
  /**
   *
   * @type {boolean}
   * @memberof SequenceTypeFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof SequenceTypeFilter
   */
  in?: Array<SequenceTypeFilterInEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof SequenceTypeFilter
   */
  notIn?: Array<SequenceTypeFilterNotInEnum>;
}

export const SequenceTypeFilterEqualsEnum = {
  Protein: 'PROTEIN',
  Cdna: 'CDNA',
  Genomic: 'GENOMIC',
} as const;

export type SequenceTypeFilterEqualsEnum = (typeof SequenceTypeFilterEqualsEnum)[keyof typeof SequenceTypeFilterEqualsEnum];
export const SequenceTypeFilterNotEqualsEnum = {
  Protein: 'PROTEIN',
  Cdna: 'CDNA',
  Genomic: 'GENOMIC',
} as const;

export type SequenceTypeFilterNotEqualsEnum = (typeof SequenceTypeFilterNotEqualsEnum)[keyof typeof SequenceTypeFilterNotEqualsEnum];
export const SequenceTypeFilterInEnum = {
  Protein: 'PROTEIN',
  Cdna: 'CDNA',
  Genomic: 'GENOMIC',
} as const;

export type SequenceTypeFilterInEnum = (typeof SequenceTypeFilterInEnum)[keyof typeof SequenceTypeFilterInEnum];
export const SequenceTypeFilterNotInEnum = {
  Protein: 'PROTEIN',
  Cdna: 'CDNA',
  Genomic: 'GENOMIC',
} as const;

export type SequenceTypeFilterNotInEnum = (typeof SequenceTypeFilterNotInEnum)[keyof typeof SequenceTypeFilterNotInEnum];

/**
 *
 * @export
 * @interface SpecimenType
 */
export interface SpecimenType {
  /**
   *
   * @type {number}
   * @memberof SpecimenType
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpecimenType
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof SpecimenType
   */
  name: string;
  /**
   *
   * @type {Array<CompanionDiagnosticDevice>}
   * @memberof SpecimenType
   */
  companionDiagnosticDevices?: Array<CompanionDiagnosticDevice>;
}
/**
 *
 * @export
 * @interface StringFilter
 */
export interface StringFilter {
  /**
   *
   * @type {string}
   * @memberof StringFilter
   */
  equals?: string;
  /**
   *
   * @type {string}
   * @memberof StringFilter
   */
  notEquals?: string;
  /**
   *
   * @type {boolean}
   * @memberof StringFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof StringFilter
   */
  in?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof StringFilter
   */
  notIn?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof StringFilter
   */
  contains?: string;
  /**
   *
   * @type {string}
   * @memberof StringFilter
   */
  doesNotContain?: string;
}
/**
 *
 * @export
 * @interface Synonym
 */
export interface Synonym {
  /**
   *
   * @type {number}
   * @memberof Synonym
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Synonym
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Synonym
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof Synonym
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Synonym
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Synonym
   */
  note?: string;
  /**
   *
   * @type {Array<Article>}
   * @memberof Synonym
   */
  articles?: Array<Article>;
  /**
   *
   * @type {Array<CancerType>}
   * @memberof Synonym
   */
  cancerTypes?: Array<CancerType>;
  /**
   *
   * @type {Array<Gene>}
   * @memberof Synonym
   */
  genes?: Array<Gene>;
  /**
   *
   * @type {Array<NciThesaurus>}
   * @memberof Synonym
   */
  nciThesauruses?: Array<NciThesaurus>;
}
/**
 *
 * @export
 * @interface SynonymCriteria
 */
export interface SynonymCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof SynonymCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof SynonymCriteria
   */
  type?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof SynonymCriteria
   */
  source?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof SynonymCriteria
   */
  code?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof SynonymCriteria
   */
  name?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof SynonymCriteria
   */
  articleId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof SynonymCriteria
   */
  cancerTypeId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof SynonymCriteria
   */
  geneId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof SynonymCriteria
   */
  nciThesaurusId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof SynonymCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface Transcript
 */
export interface Transcript {
  /**
   *
   * @type {number}
   * @memberof Transcript
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Transcript
   */
  referenceGenome?: TranscriptReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof Transcript
   */
  ensemblTranscriptId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Transcript
   */
  canonical: boolean;
  /**
   *
   * @type {string}
   * @memberof Transcript
   */
  ensemblProteinId?: string;
  /**
   *
   * @type {string}
   * @memberof Transcript
   */
  referenceSequenceId?: string;
  /**
   *
   * @type {string}
   * @memberof Transcript
   */
  description?: string;
  /**
   *
   * @type {Array<Sequence>}
   * @memberof Transcript
   */
  sequences?: Array<Sequence>;
  /**
   *
   * @type {Array<GenomeFragment>}
   * @memberof Transcript
   */
  fragments?: Array<GenomeFragment>;
  /**
   *
   * @type {Array<Flag>}
   * @memberof Transcript
   */
  flags?: Array<Flag>;
  /**
   *
   * @type {EnsemblGene}
   * @memberof Transcript
   */
  ensemblGene: EnsemblGene;
  /**
   *
   * @type {Gene}
   * @memberof Transcript
   */
  gene: Gene;
  /**
   *
   * @type {Array<Alteration>}
   * @memberof Transcript
   */
  alterations?: Array<Alteration>;
}

export const TranscriptReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type TranscriptReferenceGenomeEnum = (typeof TranscriptReferenceGenomeEnum)[keyof typeof TranscriptReferenceGenomeEnum];

/**
 *
 * @export
 * @interface TranscriptComparisonResultVM
 */
export interface TranscriptComparisonResultVM {
  /**
   *
   * @type {boolean}
   * @memberof TranscriptComparisonResultVM
   */
  match?: boolean;
  /**
   *
   * @type {string}
   * @memberof TranscriptComparisonResultVM
   */
  sequenceA?: string;
  /**
   *
   * @type {string}
   * @memberof TranscriptComparisonResultVM
   */
  sequenceB?: string;
}
/**
 *
 * @export
 * @interface TranscriptComparisonVM
 */
export interface TranscriptComparisonVM {
  /**
   *
   * @type {boolean}
   * @memberof TranscriptComparisonVM
   */
  align?: boolean;
  /**
   *
   * @type {TranscriptPairVM}
   * @memberof TranscriptComparisonVM
   */
  transcriptA?: TranscriptPairVM;
  /**
   *
   * @type {TranscriptPairVM}
   * @memberof TranscriptComparisonVM
   */
  transcriptB?: TranscriptPairVM;
}
/**
 *
 * @export
 * @interface TranscriptCriteria
 */
export interface TranscriptCriteria {
  /**
   *
   * @type {LongFilter}
   * @memberof TranscriptCriteria
   */
  id?: LongFilter;
  /**
   *
   * @type {ReferenceGenomeFilter}
   * @memberof TranscriptCriteria
   */
  referenceGenome?: ReferenceGenomeFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof TranscriptCriteria
   */
  ensemblTranscriptId?: StringFilter;
  /**
   *
   * @type {BooleanFilter}
   * @memberof TranscriptCriteria
   */
  canonical?: BooleanFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof TranscriptCriteria
   */
  ensemblProteinId?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof TranscriptCriteria
   */
  referenceSequenceId?: StringFilter;
  /**
   *
   * @type {StringFilter}
   * @memberof TranscriptCriteria
   */
  description?: StringFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof TranscriptCriteria
   */
  sequenceId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof TranscriptCriteria
   */
  fragmentsId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof TranscriptCriteria
   */
  flagId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof TranscriptCriteria
   */
  ensemblGeneId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof TranscriptCriteria
   */
  geneId?: LongFilter;
  /**
   *
   * @type {LongFilter}
   * @memberof TranscriptCriteria
   */
  alterationId?: LongFilter;
  /**
   *
   * @type {boolean}
   * @memberof TranscriptCriteria
   */
  distinct?: boolean;
}
/**
 *
 * @export
 * @interface TranscriptDTO
 */
export interface TranscriptDTO {
  /**
   *
   * @type {number}
   * @memberof TranscriptDTO
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TranscriptDTO
   */
  referenceGenome?: TranscriptDTOReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof TranscriptDTO
   */
  ensemblTranscriptId?: string;
  /**
   *
   * @type {boolean}
   * @memberof TranscriptDTO
   */
  canonical: boolean;
  /**
   *
   * @type {string}
   * @memberof TranscriptDTO
   */
  ensemblProteinId?: string;
  /**
   *
   * @type {string}
   * @memberof TranscriptDTO
   */
  referenceSequenceId?: string;
  /**
   *
   * @type {string}
   * @memberof TranscriptDTO
   */
  description?: string;
  /**
   *
   * @type {Gene}
   * @memberof TranscriptDTO
   */
  gene?: Gene;
  /**
   *
   * @type {EnsemblGene}
   * @memberof TranscriptDTO
   */
  ensemblGene?: EnsemblGene;
  /**
   *
   * @type {number}
   * @memberof TranscriptDTO
   */
  strand?: number;
  /**
   *
   * @type {string}
   * @memberof TranscriptDTO
   */
  chromosome?: string;
  /**
   *
   * @type {number}
   * @memberof TranscriptDTO
   */
  start?: number;
  /**
   *
   * @type {number}
   * @memberof TranscriptDTO
   */
  end?: number;
  /**
   *
   * @type {Array<GenomeFragment>}
   * @memberof TranscriptDTO
   */
  exons?: Array<GenomeFragment>;
  /**
   *
   * @type {Array<GenomeFragment>}
   * @memberof TranscriptDTO
   */
  utrs?: Array<GenomeFragment>;
  /**
   *
   * @type {Array<Flag>}
   * @memberof TranscriptDTO
   */
  flags?: Array<Flag>;
}

export const TranscriptDTOReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type TranscriptDTOReferenceGenomeEnum = (typeof TranscriptDTOReferenceGenomeEnum)[keyof typeof TranscriptDTOReferenceGenomeEnum];

/**
 *
 * @export
 * @interface TranscriptMatchResultVM
 */
export interface TranscriptMatchResultVM {
  /**
   *
   * @type {string}
   * @memberof TranscriptMatchResultVM
   */
  note?: string;
  /**
   *
   * @type {EnsemblTranscript}
   * @memberof TranscriptMatchResultVM
   */
  originalEnsemblTranscript?: EnsemblTranscript;
  /**
   *
   * @type {EnsemblTranscript}
   * @memberof TranscriptMatchResultVM
   */
  targetEnsemblTranscript?: EnsemblTranscript;
}
/**
 *
 * @export
 * @interface TranscriptPairVM
 */
export interface TranscriptPairVM {
  /**
   *
   * @type {string}
   * @memberof TranscriptPairVM
   */
  referenceGenome?: TranscriptPairVMReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof TranscriptPairVM
   */
  transcript?: string;
}

export const TranscriptPairVMReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type TranscriptPairVMReferenceGenomeEnum =
  (typeof TranscriptPairVMReferenceGenomeEnum)[keyof typeof TranscriptPairVMReferenceGenomeEnum];

/**
 *
 * @export
 * @interface TranscriptSuggestionVM
 */
export interface TranscriptSuggestionVM {
  /**
   *
   * @type {string}
   * @memberof TranscriptSuggestionVM
   */
  referenceGenome?: TranscriptSuggestionVMReferenceGenomeEnum;
  /**
   *
   * @type {string}
   * @memberof TranscriptSuggestionVM
   */
  note?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TranscriptSuggestionVM
   */
  suggestions?: Array<string>;
}

export const TranscriptSuggestionVMReferenceGenomeEnum = {
  Grch37: 'GRCh37',
  Grch38: 'GRCh38',
} as const;

export type TranscriptSuggestionVMReferenceGenomeEnum =
  (typeof TranscriptSuggestionVMReferenceGenomeEnum)[keyof typeof TranscriptSuggestionVMReferenceGenomeEnum];

/**
 *
 * @export
 * @interface TumorFormFilter
 */
export interface TumorFormFilter {
  /**
   *
   * @type {string}
   * @memberof TumorFormFilter
   */
  equals?: TumorFormFilterEqualsEnum;
  /**
   *
   * @type {string}
   * @memberof TumorFormFilter
   */
  notEquals?: TumorFormFilterNotEqualsEnum;
  /**
   *
   * @type {boolean}
   * @memberof TumorFormFilter
   */
  specified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof TumorFormFilter
   */
  in?: Array<TumorFormFilterInEnum>;
  /**
   *
   * @type {Array<string>}
   * @memberof TumorFormFilter
   */
  notIn?: Array<TumorFormFilterNotInEnum>;
}

export const TumorFormFilterEqualsEnum = {
  Solid: 'SOLID',
  Liquid: 'LIQUID',
  Mixed: 'MIXED',
} as const;

export type TumorFormFilterEqualsEnum = (typeof TumorFormFilterEqualsEnum)[keyof typeof TumorFormFilterEqualsEnum];
export const TumorFormFilterNotEqualsEnum = {
  Solid: 'SOLID',
  Liquid: 'LIQUID',
  Mixed: 'MIXED',
} as const;

export type TumorFormFilterNotEqualsEnum = (typeof TumorFormFilterNotEqualsEnum)[keyof typeof TumorFormFilterNotEqualsEnum];
export const TumorFormFilterInEnum = {
  Solid: 'SOLID',
  Liquid: 'LIQUID',
  Mixed: 'MIXED',
} as const;

export type TumorFormFilterInEnum = (typeof TumorFormFilterInEnum)[keyof typeof TumorFormFilterInEnum];
export const TumorFormFilterNotInEnum = {
  Solid: 'SOLID',
  Liquid: 'LIQUID',
  Mixed: 'MIXED',
} as const;

export type TumorFormFilterNotInEnum = (typeof TumorFormFilterNotInEnum)[keyof typeof TumorFormFilterNotInEnum];

/**
 *
 * @export
 * @interface UntranslatedRegion
 */
export interface UntranslatedRegion {
  /**
   *
   * @type {string}
   * @memberof UntranslatedRegion
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof UntranslatedRegion
   */
  start?: number;
  /**
   *
   * @type {number}
   * @memberof UntranslatedRegion
   */
  end?: number;
  /**
   *
   * @type {number}
   * @memberof UntranslatedRegion
   */
  strand?: number;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  login: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  activated: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  langKey?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  imageUrl?: string;
}
/**
 *
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
  /**
   *
   * @type {number}
   * @memberof UserDTO
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  login: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  imageUrl?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserDTO
   */
  activated?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  langKey?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  createdDate?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  lastModifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  lastModifiedDate?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserDTO
   */
  authorities?: Array<string>;
}

/**
 * AccountResourceApi - axios parameter creator
 * @export
 */
export const AccountResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/account`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFirebaseToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/account/firebase-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isAuthenticated: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/authenticate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountResourceApi - functional programming interface
 * @export
 */
export const AccountResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFirebaseToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFirebaseToken(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isAuthenticated(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.isAuthenticated(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AccountResourceApi - factory interface
 * @export
 */
export const AccountResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AccountResourceApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(options?: any): AxiosPromise<UserDTO> {
      return localVarFp.getAccount(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFirebaseToken(options?: any): AxiosPromise<string> {
      return localVarFp.getFirebaseToken(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isAuthenticated(options?: any): AxiosPromise<string> {
      return localVarFp.isAuthenticated(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AccountResourceApi - object-oriented interface
 * @export
 * @class AccountResourceApi
 * @extends {BaseAPI}
 */
export class AccountResourceApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public getAccount(options?: AxiosRequestConfig) {
    return AccountResourceApiFp(this.configuration)
      .getAccount(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public getFirebaseToken(options?: AxiosRequestConfig) {
    return AccountResourceApiFp(this.configuration)
      .getFirebaseToken(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public isAuthenticated(options?: AxiosRequestConfig) {
    return AccountResourceApiFp(this.configuration)
      .isAuthenticated(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AlleleStateResourceApi - axios parameter creator
 * @export
 */
export const AlleleStateResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AlleleState} alleleState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAlleleState: async (alleleState: AlleleState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'alleleState' is not null or undefined
      assertParamExists('createAlleleState', 'alleleState', alleleState);
      const localVarPath = `/api/allele-states`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(alleleState, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAlleleState: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteAlleleState', 'id', id);
      const localVarPath = `/api/allele-states/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAlleleStates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/allele-states`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAlleleState: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAlleleState', 'id', id);
      const localVarPath = `/api/allele-states/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {AlleleState} alleleState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateAlleleState: async (id: number, alleleState: AlleleState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateAlleleState', 'id', id);
      // verify required parameter 'alleleState' is not null or undefined
      assertParamExists('partialUpdateAlleleState', 'alleleState', alleleState);
      const localVarPath = `/api/allele-states/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(alleleState, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {AlleleState} alleleState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAlleleState: async (id: number, alleleState: AlleleState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateAlleleState', 'id', id);
      // verify required parameter 'alleleState' is not null or undefined
      assertParamExists('updateAlleleState', 'alleleState', alleleState);
      const localVarPath = `/api/allele-states/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(alleleState, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AlleleStateResourceApi - functional programming interface
 * @export
 */
export const AlleleStateResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AlleleStateResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AlleleState} alleleState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAlleleState(
      alleleState: AlleleState,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlleleState>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAlleleState(alleleState, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAlleleState(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAlleleState(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAlleleStates(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AlleleState>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAlleleStates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAlleleState(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlleleState>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAlleleState(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {AlleleState} alleleState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateAlleleState(
      id: number,
      alleleState: AlleleState,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlleleState>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateAlleleState(id, alleleState, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {AlleleState} alleleState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAlleleState(
      id: number,
      alleleState: AlleleState,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlleleState>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAlleleState(id, alleleState, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AlleleStateResourceApi - factory interface
 * @export
 */
export const AlleleStateResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AlleleStateResourceApiFp(configuration);
  return {
    /**
     *
     * @param {AlleleState} alleleState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAlleleState(alleleState: AlleleState, options?: any): AxiosPromise<AlleleState> {
      return localVarFp.createAlleleState(alleleState, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAlleleState(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteAlleleState(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAlleleStates(options?: any): AxiosPromise<Array<AlleleState>> {
      return localVarFp.getAllAlleleStates(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAlleleState(id: number, options?: any): AxiosPromise<AlleleState> {
      return localVarFp.getAlleleState(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {AlleleState} alleleState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateAlleleState(id: number, alleleState: AlleleState, options?: any): AxiosPromise<AlleleState> {
      return localVarFp.partialUpdateAlleleState(id, alleleState, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {AlleleState} alleleState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAlleleState(id: number, alleleState: AlleleState, options?: any): AxiosPromise<AlleleState> {
      return localVarFp.updateAlleleState(id, alleleState, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AlleleStateResourceApi - object-oriented interface
 * @export
 * @class AlleleStateResourceApi
 * @extends {BaseAPI}
 */
export class AlleleStateResourceApi extends BaseAPI {
  /**
   *
   * @param {AlleleState} alleleState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlleleStateResourceApi
   */
  public createAlleleState(alleleState: AlleleState, options?: AxiosRequestConfig) {
    return AlleleStateResourceApiFp(this.configuration)
      .createAlleleState(alleleState, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlleleStateResourceApi
   */
  public deleteAlleleState(id: number, options?: AxiosRequestConfig) {
    return AlleleStateResourceApiFp(this.configuration)
      .deleteAlleleState(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlleleStateResourceApi
   */
  public getAllAlleleStates(options?: AxiosRequestConfig) {
    return AlleleStateResourceApiFp(this.configuration)
      .getAllAlleleStates(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlleleStateResourceApi
   */
  public getAlleleState(id: number, options?: AxiosRequestConfig) {
    return AlleleStateResourceApiFp(this.configuration)
      .getAlleleState(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {AlleleState} alleleState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlleleStateResourceApi
   */
  public partialUpdateAlleleState(id: number, alleleState: AlleleState, options?: AxiosRequestConfig) {
    return AlleleStateResourceApiFp(this.configuration)
      .partialUpdateAlleleState(id, alleleState, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {AlleleState} alleleState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlleleStateResourceApi
   */
  public updateAlleleState(id: number, alleleState: AlleleState, options?: AxiosRequestConfig) {
    return AlleleStateResourceApiFp(this.configuration)
      .updateAlleleState(id, alleleState, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AlterationControllerApi - axios parameter creator
 * @export
 */
export const AlterationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Array<AnnotateAlterationBody>} annotateAlterationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateAlterations: async (
      annotateAlterationBody: Array<AnnotateAlterationBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'annotateAlterationBody' is not null or undefined
      assertParamExists('annotateAlterations', 'annotateAlterationBody', annotateAlterationBody);
      const localVarPath = `/api/annotate-alterations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(annotateAlterationBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AlterationControllerApi - functional programming interface
 * @export
 */
export const AlterationControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AlterationControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Array<AnnotateAlterationBody>} annotateAlterationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async annotateAlterations(
      annotateAlterationBody: Array<AnnotateAlterationBody>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AlterationAnnotationStatus>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.annotateAlterations(annotateAlterationBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AlterationControllerApi - factory interface
 * @export
 */
export const AlterationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AlterationControllerApiFp(configuration);
  return {
    /**
     *
     * @param {Array<AnnotateAlterationBody>} annotateAlterationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    annotateAlterations(
      annotateAlterationBody: Array<AnnotateAlterationBody>,
      options?: any,
    ): AxiosPromise<Array<AlterationAnnotationStatus>> {
      return localVarFp.annotateAlterations(annotateAlterationBody, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AlterationControllerApi - object-oriented interface
 * @export
 * @class AlterationControllerApi
 * @extends {BaseAPI}
 */
export class AlterationControllerApi extends BaseAPI {
  /**
   *
   * @param {Array<AnnotateAlterationBody>} annotateAlterationBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationControllerApi
   */
  public annotateAlterations(annotateAlterationBody: Array<AnnotateAlterationBody>, options?: AxiosRequestConfig) {
    return AlterationControllerApiFp(this.configuration)
      .annotateAlterations(annotateAlterationBody, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AlterationResourceApi - axios parameter creator
 * @export
 */
export const AlterationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AlterationCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countAlterations: async (criteria: AlterationCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countAlterations', 'criteria', criteria);
      const localVarPath = `/api/alterations/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Alteration} alteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAlteration: async (alteration: Alteration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'alteration' is not null or undefined
      assertParamExists('createAlteration', 'alteration', alteration);
      const localVarPath = `/api/alterations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(alteration, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAlteration: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteAlteration', 'id', id);
      const localVarPath = `/api/alterations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByGeneId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findByGeneId', 'id', id);
      const localVarPath = `/api/alterations/gene/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AlterationCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAlterations: async (criteria: AlterationCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllAlterations', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllAlterations', 'pageable', pageable);
      const localVarPath = `/api/alterations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAlteration: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAlteration', 'id', id);
      const localVarPath = `/api/alterations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Alteration} alteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateAlteration: async (id: number, alteration: Alteration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateAlteration', 'id', id);
      // verify required parameter 'alteration' is not null or undefined
      assertParamExists('partialUpdateAlteration', 'alteration', alteration);
      const localVarPath = `/api/alterations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(alteration, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAlterations: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchAlterations', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchAlterations', 'pageable', pageable);
      const localVarPath = `/api/alterations/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Alteration} alteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAlteration: async (id: number, alteration: Alteration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateAlteration', 'id', id);
      // verify required parameter 'alteration' is not null or undefined
      assertParamExists('updateAlteration', 'alteration', alteration);
      const localVarPath = `/api/alterations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(alteration, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AlterationResourceApi - functional programming interface
 * @export
 */
export const AlterationResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AlterationResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AlterationCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countAlterations(
      criteria: AlterationCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countAlterations(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Alteration} alteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAlteration(
      alteration: Alteration,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alteration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAlteration(alteration, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAlteration(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAlteration(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findByGeneId(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Alteration>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findByGeneId(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AlterationCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAlterations(
      criteria: AlterationCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Alteration>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAlterations(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAlteration(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alteration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAlteration(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Alteration} alteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateAlteration(
      id: number,
      alteration: Alteration,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alteration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateAlteration(id, alteration, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAlterations(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Alteration>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAlterations(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Alteration} alteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAlteration(
      id: number,
      alteration: Alteration,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alteration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAlteration(id, alteration, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AlterationResourceApi - factory interface
 * @export
 */
export const AlterationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AlterationResourceApiFp(configuration);
  return {
    /**
     *
     * @param {AlterationCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countAlterations(criteria: AlterationCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countAlterations(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Alteration} alteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAlteration(alteration: Alteration, options?: any): AxiosPromise<Alteration> {
      return localVarFp.createAlteration(alteration, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAlteration(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteAlteration(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByGeneId(id: number, options?: any): AxiosPromise<Array<Alteration>> {
      return localVarFp.findByGeneId(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {AlterationCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAlterations(criteria: AlterationCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<Alteration>> {
      return localVarFp.getAllAlterations(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAlteration(id: number, options?: any): AxiosPromise<Alteration> {
      return localVarFp.getAlteration(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Alteration} alteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateAlteration(id: number, alteration: Alteration, options?: any): AxiosPromise<Alteration> {
      return localVarFp.partialUpdateAlteration(id, alteration, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAlterations(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<Alteration>> {
      return localVarFp.searchAlterations(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Alteration} alteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAlteration(id: number, alteration: Alteration, options?: any): AxiosPromise<Alteration> {
      return localVarFp.updateAlteration(id, alteration, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AlterationResourceApi - object-oriented interface
 * @export
 * @class AlterationResourceApi
 * @extends {BaseAPI}
 */
export class AlterationResourceApi extends BaseAPI {
  /**
   *
   * @param {AlterationCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationResourceApi
   */
  public countAlterations(criteria: AlterationCriteria, options?: AxiosRequestConfig) {
    return AlterationResourceApiFp(this.configuration)
      .countAlterations(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Alteration} alteration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationResourceApi
   */
  public createAlteration(alteration: Alteration, options?: AxiosRequestConfig) {
    return AlterationResourceApiFp(this.configuration)
      .createAlteration(alteration, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationResourceApi
   */
  public deleteAlteration(id: number, options?: AxiosRequestConfig) {
    return AlterationResourceApiFp(this.configuration)
      .deleteAlteration(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationResourceApi
   */
  public findByGeneId(id: number, options?: AxiosRequestConfig) {
    return AlterationResourceApiFp(this.configuration)
      .findByGeneId(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AlterationCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationResourceApi
   */
  public getAllAlterations(criteria: AlterationCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return AlterationResourceApiFp(this.configuration)
      .getAllAlterations(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationResourceApi
   */
  public getAlteration(id: number, options?: AxiosRequestConfig) {
    return AlterationResourceApiFp(this.configuration)
      .getAlteration(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Alteration} alteration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationResourceApi
   */
  public partialUpdateAlteration(id: number, alteration: Alteration, options?: AxiosRequestConfig) {
    return AlterationResourceApiFp(this.configuration)
      .partialUpdateAlteration(id, alteration, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationResourceApi
   */
  public searchAlterations(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return AlterationResourceApiFp(this.configuration)
      .searchAlterations(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Alteration} alteration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlterationResourceApi
   */
  public updateAlteration(id: number, alteration: Alteration, options?: AxiosRequestConfig) {
    return AlterationResourceApiFp(this.configuration)
      .updateAlteration(id, alteration, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ApiProxyApi - axios parameter creator
 * @export
 */
export const ApiProxyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/legacy-api/**`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy1: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/legacy-api/**`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy2: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/legacy-api/**`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy3: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/legacy-api/**`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy4: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/legacy-api/**`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy5: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('proxy5', 'body', body);
      const localVarPath = `/legacy-api/**`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (body !== undefined) {
        localVarQueryParameter['body'] = body;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy6: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/legacy-api/**`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApiProxyApi - functional programming interface
 * @export
 */
export const ApiProxyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ApiProxyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proxy(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.proxy(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proxy1(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.proxy1(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proxy2(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.proxy2(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proxy3(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.proxy3(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proxy4(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.proxy4(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proxy5(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.proxy5(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async proxy6(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.proxy6(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ApiProxyApi - factory interface
 * @export
 */
export const ApiProxyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ApiProxyApiFp(configuration);
  return {
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy(body?: string, options?: any): AxiosPromise<string> {
      return localVarFp.proxy(body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy1(body?: string, options?: any): AxiosPromise<string> {
      return localVarFp.proxy1(body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy2(body?: string, options?: any): AxiosPromise<string> {
      return localVarFp.proxy2(body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy3(body?: string, options?: any): AxiosPromise<string> {
      return localVarFp.proxy3(body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy4(body?: string, options?: any): AxiosPromise<string> {
      return localVarFp.proxy4(body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy5(body: string, options?: any): AxiosPromise<string> {
      return localVarFp.proxy5(body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proxy6(body?: string, options?: any): AxiosPromise<string> {
      return localVarFp.proxy6(body, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ApiProxyApi - object-oriented interface
 * @export
 * @class ApiProxyApi
 * @extends {BaseAPI}
 */
export class ApiProxyApi extends BaseAPI {
  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiProxyApi
   */
  public proxy(body?: string, options?: AxiosRequestConfig) {
    return ApiProxyApiFp(this.configuration)
      .proxy(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiProxyApi
   */
  public proxy1(body?: string, options?: AxiosRequestConfig) {
    return ApiProxyApiFp(this.configuration)
      .proxy1(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiProxyApi
   */
  public proxy2(body?: string, options?: AxiosRequestConfig) {
    return ApiProxyApiFp(this.configuration)
      .proxy2(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiProxyApi
   */
  public proxy3(body?: string, options?: AxiosRequestConfig) {
    return ApiProxyApiFp(this.configuration)
      .proxy3(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiProxyApi
   */
  public proxy4(body?: string, options?: AxiosRequestConfig) {
    return ApiProxyApiFp(this.configuration)
      .proxy4(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiProxyApi
   */
  public proxy5(body: string, options?: AxiosRequestConfig) {
    return ApiProxyApiFp(this.configuration)
      .proxy5(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiProxyApi
   */
  public proxy6(body?: string, options?: AxiosRequestConfig) {
    return ApiProxyApiFp(this.configuration)
      .proxy6(body, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ArticleResourceApi - axios parameter creator
 * @export
 */
export const ArticleResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ArticleCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countArticles: async (criteria: ArticleCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countArticles', 'criteria', criteria);
      const localVarPath = `/api/articles/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Article} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createArticle: async (article: Article, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'article' is not null or undefined
      assertParamExists('createArticle', 'article', article);
      const localVarPath = `/api/articles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(article, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteArticle: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteArticle', 'id', id);
      const localVarPath = `/api/articles/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ArticleCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArticles: async (criteria: ArticleCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllArticles', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllArticles', 'pageable', pageable);
      const localVarPath = `/api/articles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticle: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getArticle', 'id', id);
      const localVarPath = `/api/articles/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} pmid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPubMedArticle: async (pmid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pmid' is not null or undefined
      assertParamExists('getPubMedArticle', 'pmid', pmid);
      const localVarPath = `/api/articles/pubmed/{pmid}`.replace(`{${'pmid'}}`, encodeURIComponent(String(pmid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Article} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateArticle: async (id: number, article: Article, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateArticle', 'id', id);
      // verify required parameter 'article' is not null or undefined
      assertParamExists('partialUpdateArticle', 'article', article);
      const localVarPath = `/api/articles/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(article, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchArticles: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchArticles', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchArticles', 'pageable', pageable);
      const localVarPath = `/api/articles/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Article} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateArticle: async (id: number, article: Article, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateArticle', 'id', id);
      // verify required parameter 'article' is not null or undefined
      assertParamExists('updateArticle', 'article', article);
      const localVarPath = `/api/articles/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(article, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ArticleResourceApi - functional programming interface
 * @export
 */
export const ArticleResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArticleResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ArticleCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countArticles(
      criteria: ArticleCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countArticles(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Article} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createArticle(
      article: Article,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createArticle(article, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteArticle(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArticle(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ArticleCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArticles(
      criteria: ArticleCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllArticles(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticle(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticle(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} pmid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPubMedArticle(
      pmid: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PubMedDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPubMedArticle(pmid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Article} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateArticle(
      id: number,
      article: Article,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateArticle(id, article, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchArticles(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchArticles(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Article} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateArticle(
      id: number,
      article: Article,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticle(id, article, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ArticleResourceApi - factory interface
 * @export
 */
export const ArticleResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ArticleResourceApiFp(configuration);
  return {
    /**
     *
     * @param {ArticleCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countArticles(criteria: ArticleCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countArticles(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Article} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createArticle(article: Article, options?: any): AxiosPromise<Article> {
      return localVarFp.createArticle(article, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteArticle(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteArticle(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ArticleCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArticles(criteria: ArticleCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<Article>> {
      return localVarFp.getAllArticles(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticle(id: number, options?: any): AxiosPromise<Article> {
      return localVarFp.getArticle(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} pmid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPubMedArticle(pmid: number, options?: any): AxiosPromise<PubMedDTO> {
      return localVarFp.getPubMedArticle(pmid, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Article} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateArticle(id: number, article: Article, options?: any): AxiosPromise<Article> {
      return localVarFp.partialUpdateArticle(id, article, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchArticles(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<Article>> {
      return localVarFp.searchArticles(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Article} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateArticle(id: number, article: Article, options?: any): AxiosPromise<Article> {
      return localVarFp.updateArticle(id, article, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ArticleResourceApi - object-oriented interface
 * @export
 * @class ArticleResourceApi
 * @extends {BaseAPI}
 */
export class ArticleResourceApi extends BaseAPI {
  /**
   *
   * @param {ArticleCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleResourceApi
   */
  public countArticles(criteria: ArticleCriteria, options?: AxiosRequestConfig) {
    return ArticleResourceApiFp(this.configuration)
      .countArticles(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Article} article
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleResourceApi
   */
  public createArticle(article: Article, options?: AxiosRequestConfig) {
    return ArticleResourceApiFp(this.configuration)
      .createArticle(article, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleResourceApi
   */
  public deleteArticle(id: number, options?: AxiosRequestConfig) {
    return ArticleResourceApiFp(this.configuration)
      .deleteArticle(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ArticleCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleResourceApi
   */
  public getAllArticles(criteria: ArticleCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return ArticleResourceApiFp(this.configuration)
      .getAllArticles(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleResourceApi
   */
  public getArticle(id: number, options?: AxiosRequestConfig) {
    return ArticleResourceApiFp(this.configuration)
      .getArticle(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} pmid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleResourceApi
   */
  public getPubMedArticle(pmid: number, options?: AxiosRequestConfig) {
    return ArticleResourceApiFp(this.configuration)
      .getPubMedArticle(pmid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Article} article
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleResourceApi
   */
  public partialUpdateArticle(id: number, article: Article, options?: AxiosRequestConfig) {
    return ArticleResourceApiFp(this.configuration)
      .partialUpdateArticle(id, article, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleResourceApi
   */
  public searchArticles(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return ArticleResourceApiFp(this.configuration)
      .searchArticles(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Article} article
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleResourceApi
   */
  public updateArticle(id: number, article: Article, options?: AxiosRequestConfig) {
    return ArticleResourceApiFp(this.configuration)
      .updateArticle(id, article, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AssociationResourceApi - axios parameter creator
 * @export
 */
export const AssociationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Association} association
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAssociation: async (association: Association, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'association' is not null or undefined
      assertParamExists('createAssociation', 'association', association);
      const localVarPath = `/api/associations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(association, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAssociation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteAssociation', 'id', id);
      const localVarPath = `/api/associations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [filter]
     * @param {boolean} [eagerload]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAssociations: async (filter?: string, eagerload?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/associations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (eagerload !== undefined) {
        localVarQueryParameter['eagerload'] = eagerload;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAssociation', 'id', id);
      const localVarPath = `/api/associations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAssociations: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchAssociations', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchAssociations', 'pageable', pageable);
      const localVarPath = `/api/associations/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssociationResourceApi - functional programming interface
 * @export
 */
export const AssociationResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AssociationResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Association} association
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAssociation(
      association: Association,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Association>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAssociation(association, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAssociation(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssociation(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [filter]
     * @param {boolean} [eagerload]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAssociations(
      filter?: string,
      eagerload?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Association>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAssociations(filter, eagerload, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociation(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Association>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociation(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAssociations(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Association>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAssociations(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AssociationResourceApi - factory interface
 * @export
 */
export const AssociationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AssociationResourceApiFp(configuration);
  return {
    /**
     *
     * @param {Association} association
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAssociation(association: Association, options?: any): AxiosPromise<Association> {
      return localVarFp.createAssociation(association, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAssociation(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteAssociation(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [filter]
     * @param {boolean} [eagerload]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAssociations(filter?: string, eagerload?: boolean, options?: any): AxiosPromise<Array<Association>> {
      return localVarFp.getAllAssociations(filter, eagerload, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociation(id: number, options?: any): AxiosPromise<Association> {
      return localVarFp.getAssociation(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAssociations(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<Association>> {
      return localVarFp.searchAssociations(query, pageable, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AssociationResourceApi - object-oriented interface
 * @export
 * @class AssociationResourceApi
 * @extends {BaseAPI}
 */
export class AssociationResourceApi extends BaseAPI {
  /**
   *
   * @param {Association} association
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssociationResourceApi
   */
  public createAssociation(association: Association, options?: AxiosRequestConfig) {
    return AssociationResourceApiFp(this.configuration)
      .createAssociation(association, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssociationResourceApi
   */
  public deleteAssociation(id: number, options?: AxiosRequestConfig) {
    return AssociationResourceApiFp(this.configuration)
      .deleteAssociation(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [filter]
   * @param {boolean} [eagerload]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssociationResourceApi
   */
  public getAllAssociations(filter?: string, eagerload?: boolean, options?: AxiosRequestConfig) {
    return AssociationResourceApiFp(this.configuration)
      .getAllAssociations(filter, eagerload, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssociationResourceApi
   */
  public getAssociation(id: number, options?: AxiosRequestConfig) {
    return AssociationResourceApiFp(this.configuration)
      .getAssociation(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssociationResourceApi
   */
  public searchAssociations(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return AssociationResourceApiFp(this.configuration)
      .searchAssociations(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AuditResourceApi - axios parameter creator
 * @export
 */
export const AuditResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditedEntities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/audits/entity/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} entityType
     * @param {number} limit
     * @param {MultiValueMapStringString} queryParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChanges: async (
      entityType: string,
      limit: number,
      queryParams: MultiValueMapStringString,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityType' is not null or undefined
      assertParamExists('getChanges', 'entityType', entityType);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('getChanges', 'limit', limit);
      // verify required parameter 'queryParams' is not null or undefined
      assertParamExists('getChanges', 'queryParams', queryParams);
      const localVarPath = `/api/audits/entity/changes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (entityType !== undefined) {
        localVarQueryParameter['entityType'] = entityType;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (queryParams !== undefined) {
        localVarQueryParameter['queryParams'] = queryParams;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} entityType
     * @param {number} entityId
     * @param {number} commitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrevVersion: async (
      entityType: string,
      entityId: number,
      commitId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityType' is not null or undefined
      assertParamExists('getPrevVersion', 'entityType', entityType);
      // verify required parameter 'entityId' is not null or undefined
      assertParamExists('getPrevVersion', 'entityId', entityId);
      // verify required parameter 'commitId' is not null or undefined
      assertParamExists('getPrevVersion', 'commitId', commitId);
      const localVarPath = `/api/audits/entity/changes/version/previous`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (entityType !== undefined) {
        localVarQueryParameter['entityType'] = entityType;
      }

      if (entityId !== undefined) {
        localVarQueryParameter['entityId'] = entityId;
      }

      if (commitId !== undefined) {
        localVarQueryParameter['commitId'] = commitId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuditResourceApi - functional programming interface
 * @export
 */
export const AuditResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuditResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAuditedEntities(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditedEntities(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} entityType
     * @param {number} limit
     * @param {MultiValueMapStringString} queryParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChanges(
      entityType: string,
      limit: number,
      queryParams: MultiValueMapStringString,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityAuditEvent>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getChanges(entityType, limit, queryParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} entityType
     * @param {number} entityId
     * @param {number} commitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrevVersion(
      entityType: string,
      entityId: number,
      commitId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityAuditEvent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrevVersion(entityType, entityId, commitId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuditResourceApi - factory interface
 * @export
 */
export const AuditResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuditResourceApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditedEntities(options?: any): AxiosPromise<Array<string>> {
      return localVarFp.getAuditedEntities(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} entityType
     * @param {number} limit
     * @param {MultiValueMapStringString} queryParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChanges(
      entityType: string,
      limit: number,
      queryParams: MultiValueMapStringString,
      options?: any,
    ): AxiosPromise<Array<EntityAuditEvent>> {
      return localVarFp.getChanges(entityType, limit, queryParams, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} entityType
     * @param {number} entityId
     * @param {number} commitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrevVersion(entityType: string, entityId: number, commitId: number, options?: any): AxiosPromise<EntityAuditEvent> {
      return localVarFp.getPrevVersion(entityType, entityId, commitId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AuditResourceApi - object-oriented interface
 * @export
 * @class AuditResourceApi
 * @extends {BaseAPI}
 */
export class AuditResourceApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditResourceApi
   */
  public getAuditedEntities(options?: AxiosRequestConfig) {
    return AuditResourceApiFp(this.configuration)
      .getAuditedEntities(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} entityType
   * @param {number} limit
   * @param {MultiValueMapStringString} queryParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditResourceApi
   */
  public getChanges(entityType: string, limit: number, queryParams: MultiValueMapStringString, options?: AxiosRequestConfig) {
    return AuditResourceApiFp(this.configuration)
      .getChanges(entityType, limit, queryParams, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} entityType
   * @param {number} entityId
   * @param {number} commitId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditResourceApi
   */
  public getPrevVersion(entityType: string, entityId: number, commitId: number, options?: AxiosRequestConfig) {
    return AuditResourceApiFp(this.configuration)
      .getPrevVersion(entityType, entityId, commitId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AuthInfoResourceApi - axios parameter creator
 * @export
 */
export const AuthInfoResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuthInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth-info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthInfoResourceApi - functional programming interface
 * @export
 */
export const AuthInfoResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthInfoResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAuthInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthInfoVM>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthInfo(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthInfoResourceApi - factory interface
 * @export
 */
export const AuthInfoResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthInfoResourceApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuthInfo(options?: any): AxiosPromise<AuthInfoVM> {
      return localVarFp.getAuthInfo(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AuthInfoResourceApi - object-oriented interface
 * @export
 * @class AuthInfoResourceApi
 * @extends {BaseAPI}
 */
export class AuthInfoResourceApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthInfoResourceApi
   */
  public getAuthInfo(options?: AxiosRequestConfig) {
    return AuthInfoResourceApiFp(this.configuration)
      .getAuthInfo(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CancerTypeResourceApi - axios parameter creator
 * @export
 */
export const CancerTypeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CancerTypeCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countCancerTypes: async (criteria: CancerTypeCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countCancerTypes', 'criteria', criteria);
      const localVarPath = `/api/cancer-types/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CancerType} cancerType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCancerType: async (cancerType: CancerType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cancerType' is not null or undefined
      assertParamExists('createCancerType', 'cancerType', cancerType);
      const localVarPath = `/api/cancer-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(cancerType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCancerType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCancerType', 'id', id);
      const localVarPath = `/api/cancer-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CancerTypeCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCancerTypes: async (criteria: CancerTypeCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllCancerTypes', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllCancerTypes', 'pageable', pageable);
      const localVarPath = `/api/cancer-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCancerType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getCancerType', 'id', id);
      const localVarPath = `/api/cancer-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RelevantCancerTypeBody} relevantCancerTypeBody
     * @param {string} [levelOfEvidence]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelevantCancerTypes: async (
      relevantCancerTypeBody: RelevantCancerTypeBody,
      levelOfEvidence?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'relevantCancerTypeBody' is not null or undefined
      assertParamExists('getRelevantCancerTypes', 'relevantCancerTypeBody', relevantCancerTypeBody);
      const localVarPath = `/api/cancer-types/relevant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (levelOfEvidence !== undefined) {
        localVarQueryParameter['levelOfEvidence'] = levelOfEvidence;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(relevantCancerTypeBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CancerType} cancerType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateCancerType: async (id: number, cancerType: CancerType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateCancerType', 'id', id);
      // verify required parameter 'cancerType' is not null or undefined
      assertParamExists('partialUpdateCancerType', 'cancerType', cancerType);
      const localVarPath = `/api/cancer-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(cancerType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCancerTypes: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchCancerTypes', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchCancerTypes', 'pageable', pageable);
      const localVarPath = `/api/cancer-types/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CancerType} cancerType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCancerType: async (id: number, cancerType: CancerType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateCancerType', 'id', id);
      // verify required parameter 'cancerType' is not null or undefined
      assertParamExists('updateCancerType', 'cancerType', cancerType);
      const localVarPath = `/api/cancer-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(cancerType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CancerTypeResourceApi - functional programming interface
 * @export
 */
export const CancerTypeResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CancerTypeResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CancerTypeCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countCancerTypes(
      criteria: CancerTypeCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countCancerTypes(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CancerType} cancerType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCancerType(
      cancerType: CancerType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancerType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCancerType(cancerType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCancerType(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCancerType(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CancerTypeCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCancerTypes(
      criteria: CancerTypeCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CancerType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCancerTypes(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCancerType(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancerType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCancerType(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RelevantCancerTypeBody} relevantCancerTypeBody
     * @param {string} [levelOfEvidence]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelevantCancerTypes(
      relevantCancerTypeBody: RelevantCancerTypeBody,
      levelOfEvidence?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CancerType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRelevantCancerTypes(relevantCancerTypeBody, levelOfEvidence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {CancerType} cancerType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateCancerType(
      id: number,
      cancerType: CancerType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancerType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCancerType(id, cancerType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchCancerTypes(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CancerType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchCancerTypes(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {CancerType} cancerType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCancerType(
      id: number,
      cancerType: CancerType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancerType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCancerType(id, cancerType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CancerTypeResourceApi - factory interface
 * @export
 */
export const CancerTypeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CancerTypeResourceApiFp(configuration);
  return {
    /**
     *
     * @param {CancerTypeCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countCancerTypes(criteria: CancerTypeCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countCancerTypes(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CancerType} cancerType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCancerType(cancerType: CancerType, options?: any): AxiosPromise<CancerType> {
      return localVarFp.createCancerType(cancerType, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCancerType(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCancerType(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CancerTypeCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCancerTypes(criteria: CancerTypeCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<CancerType>> {
      return localVarFp.getAllCancerTypes(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCancerType(id: number, options?: any): AxiosPromise<CancerType> {
      return localVarFp.getCancerType(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {RelevantCancerTypeBody} relevantCancerTypeBody
     * @param {string} [levelOfEvidence]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelevantCancerTypes(
      relevantCancerTypeBody: RelevantCancerTypeBody,
      levelOfEvidence?: string,
      options?: any,
    ): AxiosPromise<Array<CancerType>> {
      return localVarFp.getRelevantCancerTypes(relevantCancerTypeBody, levelOfEvidence, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CancerType} cancerType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateCancerType(id: number, cancerType: CancerType, options?: any): AxiosPromise<CancerType> {
      return localVarFp.partialUpdateCancerType(id, cancerType, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCancerTypes(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<CancerType>> {
      return localVarFp.searchCancerTypes(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CancerType} cancerType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCancerType(id: number, cancerType: CancerType, options?: any): AxiosPromise<CancerType> {
      return localVarFp.updateCancerType(id, cancerType, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CancerTypeResourceApi - object-oriented interface
 * @export
 * @class CancerTypeResourceApi
 * @extends {BaseAPI}
 */
export class CancerTypeResourceApi extends BaseAPI {
  /**
   *
   * @param {CancerTypeCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerTypeResourceApi
   */
  public countCancerTypes(criteria: CancerTypeCriteria, options?: AxiosRequestConfig) {
    return CancerTypeResourceApiFp(this.configuration)
      .countCancerTypes(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CancerType} cancerType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerTypeResourceApi
   */
  public createCancerType(cancerType: CancerType, options?: AxiosRequestConfig) {
    return CancerTypeResourceApiFp(this.configuration)
      .createCancerType(cancerType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerTypeResourceApi
   */
  public deleteCancerType(id: number, options?: AxiosRequestConfig) {
    return CancerTypeResourceApiFp(this.configuration)
      .deleteCancerType(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CancerTypeCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerTypeResourceApi
   */
  public getAllCancerTypes(criteria: CancerTypeCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return CancerTypeResourceApiFp(this.configuration)
      .getAllCancerTypes(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerTypeResourceApi
   */
  public getCancerType(id: number, options?: AxiosRequestConfig) {
    return CancerTypeResourceApiFp(this.configuration)
      .getCancerType(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RelevantCancerTypeBody} relevantCancerTypeBody
   * @param {string} [levelOfEvidence]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerTypeResourceApi
   */
  public getRelevantCancerTypes(relevantCancerTypeBody: RelevantCancerTypeBody, levelOfEvidence?: string, options?: AxiosRequestConfig) {
    return CancerTypeResourceApiFp(this.configuration)
      .getRelevantCancerTypes(relevantCancerTypeBody, levelOfEvidence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CancerType} cancerType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerTypeResourceApi
   */
  public partialUpdateCancerType(id: number, cancerType: CancerType, options?: AxiosRequestConfig) {
    return CancerTypeResourceApiFp(this.configuration)
      .partialUpdateCancerType(id, cancerType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerTypeResourceApi
   */
  public searchCancerTypes(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return CancerTypeResourceApiFp(this.configuration)
      .searchCancerTypes(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CancerType} cancerType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CancerTypeResourceApi
   */
  public updateCancerType(id: number, cancerType: CancerType, options?: AxiosRequestConfig) {
    return CancerTypeResourceApiFp(this.configuration)
      .updateCancerType(id, cancerType, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CategoricalAlterationResourceApi - axios parameter creator
 * @export
 */
export const CategoricalAlterationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CategoricalAlteration} categoricalAlteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCategoricalAlteration: async (
      categoricalAlteration: CategoricalAlteration,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoricalAlteration' is not null or undefined
      assertParamExists('createCategoricalAlteration', 'categoricalAlteration', categoricalAlteration);
      const localVarPath = `/api/categorical-alterations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(categoricalAlteration, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCategoricalAlteration: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCategoricalAlteration', 'id', id);
      const localVarPath = `/api/categorical-alterations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCategoricalAlterations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/categorical-alterations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoricalAlteration: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getCategoricalAlteration', 'id', id);
      const localVarPath = `/api/categorical-alterations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CategoricalAlteration} categoricalAlteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateCategoricalAlteration: async (
      id: number,
      categoricalAlteration: CategoricalAlteration,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateCategoricalAlteration', 'id', id);
      // verify required parameter 'categoricalAlteration' is not null or undefined
      assertParamExists('partialUpdateCategoricalAlteration', 'categoricalAlteration', categoricalAlteration);
      const localVarPath = `/api/categorical-alterations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(categoricalAlteration, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CategoricalAlteration} categoricalAlteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCategoricalAlteration: async (
      id: number,
      categoricalAlteration: CategoricalAlteration,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateCategoricalAlteration', 'id', id);
      // verify required parameter 'categoricalAlteration' is not null or undefined
      assertParamExists('updateCategoricalAlteration', 'categoricalAlteration', categoricalAlteration);
      const localVarPath = `/api/categorical-alterations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(categoricalAlteration, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CategoricalAlterationResourceApi - functional programming interface
 * @export
 */
export const CategoricalAlterationResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CategoricalAlterationResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CategoricalAlteration} categoricalAlteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCategoricalAlteration(
      categoricalAlteration: CategoricalAlteration,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoricalAlteration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCategoricalAlteration(categoricalAlteration, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCategoricalAlteration(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategoricalAlteration(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCategoricalAlterations(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoricalAlteration>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCategoricalAlterations(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategoricalAlteration(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoricalAlteration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoricalAlteration(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {CategoricalAlteration} categoricalAlteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateCategoricalAlteration(
      id: number,
      categoricalAlteration: CategoricalAlteration,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoricalAlteration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCategoricalAlteration(id, categoricalAlteration, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {CategoricalAlteration} categoricalAlteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCategoricalAlteration(
      id: number,
      categoricalAlteration: CategoricalAlteration,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoricalAlteration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategoricalAlteration(id, categoricalAlteration, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CategoricalAlterationResourceApi - factory interface
 * @export
 */
export const CategoricalAlterationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CategoricalAlterationResourceApiFp(configuration);
  return {
    /**
     *
     * @param {CategoricalAlteration} categoricalAlteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCategoricalAlteration(categoricalAlteration: CategoricalAlteration, options?: any): AxiosPromise<CategoricalAlteration> {
      return localVarFp.createCategoricalAlteration(categoricalAlteration, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCategoricalAlteration(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCategoricalAlteration(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCategoricalAlterations(options?: any): AxiosPromise<Array<CategoricalAlteration>> {
      return localVarFp.getAllCategoricalAlterations(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoricalAlteration(id: number, options?: any): AxiosPromise<CategoricalAlteration> {
      return localVarFp.getCategoricalAlteration(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CategoricalAlteration} categoricalAlteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateCategoricalAlteration(
      id: number,
      categoricalAlteration: CategoricalAlteration,
      options?: any,
    ): AxiosPromise<CategoricalAlteration> {
      return localVarFp.partialUpdateCategoricalAlteration(id, categoricalAlteration, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CategoricalAlteration} categoricalAlteration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCategoricalAlteration(
      id: number,
      categoricalAlteration: CategoricalAlteration,
      options?: any,
    ): AxiosPromise<CategoricalAlteration> {
      return localVarFp.updateCategoricalAlteration(id, categoricalAlteration, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CategoricalAlterationResourceApi - object-oriented interface
 * @export
 * @class CategoricalAlterationResourceApi
 * @extends {BaseAPI}
 */
export class CategoricalAlterationResourceApi extends BaseAPI {
  /**
   *
   * @param {CategoricalAlteration} categoricalAlteration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoricalAlterationResourceApi
   */
  public createCategoricalAlteration(categoricalAlteration: CategoricalAlteration, options?: AxiosRequestConfig) {
    return CategoricalAlterationResourceApiFp(this.configuration)
      .createCategoricalAlteration(categoricalAlteration, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoricalAlterationResourceApi
   */
  public deleteCategoricalAlteration(id: number, options?: AxiosRequestConfig) {
    return CategoricalAlterationResourceApiFp(this.configuration)
      .deleteCategoricalAlteration(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoricalAlterationResourceApi
   */
  public getAllCategoricalAlterations(options?: AxiosRequestConfig) {
    return CategoricalAlterationResourceApiFp(this.configuration)
      .getAllCategoricalAlterations(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoricalAlterationResourceApi
   */
  public getCategoricalAlteration(id: number, options?: AxiosRequestConfig) {
    return CategoricalAlterationResourceApiFp(this.configuration)
      .getCategoricalAlteration(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CategoricalAlteration} categoricalAlteration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoricalAlterationResourceApi
   */
  public partialUpdateCategoricalAlteration(id: number, categoricalAlteration: CategoricalAlteration, options?: AxiosRequestConfig) {
    return CategoricalAlterationResourceApiFp(this.configuration)
      .partialUpdateCategoricalAlteration(id, categoricalAlteration, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CategoricalAlteration} categoricalAlteration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoricalAlterationResourceApi
   */
  public updateCategoricalAlteration(id: number, categoricalAlteration: CategoricalAlteration, options?: AxiosRequestConfig) {
    return CategoricalAlterationResourceApiFp(this.configuration)
      .updateCategoricalAlteration(id, categoricalAlteration, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ClinicalTrialArmResourceApi - axios parameter creator
 * @export
 */
export const ClinicalTrialArmResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ClinicalTrialArmCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countClinicalTrialArms: async (criteria: ClinicalTrialArmCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countClinicalTrialArms', 'criteria', criteria);
      const localVarPath = `/api/clinical-trial-arms/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ClinicalTrialArm} clinicalTrialArm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClinicalTrialArm: async (clinicalTrialArm: ClinicalTrialArm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'clinicalTrialArm' is not null or undefined
      assertParamExists('createClinicalTrialArm', 'clinicalTrialArm', clinicalTrialArm);
      const localVarPath = `/api/clinical-trial-arms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(clinicalTrialArm, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClinicalTrialArm: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteClinicalTrialArm', 'id', id);
      const localVarPath = `/api/clinical-trial-arms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ClinicalTrialArmCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClinicalTrialArms: async (
      criteria: ClinicalTrialArmCriteria,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllClinicalTrialArms', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllClinicalTrialArms', 'pageable', pageable);
      const localVarPath = `/api/clinical-trial-arms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClinicalTrialArm: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClinicalTrialArm', 'id', id);
      const localVarPath = `/api/clinical-trial-arms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrialArm} clinicalTrialArm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateClinicalTrialArm: async (
      id: number,
      clinicalTrialArm: ClinicalTrialArm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateClinicalTrialArm', 'id', id);
      // verify required parameter 'clinicalTrialArm' is not null or undefined
      assertParamExists('partialUpdateClinicalTrialArm', 'clinicalTrialArm', clinicalTrialArm);
      const localVarPath = `/api/clinical-trial-arms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(clinicalTrialArm, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchClinicalTrialArms: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchClinicalTrialArms', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchClinicalTrialArms', 'pageable', pageable);
      const localVarPath = `/api/clinical-trial-arms/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrialArm} clinicalTrialArm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClinicalTrialArm: async (
      id: number,
      clinicalTrialArm: ClinicalTrialArm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateClinicalTrialArm', 'id', id);
      // verify required parameter 'clinicalTrialArm' is not null or undefined
      assertParamExists('updateClinicalTrialArm', 'clinicalTrialArm', clinicalTrialArm);
      const localVarPath = `/api/clinical-trial-arms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(clinicalTrialArm, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClinicalTrialArmResourceApi - functional programming interface
 * @export
 */
export const ClinicalTrialArmResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClinicalTrialArmResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ClinicalTrialArmCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countClinicalTrialArms(
      criteria: ClinicalTrialArmCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countClinicalTrialArms(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ClinicalTrialArm} clinicalTrialArm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createClinicalTrialArm(
      clinicalTrialArm: ClinicalTrialArm,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicalTrialArm>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createClinicalTrialArm(clinicalTrialArm, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteClinicalTrialArm(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClinicalTrialArm(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ClinicalTrialArmCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClinicalTrialArms(
      criteria: ClinicalTrialArmCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicalTrialArm>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClinicalTrialArms(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClinicalTrialArm(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicalTrialArm>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClinicalTrialArm(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrialArm} clinicalTrialArm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateClinicalTrialArm(
      id: number,
      clinicalTrialArm: ClinicalTrialArm,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicalTrialArm>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateClinicalTrialArm(id, clinicalTrialArm, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchClinicalTrialArms(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicalTrialArm>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchClinicalTrialArms(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrialArm} clinicalTrialArm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClinicalTrialArm(
      id: number,
      clinicalTrialArm: ClinicalTrialArm,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicalTrialArm>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateClinicalTrialArm(id, clinicalTrialArm, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ClinicalTrialArmResourceApi - factory interface
 * @export
 */
export const ClinicalTrialArmResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ClinicalTrialArmResourceApiFp(configuration);
  return {
    /**
     *
     * @param {ClinicalTrialArmCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countClinicalTrialArms(criteria: ClinicalTrialArmCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countClinicalTrialArms(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ClinicalTrialArm} clinicalTrialArm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClinicalTrialArm(clinicalTrialArm: ClinicalTrialArm, options?: any): AxiosPromise<ClinicalTrialArm> {
      return localVarFp.createClinicalTrialArm(clinicalTrialArm, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClinicalTrialArm(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteClinicalTrialArm(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ClinicalTrialArmCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClinicalTrialArms(criteria: ClinicalTrialArmCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<ClinicalTrialArm>> {
      return localVarFp.getAllClinicalTrialArms(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClinicalTrialArm(id: number, options?: any): AxiosPromise<ClinicalTrialArm> {
      return localVarFp.getClinicalTrialArm(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrialArm} clinicalTrialArm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateClinicalTrialArm(id: number, clinicalTrialArm: ClinicalTrialArm, options?: any): AxiosPromise<ClinicalTrialArm> {
      return localVarFp.partialUpdateClinicalTrialArm(id, clinicalTrialArm, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchClinicalTrialArms(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<ClinicalTrialArm>> {
      return localVarFp.searchClinicalTrialArms(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrialArm} clinicalTrialArm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClinicalTrialArm(id: number, clinicalTrialArm: ClinicalTrialArm, options?: any): AxiosPromise<ClinicalTrialArm> {
      return localVarFp.updateClinicalTrialArm(id, clinicalTrialArm, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ClinicalTrialArmResourceApi - object-oriented interface
 * @export
 * @class ClinicalTrialArmResourceApi
 * @extends {BaseAPI}
 */
export class ClinicalTrialArmResourceApi extends BaseAPI {
  /**
   *
   * @param {ClinicalTrialArmCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialArmResourceApi
   */
  public countClinicalTrialArms(criteria: ClinicalTrialArmCriteria, options?: AxiosRequestConfig) {
    return ClinicalTrialArmResourceApiFp(this.configuration)
      .countClinicalTrialArms(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ClinicalTrialArm} clinicalTrialArm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialArmResourceApi
   */
  public createClinicalTrialArm(clinicalTrialArm: ClinicalTrialArm, options?: AxiosRequestConfig) {
    return ClinicalTrialArmResourceApiFp(this.configuration)
      .createClinicalTrialArm(clinicalTrialArm, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialArmResourceApi
   */
  public deleteClinicalTrialArm(id: number, options?: AxiosRequestConfig) {
    return ClinicalTrialArmResourceApiFp(this.configuration)
      .deleteClinicalTrialArm(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ClinicalTrialArmCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialArmResourceApi
   */
  public getAllClinicalTrialArms(criteria: ClinicalTrialArmCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return ClinicalTrialArmResourceApiFp(this.configuration)
      .getAllClinicalTrialArms(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialArmResourceApi
   */
  public getClinicalTrialArm(id: number, options?: AxiosRequestConfig) {
    return ClinicalTrialArmResourceApiFp(this.configuration)
      .getClinicalTrialArm(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {ClinicalTrialArm} clinicalTrialArm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialArmResourceApi
   */
  public partialUpdateClinicalTrialArm(id: number, clinicalTrialArm: ClinicalTrialArm, options?: AxiosRequestConfig) {
    return ClinicalTrialArmResourceApiFp(this.configuration)
      .partialUpdateClinicalTrialArm(id, clinicalTrialArm, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialArmResourceApi
   */
  public searchClinicalTrialArms(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return ClinicalTrialArmResourceApiFp(this.configuration)
      .searchClinicalTrialArms(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {ClinicalTrialArm} clinicalTrialArm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialArmResourceApi
   */
  public updateClinicalTrialArm(id: number, clinicalTrialArm: ClinicalTrialArm, options?: AxiosRequestConfig) {
    return ClinicalTrialArmResourceApiFp(this.configuration)
      .updateClinicalTrialArm(id, clinicalTrialArm, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ClinicalTrialResourceApi - axios parameter creator
 * @export
 */
export const ClinicalTrialResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ClinicalTrialCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countClinicalTrials: async (criteria: ClinicalTrialCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countClinicalTrials', 'criteria', criteria);
      const localVarPath = `/api/clinical-trials/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ClinicalTrial} clinicalTrial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClinicalTrial: async (clinicalTrial: ClinicalTrial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'clinicalTrial' is not null or undefined
      assertParamExists('createClinicalTrial', 'clinicalTrial', clinicalTrial);
      const localVarPath = `/api/clinical-trials`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(clinicalTrial, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClinicalTrial: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteClinicalTrial', 'id', id);
      const localVarPath = `/api/clinical-trials/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ClinicalTrialCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClinicalTrials: async (
      criteria: ClinicalTrialCriteria,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllClinicalTrials', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllClinicalTrials', 'pageable', pageable);
      const localVarPath = `/api/clinical-trials`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClinicalTrial: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClinicalTrial', 'id', id);
      const localVarPath = `/api/clinical-trials/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrial} clinicalTrial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateClinicalTrial: async (
      id: number,
      clinicalTrial: ClinicalTrial,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateClinicalTrial', 'id', id);
      // verify required parameter 'clinicalTrial' is not null or undefined
      assertParamExists('partialUpdateClinicalTrial', 'clinicalTrial', clinicalTrial);
      const localVarPath = `/api/clinical-trials/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(clinicalTrial, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchClinicalTrials: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchClinicalTrials', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchClinicalTrials', 'pageable', pageable);
      const localVarPath = `/api/clinical-trials/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrial} clinicalTrial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClinicalTrial: async (id: number, clinicalTrial: ClinicalTrial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateClinicalTrial', 'id', id);
      // verify required parameter 'clinicalTrial' is not null or undefined
      assertParamExists('updateClinicalTrial', 'clinicalTrial', clinicalTrial);
      const localVarPath = `/api/clinical-trials/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(clinicalTrial, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClinicalTrialResourceApi - functional programming interface
 * @export
 */
export const ClinicalTrialResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClinicalTrialResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ClinicalTrialCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countClinicalTrials(
      criteria: ClinicalTrialCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countClinicalTrials(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ClinicalTrial} clinicalTrial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createClinicalTrial(
      clinicalTrial: ClinicalTrial,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicalTrial>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createClinicalTrial(clinicalTrial, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteClinicalTrial(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClinicalTrial(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ClinicalTrialCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClinicalTrials(
      criteria: ClinicalTrialCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicalTrial>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClinicalTrials(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClinicalTrial(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicalTrial>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClinicalTrial(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrial} clinicalTrial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateClinicalTrial(
      id: number,
      clinicalTrial: ClinicalTrial,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicalTrial>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateClinicalTrial(id, clinicalTrial, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchClinicalTrials(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicalTrial>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchClinicalTrials(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrial} clinicalTrial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClinicalTrial(
      id: number,
      clinicalTrial: ClinicalTrial,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicalTrial>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateClinicalTrial(id, clinicalTrial, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ClinicalTrialResourceApi - factory interface
 * @export
 */
export const ClinicalTrialResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ClinicalTrialResourceApiFp(configuration);
  return {
    /**
     *
     * @param {ClinicalTrialCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countClinicalTrials(criteria: ClinicalTrialCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countClinicalTrials(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ClinicalTrial} clinicalTrial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClinicalTrial(clinicalTrial: ClinicalTrial, options?: any): AxiosPromise<ClinicalTrial> {
      return localVarFp.createClinicalTrial(clinicalTrial, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClinicalTrial(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteClinicalTrial(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ClinicalTrialCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClinicalTrials(criteria: ClinicalTrialCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<ClinicalTrial>> {
      return localVarFp.getAllClinicalTrials(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClinicalTrial(id: number, options?: any): AxiosPromise<ClinicalTrial> {
      return localVarFp.getClinicalTrial(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrial} clinicalTrial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateClinicalTrial(id: number, clinicalTrial: ClinicalTrial, options?: any): AxiosPromise<ClinicalTrial> {
      return localVarFp.partialUpdateClinicalTrial(id, clinicalTrial, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchClinicalTrials(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<ClinicalTrial>> {
      return localVarFp.searchClinicalTrials(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {ClinicalTrial} clinicalTrial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClinicalTrial(id: number, clinicalTrial: ClinicalTrial, options?: any): AxiosPromise<ClinicalTrial> {
      return localVarFp.updateClinicalTrial(id, clinicalTrial, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ClinicalTrialResourceApi - object-oriented interface
 * @export
 * @class ClinicalTrialResourceApi
 * @extends {BaseAPI}
 */
export class ClinicalTrialResourceApi extends BaseAPI {
  /**
   *
   * @param {ClinicalTrialCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialResourceApi
   */
  public countClinicalTrials(criteria: ClinicalTrialCriteria, options?: AxiosRequestConfig) {
    return ClinicalTrialResourceApiFp(this.configuration)
      .countClinicalTrials(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ClinicalTrial} clinicalTrial
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialResourceApi
   */
  public createClinicalTrial(clinicalTrial: ClinicalTrial, options?: AxiosRequestConfig) {
    return ClinicalTrialResourceApiFp(this.configuration)
      .createClinicalTrial(clinicalTrial, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialResourceApi
   */
  public deleteClinicalTrial(id: number, options?: AxiosRequestConfig) {
    return ClinicalTrialResourceApiFp(this.configuration)
      .deleteClinicalTrial(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ClinicalTrialCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialResourceApi
   */
  public getAllClinicalTrials(criteria: ClinicalTrialCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return ClinicalTrialResourceApiFp(this.configuration)
      .getAllClinicalTrials(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialResourceApi
   */
  public getClinicalTrial(id: number, options?: AxiosRequestConfig) {
    return ClinicalTrialResourceApiFp(this.configuration)
      .getClinicalTrial(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {ClinicalTrial} clinicalTrial
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialResourceApi
   */
  public partialUpdateClinicalTrial(id: number, clinicalTrial: ClinicalTrial, options?: AxiosRequestConfig) {
    return ClinicalTrialResourceApiFp(this.configuration)
      .partialUpdateClinicalTrial(id, clinicalTrial, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialResourceApi
   */
  public searchClinicalTrials(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return ClinicalTrialResourceApiFp(this.configuration)
      .searchClinicalTrials(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {ClinicalTrial} clinicalTrial
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClinicalTrialResourceApi
   */
  public updateClinicalTrial(id: number, clinicalTrial: ClinicalTrial, options?: AxiosRequestConfig) {
    return ClinicalTrialResourceApiFp(this.configuration)
      .updateClinicalTrial(id, clinicalTrial, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CompanionDiagnosticDeviceResourceApi - axios parameter creator
 * @export
 */
export const CompanionDiagnosticDeviceResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CompanionDiagnosticDeviceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countCompanionDiagnosticDevices: async (
      criteria: CompanionDiagnosticDeviceCriteria,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countCompanionDiagnosticDevices', 'criteria', criteria);
      const localVarPath = `/api/companion-diagnostic-devices/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCompanionDiagnosticDevice: async (
      companionDiagnosticDevice: CompanionDiagnosticDevice,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companionDiagnosticDevice' is not null or undefined
      assertParamExists('createCompanionDiagnosticDevice', 'companionDiagnosticDevice', companionDiagnosticDevice);
      const localVarPath = `/api/companion-diagnostic-devices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(companionDiagnosticDevice, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCompanionDiagnosticDevice: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCompanionDiagnosticDevice', 'id', id);
      const localVarPath = `/api/companion-diagnostic-devices/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CompanionDiagnosticDeviceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCompanionDiagnosticDevices: async (
      criteria: CompanionDiagnosticDeviceCriteria,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllCompanionDiagnosticDevices', 'criteria', criteria);
      const localVarPath = `/api/companion-diagnostic-devices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanionDiagnosticDevice: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getCompanionDiagnosticDevice', 'id', id);
      const localVarPath = `/api/companion-diagnostic-devices/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateCompanionDiagnosticDevice: async (
      id: number,
      companionDiagnosticDevice: CompanionDiagnosticDevice,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateCompanionDiagnosticDevice', 'id', id);
      // verify required parameter 'companionDiagnosticDevice' is not null or undefined
      assertParamExists('partialUpdateCompanionDiagnosticDevice', 'companionDiagnosticDevice', companionDiagnosticDevice);
      const localVarPath = `/api/companion-diagnostic-devices/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(companionDiagnosticDevice, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCompanionDiagnosticDevices: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchCompanionDiagnosticDevices', 'query', query);
      const localVarPath = `/api/companion-diagnostic-devices/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCompanionDiagnosticDevice: async (
      id: number,
      companionDiagnosticDevice: CompanionDiagnosticDevice,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateCompanionDiagnosticDevice', 'id', id);
      // verify required parameter 'companionDiagnosticDevice' is not null or undefined
      assertParamExists('updateCompanionDiagnosticDevice', 'companionDiagnosticDevice', companionDiagnosticDevice);
      const localVarPath = `/api/companion-diagnostic-devices/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(companionDiagnosticDevice, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompanionDiagnosticDeviceResourceApi - functional programming interface
 * @export
 */
export const CompanionDiagnosticDeviceResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompanionDiagnosticDeviceResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CompanionDiagnosticDeviceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countCompanionDiagnosticDevices(
      criteria: CompanionDiagnosticDeviceCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countCompanionDiagnosticDevices(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCompanionDiagnosticDevice(
      companionDiagnosticDevice: CompanionDiagnosticDevice,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanionDiagnosticDevice>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanionDiagnosticDevice(companionDiagnosticDevice, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCompanionDiagnosticDevice(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanionDiagnosticDevice(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CompanionDiagnosticDeviceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCompanionDiagnosticDevices(
      criteria: CompanionDiagnosticDeviceCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanionDiagnosticDevice>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanionDiagnosticDevices(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanionDiagnosticDevice(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanionDiagnosticDevice>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanionDiagnosticDevice(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateCompanionDiagnosticDevice(
      id: number,
      companionDiagnosticDevice: CompanionDiagnosticDevice,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanionDiagnosticDevice>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCompanionDiagnosticDevice(
        id,
        companionDiagnosticDevice,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchCompanionDiagnosticDevices(
      query: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanionDiagnosticDevice>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchCompanionDiagnosticDevices(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCompanionDiagnosticDevice(
      id: number,
      companionDiagnosticDevice: CompanionDiagnosticDevice,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanionDiagnosticDevice>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanionDiagnosticDevice(id, companionDiagnosticDevice, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompanionDiagnosticDeviceResourceApi - factory interface
 * @export
 */
export const CompanionDiagnosticDeviceResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CompanionDiagnosticDeviceResourceApiFp(configuration);
  return {
    /**
     *
     * @param {CompanionDiagnosticDeviceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countCompanionDiagnosticDevices(criteria: CompanionDiagnosticDeviceCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countCompanionDiagnosticDevices(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCompanionDiagnosticDevice(
      companionDiagnosticDevice: CompanionDiagnosticDevice,
      options?: any,
    ): AxiosPromise<CompanionDiagnosticDevice> {
      return localVarFp.createCompanionDiagnosticDevice(companionDiagnosticDevice, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCompanionDiagnosticDevice(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCompanionDiagnosticDevice(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CompanionDiagnosticDeviceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCompanionDiagnosticDevices(
      criteria: CompanionDiagnosticDeviceCriteria,
      options?: any,
    ): AxiosPromise<Array<CompanionDiagnosticDevice>> {
      return localVarFp.getAllCompanionDiagnosticDevices(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanionDiagnosticDevice(id: number, options?: any): AxiosPromise<CompanionDiagnosticDevice> {
      return localVarFp.getCompanionDiagnosticDevice(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateCompanionDiagnosticDevice(
      id: number,
      companionDiagnosticDevice: CompanionDiagnosticDevice,
      options?: any,
    ): AxiosPromise<CompanionDiagnosticDevice> {
      return localVarFp
        .partialUpdateCompanionDiagnosticDevice(id, companionDiagnosticDevice, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCompanionDiagnosticDevices(query: string, options?: any): AxiosPromise<Array<CompanionDiagnosticDevice>> {
      return localVarFp.searchCompanionDiagnosticDevices(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCompanionDiagnosticDevice(
      id: number,
      companionDiagnosticDevice: CompanionDiagnosticDevice,
      options?: any,
    ): AxiosPromise<CompanionDiagnosticDevice> {
      return localVarFp.updateCompanionDiagnosticDevice(id, companionDiagnosticDevice, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CompanionDiagnosticDeviceResourceApi - object-oriented interface
 * @export
 * @class CompanionDiagnosticDeviceResourceApi
 * @extends {BaseAPI}
 */
export class CompanionDiagnosticDeviceResourceApi extends BaseAPI {
  /**
   *
   * @param {CompanionDiagnosticDeviceCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanionDiagnosticDeviceResourceApi
   */
  public countCompanionDiagnosticDevices(criteria: CompanionDiagnosticDeviceCriteria, options?: AxiosRequestConfig) {
    return CompanionDiagnosticDeviceResourceApiFp(this.configuration)
      .countCompanionDiagnosticDevices(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanionDiagnosticDeviceResourceApi
   */
  public createCompanionDiagnosticDevice(companionDiagnosticDevice: CompanionDiagnosticDevice, options?: AxiosRequestConfig) {
    return CompanionDiagnosticDeviceResourceApiFp(this.configuration)
      .createCompanionDiagnosticDevice(companionDiagnosticDevice, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanionDiagnosticDeviceResourceApi
   */
  public deleteCompanionDiagnosticDevice(id: number, options?: AxiosRequestConfig) {
    return CompanionDiagnosticDeviceResourceApiFp(this.configuration)
      .deleteCompanionDiagnosticDevice(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CompanionDiagnosticDeviceCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanionDiagnosticDeviceResourceApi
   */
  public getAllCompanionDiagnosticDevices(criteria: CompanionDiagnosticDeviceCriteria, options?: AxiosRequestConfig) {
    return CompanionDiagnosticDeviceResourceApiFp(this.configuration)
      .getAllCompanionDiagnosticDevices(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanionDiagnosticDeviceResourceApi
   */
  public getCompanionDiagnosticDevice(id: number, options?: AxiosRequestConfig) {
    return CompanionDiagnosticDeviceResourceApiFp(this.configuration)
      .getCompanionDiagnosticDevice(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanionDiagnosticDeviceResourceApi
   */
  public partialUpdateCompanionDiagnosticDevice(
    id: number,
    companionDiagnosticDevice: CompanionDiagnosticDevice,
    options?: AxiosRequestConfig,
  ) {
    return CompanionDiagnosticDeviceResourceApiFp(this.configuration)
      .partialUpdateCompanionDiagnosticDevice(id, companionDiagnosticDevice, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanionDiagnosticDeviceResourceApi
   */
  public searchCompanionDiagnosticDevices(query: string, options?: AxiosRequestConfig) {
    return CompanionDiagnosticDeviceResourceApiFp(this.configuration)
      .searchCompanionDiagnosticDevices(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CompanionDiagnosticDevice} companionDiagnosticDevice
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanionDiagnosticDeviceResourceApi
   */
  public updateCompanionDiagnosticDevice(id: number, companionDiagnosticDevice: CompanionDiagnosticDevice, options?: AxiosRequestConfig) {
    return CompanionDiagnosticDeviceResourceApiFp(this.configuration)
      .updateCompanionDiagnosticDevice(id, companionDiagnosticDevice, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ConsequenceResourceApi - axios parameter creator
 * @export
 */
export const ConsequenceResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ConsequenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countConsequences: async (criteria: ConsequenceCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countConsequences', 'criteria', criteria);
      const localVarPath = `/api/consequences/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Consequence} consequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createConsequence: async (consequence: Consequence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'consequence' is not null or undefined
      assertParamExists('createConsequence', 'consequence', consequence);
      const localVarPath = `/api/consequences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(consequence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteConsequence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteConsequence', 'id', id);
      const localVarPath = `/api/consequences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConsequenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllConsequences: async (criteria: ConsequenceCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllConsequences', 'criteria', criteria);
      const localVarPath = `/api/consequences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsequence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getConsequence', 'id', id);
      const localVarPath = `/api/consequences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Consequence} consequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateConsequence: async (id: number, consequence: Consequence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateConsequence', 'id', id);
      // verify required parameter 'consequence' is not null or undefined
      assertParamExists('partialUpdateConsequence', 'consequence', consequence);
      const localVarPath = `/api/consequences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(consequence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Consequence} consequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConsequence: async (id: number, consequence: Consequence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateConsequence', 'id', id);
      // verify required parameter 'consequence' is not null or undefined
      assertParamExists('updateConsequence', 'consequence', consequence);
      const localVarPath = `/api/consequences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(consequence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ConsequenceResourceApi - functional programming interface
 * @export
 */
export const ConsequenceResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ConsequenceResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ConsequenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countConsequences(
      criteria: ConsequenceCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countConsequences(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Consequence} consequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createConsequence(
      consequence: Consequence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consequence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createConsequence(consequence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteConsequence(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConsequence(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ConsequenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllConsequences(
      criteria: ConsequenceCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Consequence>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConsequences(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConsequence(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consequence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConsequence(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Consequence} consequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateConsequence(
      id: number,
      consequence: Consequence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consequence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateConsequence(id, consequence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Consequence} consequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateConsequence(
      id: number,
      consequence: Consequence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consequence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateConsequence(id, consequence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ConsequenceResourceApi - factory interface
 * @export
 */
export const ConsequenceResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ConsequenceResourceApiFp(configuration);
  return {
    /**
     *
     * @param {ConsequenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countConsequences(criteria: ConsequenceCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countConsequences(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Consequence} consequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createConsequence(consequence: Consequence, options?: any): AxiosPromise<Consequence> {
      return localVarFp.createConsequence(consequence, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteConsequence(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteConsequence(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ConsequenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllConsequences(criteria: ConsequenceCriteria, options?: any): AxiosPromise<Array<Consequence>> {
      return localVarFp.getAllConsequences(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsequence(id: number, options?: any): AxiosPromise<Consequence> {
      return localVarFp.getConsequence(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Consequence} consequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateConsequence(id: number, consequence: Consequence, options?: any): AxiosPromise<Consequence> {
      return localVarFp.partialUpdateConsequence(id, consequence, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Consequence} consequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConsequence(id: number, consequence: Consequence, options?: any): AxiosPromise<Consequence> {
      return localVarFp.updateConsequence(id, consequence, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ConsequenceResourceApi - object-oriented interface
 * @export
 * @class ConsequenceResourceApi
 * @extends {BaseAPI}
 */
export class ConsequenceResourceApi extends BaseAPI {
  /**
   *
   * @param {ConsequenceCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsequenceResourceApi
   */
  public countConsequences(criteria: ConsequenceCriteria, options?: AxiosRequestConfig) {
    return ConsequenceResourceApiFp(this.configuration)
      .countConsequences(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Consequence} consequence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsequenceResourceApi
   */
  public createConsequence(consequence: Consequence, options?: AxiosRequestConfig) {
    return ConsequenceResourceApiFp(this.configuration)
      .createConsequence(consequence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsequenceResourceApi
   */
  public deleteConsequence(id: number, options?: AxiosRequestConfig) {
    return ConsequenceResourceApiFp(this.configuration)
      .deleteConsequence(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ConsequenceCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsequenceResourceApi
   */
  public getAllConsequences(criteria: ConsequenceCriteria, options?: AxiosRequestConfig) {
    return ConsequenceResourceApiFp(this.configuration)
      .getAllConsequences(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsequenceResourceApi
   */
  public getConsequence(id: number, options?: AxiosRequestConfig) {
    return ConsequenceResourceApiFp(this.configuration)
      .getConsequence(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Consequence} consequence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsequenceResourceApi
   */
  public partialUpdateConsequence(id: number, consequence: Consequence, options?: AxiosRequestConfig) {
    return ConsequenceResourceApiFp(this.configuration)
      .partialUpdateConsequence(id, consequence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Consequence} consequence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsequenceResourceApi
   */
  public updateConsequence(id: number, consequence: Consequence, options?: AxiosRequestConfig) {
    return ConsequenceResourceApiFp(this.configuration)
      .updateConsequence(id, consequence, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DrugResourceApi - axios parameter creator
 * @export
 */
export const DrugResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {DrugCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countDrugs: async (criteria: DrugCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countDrugs', 'criteria', criteria);
      const localVarPath = `/api/drugs/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Drug} drug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDrug: async (drug: Drug, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'drug' is not null or undefined
      assertParamExists('createDrug', 'drug', drug);
      const localVarPath = `/api/drugs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(drug, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDrug: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteDrug', 'id', id);
      const localVarPath = `/api/drugs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DrugCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDrugs: async (criteria: DrugCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllDrugs', 'criteria', criteria);
      const localVarPath = `/api/drugs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDrug: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getDrug', 'id', id);
      const localVarPath = `/api/drugs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Drug} drug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateDrug: async (id: number, drug: Drug, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateDrug', 'id', id);
      // verify required parameter 'drug' is not null or undefined
      assertParamExists('partialUpdateDrug', 'drug', drug);
      const localVarPath = `/api/drugs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(drug, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDrugs: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchDrugs', 'query', query);
      const localVarPath = `/api/drugs/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Drug} drug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDrug: async (id: number, drug: Drug, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateDrug', 'id', id);
      // verify required parameter 'drug' is not null or undefined
      assertParamExists('updateDrug', 'drug', drug);
      const localVarPath = `/api/drugs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(drug, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DrugResourceApi - functional programming interface
 * @export
 */
export const DrugResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DrugResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {DrugCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countDrugs(
      criteria: DrugCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countDrugs(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Drug} drug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDrug(drug: Drug, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Drug>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDrug(drug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDrug(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDrug(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DrugCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllDrugs(
      criteria: DrugCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Drug>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDrugs(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDrug(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Drug>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDrug(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Drug} drug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateDrug(
      id: number,
      drug: Drug,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Drug>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateDrug(id, drug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchDrugs(
      query: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Drug>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchDrugs(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Drug} drug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDrug(
      id: number,
      drug: Drug,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Drug>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDrug(id, drug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DrugResourceApi - factory interface
 * @export
 */
export const DrugResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DrugResourceApiFp(configuration);
  return {
    /**
     *
     * @param {DrugCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countDrugs(criteria: DrugCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countDrugs(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Drug} drug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDrug(drug: Drug, options?: any): AxiosPromise<Drug> {
      return localVarFp.createDrug(drug, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDrug(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteDrug(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {DrugCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDrugs(criteria: DrugCriteria, options?: any): AxiosPromise<Array<Drug>> {
      return localVarFp.getAllDrugs(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDrug(id: number, options?: any): AxiosPromise<Drug> {
      return localVarFp.getDrug(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Drug} drug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateDrug(id: number, drug: Drug, options?: any): AxiosPromise<Drug> {
      return localVarFp.partialUpdateDrug(id, drug, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDrugs(query: string, options?: any): AxiosPromise<Array<Drug>> {
      return localVarFp.searchDrugs(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Drug} drug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDrug(id: number, drug: Drug, options?: any): AxiosPromise<Drug> {
      return localVarFp.updateDrug(id, drug, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DrugResourceApi - object-oriented interface
 * @export
 * @class DrugResourceApi
 * @extends {BaseAPI}
 */
export class DrugResourceApi extends BaseAPI {
  /**
   *
   * @param {DrugCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugResourceApi
   */
  public countDrugs(criteria: DrugCriteria, options?: AxiosRequestConfig) {
    return DrugResourceApiFp(this.configuration)
      .countDrugs(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Drug} drug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugResourceApi
   */
  public createDrug(drug: Drug, options?: AxiosRequestConfig) {
    return DrugResourceApiFp(this.configuration)
      .createDrug(drug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugResourceApi
   */
  public deleteDrug(id: number, options?: AxiosRequestConfig) {
    return DrugResourceApiFp(this.configuration)
      .deleteDrug(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DrugCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugResourceApi
   */
  public getAllDrugs(criteria: DrugCriteria, options?: AxiosRequestConfig) {
    return DrugResourceApiFp(this.configuration)
      .getAllDrugs(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugResourceApi
   */
  public getDrug(id: number, options?: AxiosRequestConfig) {
    return DrugResourceApiFp(this.configuration)
      .getDrug(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Drug} drug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugResourceApi
   */
  public partialUpdateDrug(id: number, drug: Drug, options?: AxiosRequestConfig) {
    return DrugResourceApiFp(this.configuration)
      .partialUpdateDrug(id, drug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugResourceApi
   */
  public searchDrugs(query: string, options?: AxiosRequestConfig) {
    return DrugResourceApiFp(this.configuration)
      .searchDrugs(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Drug} drug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DrugResourceApi
   */
  public updateDrug(id: number, drug: Drug, options?: AxiosRequestConfig) {
    return DrugResourceApiFp(this.configuration)
      .updateDrug(id, drug, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EligibilityCriteriaResourceApi - axios parameter creator
 * @export
 */
export const EligibilityCriteriaResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {EligibilityCriteriaCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countEligibilityCriteria: async (criteria: EligibilityCriteriaCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countEligibilityCriteria', 'criteria', criteria);
      const localVarPath = `/api/eligibility-criteria/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EligibilityCriteria} eligibilityCriteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEligibilityCriteria: async (eligibilityCriteria: EligibilityCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'eligibilityCriteria' is not null or undefined
      assertParamExists('createEligibilityCriteria', 'eligibilityCriteria', eligibilityCriteria);
      const localVarPath = `/api/eligibility-criteria`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(eligibilityCriteria, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEligibilityCriteria: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteEligibilityCriteria', 'id', id);
      const localVarPath = `/api/eligibility-criteria/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EligibilityCriteriaCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEligibilityCriteria: async (
      criteria: EligibilityCriteriaCriteria,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllEligibilityCriteria', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllEligibilityCriteria', 'pageable', pageable);
      const localVarPath = `/api/eligibility-criteria`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEligibilityCriteria: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getEligibilityCriteria', 'id', id);
      const localVarPath = `/api/eligibility-criteria/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {EligibilityCriteria} eligibilityCriteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateEligibilityCriteria: async (
      id: number,
      eligibilityCriteria: EligibilityCriteria,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateEligibilityCriteria', 'id', id);
      // verify required parameter 'eligibilityCriteria' is not null or undefined
      assertParamExists('partialUpdateEligibilityCriteria', 'eligibilityCriteria', eligibilityCriteria);
      const localVarPath = `/api/eligibility-criteria/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(eligibilityCriteria, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchEligibilityCriteria: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchEligibilityCriteria', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchEligibilityCriteria', 'pageable', pageable);
      const localVarPath = `/api/eligibility-criteria/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {EligibilityCriteria} eligibilityCriteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEligibilityCriteria: async (
      id: number,
      eligibilityCriteria: EligibilityCriteria,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateEligibilityCriteria', 'id', id);
      // verify required parameter 'eligibilityCriteria' is not null or undefined
      assertParamExists('updateEligibilityCriteria', 'eligibilityCriteria', eligibilityCriteria);
      const localVarPath = `/api/eligibility-criteria/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(eligibilityCriteria, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EligibilityCriteriaResourceApi - functional programming interface
 * @export
 */
export const EligibilityCriteriaResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EligibilityCriteriaResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {EligibilityCriteriaCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countEligibilityCriteria(
      criteria: EligibilityCriteriaCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countEligibilityCriteria(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EligibilityCriteria} eligibilityCriteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEligibilityCriteria(
      eligibilityCriteria: EligibilityCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EligibilityCriteria>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEligibilityCriteria(eligibilityCriteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEligibilityCriteria(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEligibilityCriteria(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EligibilityCriteriaCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllEligibilityCriteria(
      criteria: EligibilityCriteriaCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EligibilityCriteria>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEligibilityCriteria(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEligibilityCriteria(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EligibilityCriteria>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEligibilityCriteria(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {EligibilityCriteria} eligibilityCriteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateEligibilityCriteria(
      id: number,
      eligibilityCriteria: EligibilityCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EligibilityCriteria>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateEligibilityCriteria(id, eligibilityCriteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchEligibilityCriteria(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EligibilityCriteria>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchEligibilityCriteria(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {EligibilityCriteria} eligibilityCriteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEligibilityCriteria(
      id: number,
      eligibilityCriteria: EligibilityCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EligibilityCriteria>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEligibilityCriteria(id, eligibilityCriteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EligibilityCriteriaResourceApi - factory interface
 * @export
 */
export const EligibilityCriteriaResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EligibilityCriteriaResourceApiFp(configuration);
  return {
    /**
     *
     * @param {EligibilityCriteriaCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countEligibilityCriteria(criteria: EligibilityCriteriaCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countEligibilityCriteria(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {EligibilityCriteria} eligibilityCriteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEligibilityCriteria(eligibilityCriteria: EligibilityCriteria, options?: any): AxiosPromise<EligibilityCriteria> {
      return localVarFp.createEligibilityCriteria(eligibilityCriteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEligibilityCriteria(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteEligibilityCriteria(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {EligibilityCriteriaCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEligibilityCriteria(
      criteria: EligibilityCriteriaCriteria,
      pageable: Pageable,
      options?: any,
    ): AxiosPromise<Array<EligibilityCriteria>> {
      return localVarFp.getAllEligibilityCriteria(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEligibilityCriteria(id: number, options?: any): AxiosPromise<EligibilityCriteria> {
      return localVarFp.getEligibilityCriteria(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {EligibilityCriteria} eligibilityCriteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateEligibilityCriteria(
      id: number,
      eligibilityCriteria: EligibilityCriteria,
      options?: any,
    ): AxiosPromise<EligibilityCriteria> {
      return localVarFp.partialUpdateEligibilityCriteria(id, eligibilityCriteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchEligibilityCriteria(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<EligibilityCriteria>> {
      return localVarFp.searchEligibilityCriteria(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {EligibilityCriteria} eligibilityCriteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEligibilityCriteria(id: number, eligibilityCriteria: EligibilityCriteria, options?: any): AxiosPromise<EligibilityCriteria> {
      return localVarFp.updateEligibilityCriteria(id, eligibilityCriteria, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * EligibilityCriteriaResourceApi - object-oriented interface
 * @export
 * @class EligibilityCriteriaResourceApi
 * @extends {BaseAPI}
 */
export class EligibilityCriteriaResourceApi extends BaseAPI {
  /**
   *
   * @param {EligibilityCriteriaCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EligibilityCriteriaResourceApi
   */
  public countEligibilityCriteria(criteria: EligibilityCriteriaCriteria, options?: AxiosRequestConfig) {
    return EligibilityCriteriaResourceApiFp(this.configuration)
      .countEligibilityCriteria(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EligibilityCriteria} eligibilityCriteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EligibilityCriteriaResourceApi
   */
  public createEligibilityCriteria(eligibilityCriteria: EligibilityCriteria, options?: AxiosRequestConfig) {
    return EligibilityCriteriaResourceApiFp(this.configuration)
      .createEligibilityCriteria(eligibilityCriteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EligibilityCriteriaResourceApi
   */
  public deleteEligibilityCriteria(id: number, options?: AxiosRequestConfig) {
    return EligibilityCriteriaResourceApiFp(this.configuration)
      .deleteEligibilityCriteria(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EligibilityCriteriaCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EligibilityCriteriaResourceApi
   */
  public getAllEligibilityCriteria(criteria: EligibilityCriteriaCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return EligibilityCriteriaResourceApiFp(this.configuration)
      .getAllEligibilityCriteria(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EligibilityCriteriaResourceApi
   */
  public getEligibilityCriteria(id: number, options?: AxiosRequestConfig) {
    return EligibilityCriteriaResourceApiFp(this.configuration)
      .getEligibilityCriteria(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {EligibilityCriteria} eligibilityCriteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EligibilityCriteriaResourceApi
   */
  public partialUpdateEligibilityCriteria(id: number, eligibilityCriteria: EligibilityCriteria, options?: AxiosRequestConfig) {
    return EligibilityCriteriaResourceApiFp(this.configuration)
      .partialUpdateEligibilityCriteria(id, eligibilityCriteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EligibilityCriteriaResourceApi
   */
  public searchEligibilityCriteria(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return EligibilityCriteriaResourceApiFp(this.configuration)
      .searchEligibilityCriteria(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {EligibilityCriteria} eligibilityCriteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EligibilityCriteriaResourceApi
   */
  public updateEligibilityCriteria(id: number, eligibilityCriteria: EligibilityCriteria, options?: AxiosRequestConfig) {
    return EligibilityCriteriaResourceApiFp(this.configuration)
      .updateEligibilityCriteria(id, eligibilityCriteria, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EnsemblGeneControllerApi - axios parameter creator
 * @export
 */
export const EnsemblGeneControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AddEnsemblGeneBody} addEnsemblGeneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEnsemblGene: async (addEnsemblGeneBody: AddEnsemblGeneBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'addEnsemblGeneBody' is not null or undefined
      assertParamExists('addEnsemblGene', 'addEnsemblGeneBody', addEnsemblGeneBody);
      const localVarPath = `/api/add-ensembl-gene`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(addEnsemblGeneBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EnsemblGeneControllerApi - functional programming interface
 * @export
 */
export const EnsemblGeneControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EnsemblGeneControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AddEnsemblGeneBody} addEnsemblGeneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addEnsemblGene(
      addEnsemblGeneBody: AddEnsemblGeneBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnsemblGene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addEnsemblGene(addEnsemblGeneBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EnsemblGeneControllerApi - factory interface
 * @export
 */
export const EnsemblGeneControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EnsemblGeneControllerApiFp(configuration);
  return {
    /**
     *
     * @param {AddEnsemblGeneBody} addEnsemblGeneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEnsemblGene(addEnsemblGeneBody: AddEnsemblGeneBody, options?: any): AxiosPromise<EnsemblGene> {
      return localVarFp.addEnsemblGene(addEnsemblGeneBody, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * EnsemblGeneControllerApi - object-oriented interface
 * @export
 * @class EnsemblGeneControllerApi
 * @extends {BaseAPI}
 */
export class EnsemblGeneControllerApi extends BaseAPI {
  /**
   *
   * @param {AddEnsemblGeneBody} addEnsemblGeneBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnsemblGeneControllerApi
   */
  public addEnsemblGene(addEnsemblGeneBody: AddEnsemblGeneBody, options?: AxiosRequestConfig) {
    return EnsemblGeneControllerApiFp(this.configuration)
      .addEnsemblGene(addEnsemblGeneBody, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EnsemblGeneResourceApi - axios parameter creator
 * @export
 */
export const EnsemblGeneResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {EnsemblGeneCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countEnsemblGenes: async (criteria: EnsemblGeneCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countEnsemblGenes', 'criteria', criteria);
      const localVarPath = `/api/ensembl-genes/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EnsemblGene} ensemblGene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEnsemblGene: async (ensemblGene: EnsemblGene, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'ensemblGene' is not null or undefined
      assertParamExists('createEnsemblGene', 'ensemblGene', ensemblGene);
      const localVarPath = `/api/ensembl-genes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(ensemblGene, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEnsemblGene: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteEnsemblGene', 'id', id);
      const localVarPath = `/api/ensembl-genes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EnsemblGeneCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEnsemblGenes: async (
      criteria: EnsemblGeneCriteria,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllEnsemblGenes', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllEnsemblGenes', 'pageable', pageable);
      const localVarPath = `/api/ensembl-genes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnsemblGene: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getEnsemblGene', 'id', id);
      const localVarPath = `/api/ensembl-genes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {EnsemblGene} ensemblGene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateEnsemblGene: async (id: number, ensemblGene: EnsemblGene, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateEnsemblGene', 'id', id);
      // verify required parameter 'ensemblGene' is not null or undefined
      assertParamExists('partialUpdateEnsemblGene', 'ensemblGene', ensemblGene);
      const localVarPath = `/api/ensembl-genes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(ensemblGene, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchEnsemblGenes: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchEnsemblGenes', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchEnsemblGenes', 'pageable', pageable);
      const localVarPath = `/api/ensembl-genes/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {EnsemblGene} ensemblGene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnsemblGene: async (id: number, ensemblGene: EnsemblGene, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateEnsemblGene', 'id', id);
      // verify required parameter 'ensemblGene' is not null or undefined
      assertParamExists('updateEnsemblGene', 'ensemblGene', ensemblGene);
      const localVarPath = `/api/ensembl-genes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(ensemblGene, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EnsemblGeneResourceApi - functional programming interface
 * @export
 */
export const EnsemblGeneResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EnsemblGeneResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {EnsemblGeneCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countEnsemblGenes(
      criteria: EnsemblGeneCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countEnsemblGenes(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EnsemblGene} ensemblGene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEnsemblGene(
      ensemblGene: EnsemblGene,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnsemblGene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEnsemblGene(ensemblGene, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEnsemblGene(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEnsemblGene(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EnsemblGeneCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllEnsemblGenes(
      criteria: EnsemblGeneCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnsemblGene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEnsemblGenes(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEnsemblGene(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnsemblGene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEnsemblGene(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {EnsemblGene} ensemblGene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateEnsemblGene(
      id: number,
      ensemblGene: EnsemblGene,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnsemblGene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateEnsemblGene(id, ensemblGene, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchEnsemblGenes(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnsemblGene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchEnsemblGenes(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {EnsemblGene} ensemblGene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEnsemblGene(
      id: number,
      ensemblGene: EnsemblGene,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnsemblGene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnsemblGene(id, ensemblGene, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EnsemblGeneResourceApi - factory interface
 * @export
 */
export const EnsemblGeneResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EnsemblGeneResourceApiFp(configuration);
  return {
    /**
     *
     * @param {EnsemblGeneCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countEnsemblGenes(criteria: EnsemblGeneCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countEnsemblGenes(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {EnsemblGene} ensemblGene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEnsemblGene(ensemblGene: EnsemblGene, options?: any): AxiosPromise<EnsemblGene> {
      return localVarFp.createEnsemblGene(ensemblGene, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEnsemblGene(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteEnsemblGene(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {EnsemblGeneCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEnsemblGenes(criteria: EnsemblGeneCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<EnsemblGene>> {
      return localVarFp.getAllEnsemblGenes(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnsemblGene(id: number, options?: any): AxiosPromise<EnsemblGene> {
      return localVarFp.getEnsemblGene(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {EnsemblGene} ensemblGene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateEnsemblGene(id: number, ensemblGene: EnsemblGene, options?: any): AxiosPromise<EnsemblGene> {
      return localVarFp.partialUpdateEnsemblGene(id, ensemblGene, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchEnsemblGenes(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<EnsemblGene>> {
      return localVarFp.searchEnsemblGenes(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {EnsemblGene} ensemblGene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnsemblGene(id: number, ensemblGene: EnsemblGene, options?: any): AxiosPromise<EnsemblGene> {
      return localVarFp.updateEnsemblGene(id, ensemblGene, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * EnsemblGeneResourceApi - object-oriented interface
 * @export
 * @class EnsemblGeneResourceApi
 * @extends {BaseAPI}
 */
export class EnsemblGeneResourceApi extends BaseAPI {
  /**
   *
   * @param {EnsemblGeneCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnsemblGeneResourceApi
   */
  public countEnsemblGenes(criteria: EnsemblGeneCriteria, options?: AxiosRequestConfig) {
    return EnsemblGeneResourceApiFp(this.configuration)
      .countEnsemblGenes(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EnsemblGene} ensemblGene
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnsemblGeneResourceApi
   */
  public createEnsemblGene(ensemblGene: EnsemblGene, options?: AxiosRequestConfig) {
    return EnsemblGeneResourceApiFp(this.configuration)
      .createEnsemblGene(ensemblGene, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnsemblGeneResourceApi
   */
  public deleteEnsemblGene(id: number, options?: AxiosRequestConfig) {
    return EnsemblGeneResourceApiFp(this.configuration)
      .deleteEnsemblGene(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EnsemblGeneCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnsemblGeneResourceApi
   */
  public getAllEnsemblGenes(criteria: EnsemblGeneCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return EnsemblGeneResourceApiFp(this.configuration)
      .getAllEnsemblGenes(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnsemblGeneResourceApi
   */
  public getEnsemblGene(id: number, options?: AxiosRequestConfig) {
    return EnsemblGeneResourceApiFp(this.configuration)
      .getEnsemblGene(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {EnsemblGene} ensemblGene
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnsemblGeneResourceApi
   */
  public partialUpdateEnsemblGene(id: number, ensemblGene: EnsemblGene, options?: AxiosRequestConfig) {
    return EnsemblGeneResourceApiFp(this.configuration)
      .partialUpdateEnsemblGene(id, ensemblGene, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnsemblGeneResourceApi
   */
  public searchEnsemblGenes(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return EnsemblGeneResourceApiFp(this.configuration)
      .searchEnsemblGenes(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {EnsemblGene} ensemblGene
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnsemblGeneResourceApi
   */
  public updateEnsemblGene(id: number, ensemblGene: EnsemblGene, options?: AxiosRequestConfig) {
    return EnsemblGeneResourceApiFp(this.configuration)
      .updateEnsemblGene(id, ensemblGene, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EvidenceResourceApi - axios parameter creator
 * @export
 */
export const EvidenceResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {EvidenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countEvidences: async (criteria: EvidenceCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countEvidences', 'criteria', criteria);
      const localVarPath = `/api/evidences/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Evidence} evidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEvidence: async (evidence: Evidence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'evidence' is not null or undefined
      assertParamExists('createEvidence', 'evidence', evidence);
      const localVarPath = `/api/evidences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(evidence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEvidence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteEvidence', 'id', id);
      const localVarPath = `/api/evidences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EvidenceCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEvidences: async (criteria: EvidenceCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllEvidences', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllEvidences', 'pageable', pageable);
      const localVarPath = `/api/evidences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvidence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getEvidence', 'id', id);
      const localVarPath = `/api/evidences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Evidence} evidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateEvidence: async (id: number, evidence: Evidence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateEvidence', 'id', id);
      // verify required parameter 'evidence' is not null or undefined
      assertParamExists('partialUpdateEvidence', 'evidence', evidence);
      const localVarPath = `/api/evidences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(evidence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchEvidences: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchEvidences', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchEvidences', 'pageable', pageable);
      const localVarPath = `/api/evidences/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Evidence} evidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEvidence: async (id: number, evidence: Evidence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateEvidence', 'id', id);
      // verify required parameter 'evidence' is not null or undefined
      assertParamExists('updateEvidence', 'evidence', evidence);
      const localVarPath = `/api/evidences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(evidence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EvidenceResourceApi - functional programming interface
 * @export
 */
export const EvidenceResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EvidenceResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {EvidenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countEvidences(
      criteria: EvidenceCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countEvidences(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Evidence} evidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEvidence(
      evidence: Evidence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEvidence(evidence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEvidence(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvidence(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EvidenceCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllEvidences(
      criteria: EvidenceCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Evidence>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEvidences(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEvidence(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidence(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Evidence} evidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateEvidence(
      id: number,
      evidence: Evidence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateEvidence(id, evidence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchEvidences(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Evidence>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchEvidences(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Evidence} evidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEvidence(
      id: number,
      evidence: Evidence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvidence(id, evidence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EvidenceResourceApi - factory interface
 * @export
 */
export const EvidenceResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EvidenceResourceApiFp(configuration);
  return {
    /**
     *
     * @param {EvidenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countEvidences(criteria: EvidenceCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countEvidences(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Evidence} evidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEvidence(evidence: Evidence, options?: any): AxiosPromise<Evidence> {
      return localVarFp.createEvidence(evidence, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEvidence(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteEvidence(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {EvidenceCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEvidences(criteria: EvidenceCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<Evidence>> {
      return localVarFp.getAllEvidences(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvidence(id: number, options?: any): AxiosPromise<Evidence> {
      return localVarFp.getEvidence(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Evidence} evidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateEvidence(id: number, evidence: Evidence, options?: any): AxiosPromise<Evidence> {
      return localVarFp.partialUpdateEvidence(id, evidence, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchEvidences(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<Evidence>> {
      return localVarFp.searchEvidences(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Evidence} evidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEvidence(id: number, evidence: Evidence, options?: any): AxiosPromise<Evidence> {
      return localVarFp.updateEvidence(id, evidence, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * EvidenceResourceApi - object-oriented interface
 * @export
 * @class EvidenceResourceApi
 * @extends {BaseAPI}
 */
export class EvidenceResourceApi extends BaseAPI {
  /**
   *
   * @param {EvidenceCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidenceResourceApi
   */
  public countEvidences(criteria: EvidenceCriteria, options?: AxiosRequestConfig) {
    return EvidenceResourceApiFp(this.configuration)
      .countEvidences(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Evidence} evidence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidenceResourceApi
   */
  public createEvidence(evidence: Evidence, options?: AxiosRequestConfig) {
    return EvidenceResourceApiFp(this.configuration)
      .createEvidence(evidence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidenceResourceApi
   */
  public deleteEvidence(id: number, options?: AxiosRequestConfig) {
    return EvidenceResourceApiFp(this.configuration)
      .deleteEvidence(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EvidenceCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidenceResourceApi
   */
  public getAllEvidences(criteria: EvidenceCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return EvidenceResourceApiFp(this.configuration)
      .getAllEvidences(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidenceResourceApi
   */
  public getEvidence(id: number, options?: AxiosRequestConfig) {
    return EvidenceResourceApiFp(this.configuration)
      .getEvidence(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Evidence} evidence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidenceResourceApi
   */
  public partialUpdateEvidence(id: number, evidence: Evidence, options?: AxiosRequestConfig) {
    return EvidenceResourceApiFp(this.configuration)
      .partialUpdateEvidence(id, evidence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidenceResourceApi
   */
  public searchEvidences(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return EvidenceResourceApiFp(this.configuration)
      .searchEvidences(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Evidence} evidence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvidenceResourceApi
   */
  public updateEvidence(id: number, evidence: Evidence, options?: AxiosRequestConfig) {
    return EvidenceResourceApiFp(this.configuration)
      .updateEvidence(id, evidence, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FdaDrugResourceApi - axios parameter creator
 * @export
 */
export const FdaDrugResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {FdaDrugCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countFdaDrugs: async (criteria: FdaDrugCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countFdaDrugs', 'criteria', criteria);
      const localVarPath = `/api/fda-drugs/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FdaDrug} fdaDrug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFdaDrug: async (fdaDrug: FdaDrug, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'fdaDrug' is not null or undefined
      assertParamExists('createFdaDrug', 'fdaDrug', fdaDrug);
      const localVarPath = `/api/fda-drugs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fdaDrug, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFdaDrug: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteFdaDrug', 'id', id);
      const localVarPath = `/api/fda-drugs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FdaDrugCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFdaDrugs: async (criteria: FdaDrugCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllFdaDrugs', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllFdaDrugs', 'pageable', pageable);
      const localVarPath = `/api/fda-drugs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFdaDrug: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getFdaDrug', 'id', id);
      const localVarPath = `/api/fda-drugs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {FdaDrug} fdaDrug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateFdaDrug: async (id: number, fdaDrug: FdaDrug, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateFdaDrug', 'id', id);
      // verify required parameter 'fdaDrug' is not null or undefined
      assertParamExists('partialUpdateFdaDrug', 'fdaDrug', fdaDrug);
      const localVarPath = `/api/fda-drugs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fdaDrug, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchFdaDrugs: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchFdaDrugs', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchFdaDrugs', 'pageable', pageable);
      const localVarPath = `/api/fda-drugs/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {FdaDrug} fdaDrug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFdaDrug: async (id: number, fdaDrug: FdaDrug, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateFdaDrug', 'id', id);
      // verify required parameter 'fdaDrug' is not null or undefined
      assertParamExists('updateFdaDrug', 'fdaDrug', fdaDrug);
      const localVarPath = `/api/fda-drugs/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fdaDrug, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FdaDrugResourceApi - functional programming interface
 * @export
 */
export const FdaDrugResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FdaDrugResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {FdaDrugCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countFdaDrugs(
      criteria: FdaDrugCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countFdaDrugs(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FdaDrug} fdaDrug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFdaDrug(
      fdaDrug: FdaDrug,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaDrug>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFdaDrug(fdaDrug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFdaDrug(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFdaDrug(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FdaDrugCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllFdaDrugs(
      criteria: FdaDrugCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FdaDrug>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFdaDrugs(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFdaDrug(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaDrug>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFdaDrug(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {FdaDrug} fdaDrug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateFdaDrug(
      id: number,
      fdaDrug: FdaDrug,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaDrug>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateFdaDrug(id, fdaDrug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchFdaDrugs(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FdaDrug>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchFdaDrugs(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {FdaDrug} fdaDrug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFdaDrug(
      id: number,
      fdaDrug: FdaDrug,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaDrug>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFdaDrug(id, fdaDrug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FdaDrugResourceApi - factory interface
 * @export
 */
export const FdaDrugResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FdaDrugResourceApiFp(configuration);
  return {
    /**
     *
     * @param {FdaDrugCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countFdaDrugs(criteria: FdaDrugCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countFdaDrugs(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {FdaDrug} fdaDrug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFdaDrug(fdaDrug: FdaDrug, options?: any): AxiosPromise<FdaDrug> {
      return localVarFp.createFdaDrug(fdaDrug, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFdaDrug(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteFdaDrug(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {FdaDrugCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFdaDrugs(criteria: FdaDrugCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<FdaDrug>> {
      return localVarFp.getAllFdaDrugs(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFdaDrug(id: number, options?: any): AxiosPromise<FdaDrug> {
      return localVarFp.getFdaDrug(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {FdaDrug} fdaDrug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateFdaDrug(id: number, fdaDrug: FdaDrug, options?: any): AxiosPromise<FdaDrug> {
      return localVarFp.partialUpdateFdaDrug(id, fdaDrug, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchFdaDrugs(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<FdaDrug>> {
      return localVarFp.searchFdaDrugs(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {FdaDrug} fdaDrug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFdaDrug(id: number, fdaDrug: FdaDrug, options?: any): AxiosPromise<FdaDrug> {
      return localVarFp.updateFdaDrug(id, fdaDrug, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * FdaDrugResourceApi - object-oriented interface
 * @export
 * @class FdaDrugResourceApi
 * @extends {BaseAPI}
 */
export class FdaDrugResourceApi extends BaseAPI {
  /**
   *
   * @param {FdaDrugCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaDrugResourceApi
   */
  public countFdaDrugs(criteria: FdaDrugCriteria, options?: AxiosRequestConfig) {
    return FdaDrugResourceApiFp(this.configuration)
      .countFdaDrugs(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FdaDrug} fdaDrug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaDrugResourceApi
   */
  public createFdaDrug(fdaDrug: FdaDrug, options?: AxiosRequestConfig) {
    return FdaDrugResourceApiFp(this.configuration)
      .createFdaDrug(fdaDrug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaDrugResourceApi
   */
  public deleteFdaDrug(id: number, options?: AxiosRequestConfig) {
    return FdaDrugResourceApiFp(this.configuration)
      .deleteFdaDrug(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FdaDrugCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaDrugResourceApi
   */
  public getAllFdaDrugs(criteria: FdaDrugCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return FdaDrugResourceApiFp(this.configuration)
      .getAllFdaDrugs(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaDrugResourceApi
   */
  public getFdaDrug(id: number, options?: AxiosRequestConfig) {
    return FdaDrugResourceApiFp(this.configuration)
      .getFdaDrug(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {FdaDrug} fdaDrug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaDrugResourceApi
   */
  public partialUpdateFdaDrug(id: number, fdaDrug: FdaDrug, options?: AxiosRequestConfig) {
    return FdaDrugResourceApiFp(this.configuration)
      .partialUpdateFdaDrug(id, fdaDrug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaDrugResourceApi
   */
  public searchFdaDrugs(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return FdaDrugResourceApiFp(this.configuration)
      .searchFdaDrugs(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {FdaDrug} fdaDrug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaDrugResourceApi
   */
  public updateFdaDrug(id: number, fdaDrug: FdaDrug, options?: AxiosRequestConfig) {
    return FdaDrugResourceApiFp(this.configuration)
      .updateFdaDrug(id, fdaDrug, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FdaSubmissionResourceApi - axios parameter creator
 * @export
 */
export const FdaSubmissionResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {FdaSubmissionCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countFdaSubmissions: async (criteria: FdaSubmissionCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countFdaSubmissions', 'criteria', criteria);
      const localVarPath = `/api/fda-submissions/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FdaSubmission} fdaSubmission
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFdaSubmission: async (fdaSubmission: FdaSubmission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'fdaSubmission' is not null or undefined
      assertParamExists('createFdaSubmission', 'fdaSubmission', fdaSubmission);
      const localVarPath = `/api/fda-submissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fdaSubmission, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFdaSubmission: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteFdaSubmission', 'id', id);
      const localVarPath = `/api/fda-submissions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFdaSubmissionsByCompanionDiagnosticDevice: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findFdaSubmissionsByCompanionDiagnosticDevice', 'id', id);
      const localVarPath = `/api/fda-submissions/companion-diagnostic-device/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FdaSubmissionCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFdaSubmissions: async (
      criteria: FdaSubmissionCriteria,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllFdaSubmissions', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllFdaSubmissions', 'pageable', pageable);
      const localVarPath = `/api/fda-submissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFdaSubmission: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getFdaSubmission', 'id', id);
      const localVarPath = `/api/fda-submissions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} number
     * @param {string} [supplementNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFdaSubmissionByNumber: async (number: string, supplementNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'number' is not null or undefined
      assertParamExists('getFdaSubmissionByNumber', 'number', number);
      const localVarPath = `/api/fda-submissions/lookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (number !== undefined) {
        localVarQueryParameter['number'] = number;
      }

      if (supplementNumber !== undefined) {
        localVarQueryParameter['supplementNumber'] = supplementNumber;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmission} fdaSubmission
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateFdaSubmission: async (
      id: number,
      fdaSubmission: FdaSubmission,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateFdaSubmission', 'id', id);
      // verify required parameter 'fdaSubmission' is not null or undefined
      assertParamExists('partialUpdateFdaSubmission', 'fdaSubmission', fdaSubmission);
      const localVarPath = `/api/fda-submissions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fdaSubmission, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchFdaSubmissions: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchFdaSubmissions', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchFdaSubmissions', 'pageable', pageable);
      const localVarPath = `/api/fda-submissions/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmission} fdaSubmission
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFdaSubmission: async (id: number, fdaSubmission: FdaSubmission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateFdaSubmission', 'id', id);
      // verify required parameter 'fdaSubmission' is not null or undefined
      assertParamExists('updateFdaSubmission', 'fdaSubmission', fdaSubmission);
      const localVarPath = `/api/fda-submissions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fdaSubmission, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FdaSubmissionResourceApi - functional programming interface
 * @export
 */
export const FdaSubmissionResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FdaSubmissionResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {FdaSubmissionCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countFdaSubmissions(
      criteria: FdaSubmissionCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countFdaSubmissions(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FdaSubmission} fdaSubmission
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFdaSubmission(
      fdaSubmission: FdaSubmission,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaSubmission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFdaSubmission(fdaSubmission, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFdaSubmission(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFdaSubmission(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findFdaSubmissionsByCompanionDiagnosticDevice(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FdaSubmission>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findFdaSubmissionsByCompanionDiagnosticDevice(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FdaSubmissionCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllFdaSubmissions(
      criteria: FdaSubmissionCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FdaSubmission>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFdaSubmissions(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFdaSubmission(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaSubmission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFdaSubmission(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} number
     * @param {string} [supplementNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFdaSubmissionByNumber(
      number: string,
      supplementNumber?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaSubmission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFdaSubmissionByNumber(number, supplementNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmission} fdaSubmission
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateFdaSubmission(
      id: number,
      fdaSubmission: FdaSubmission,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaSubmission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateFdaSubmission(id, fdaSubmission, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchFdaSubmissions(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FdaSubmission>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchFdaSubmissions(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmission} fdaSubmission
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFdaSubmission(
      id: number,
      fdaSubmission: FdaSubmission,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaSubmission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFdaSubmission(id, fdaSubmission, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FdaSubmissionResourceApi - factory interface
 * @export
 */
export const FdaSubmissionResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FdaSubmissionResourceApiFp(configuration);
  return {
    /**
     *
     * @param {FdaSubmissionCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countFdaSubmissions(criteria: FdaSubmissionCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countFdaSubmissions(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {FdaSubmission} fdaSubmission
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFdaSubmission(fdaSubmission: FdaSubmission, options?: any): AxiosPromise<FdaSubmission> {
      return localVarFp.createFdaSubmission(fdaSubmission, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFdaSubmission(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteFdaSubmission(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFdaSubmissionsByCompanionDiagnosticDevice(id: number, options?: any): AxiosPromise<Array<FdaSubmission>> {
      return localVarFp.findFdaSubmissionsByCompanionDiagnosticDevice(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {FdaSubmissionCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFdaSubmissions(criteria: FdaSubmissionCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<FdaSubmission>> {
      return localVarFp.getAllFdaSubmissions(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFdaSubmission(id: number, options?: any): AxiosPromise<FdaSubmission> {
      return localVarFp.getFdaSubmission(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} number
     * @param {string} [supplementNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFdaSubmissionByNumber(number: string, supplementNumber?: string, options?: any): AxiosPromise<FdaSubmission> {
      return localVarFp.getFdaSubmissionByNumber(number, supplementNumber, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmission} fdaSubmission
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateFdaSubmission(id: number, fdaSubmission: FdaSubmission, options?: any): AxiosPromise<FdaSubmission> {
      return localVarFp.partialUpdateFdaSubmission(id, fdaSubmission, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchFdaSubmissions(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<FdaSubmission>> {
      return localVarFp.searchFdaSubmissions(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmission} fdaSubmission
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFdaSubmission(id: number, fdaSubmission: FdaSubmission, options?: any): AxiosPromise<FdaSubmission> {
      return localVarFp.updateFdaSubmission(id, fdaSubmission, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * FdaSubmissionResourceApi - object-oriented interface
 * @export
 * @class FdaSubmissionResourceApi
 * @extends {BaseAPI}
 */
export class FdaSubmissionResourceApi extends BaseAPI {
  /**
   *
   * @param {FdaSubmissionCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public countFdaSubmissions(criteria: FdaSubmissionCriteria, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .countFdaSubmissions(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FdaSubmission} fdaSubmission
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public createFdaSubmission(fdaSubmission: FdaSubmission, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .createFdaSubmission(fdaSubmission, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public deleteFdaSubmission(id: number, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .deleteFdaSubmission(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public findFdaSubmissionsByCompanionDiagnosticDevice(id: number, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .findFdaSubmissionsByCompanionDiagnosticDevice(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FdaSubmissionCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public getAllFdaSubmissions(criteria: FdaSubmissionCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .getAllFdaSubmissions(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public getFdaSubmission(id: number, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .getFdaSubmission(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} number
   * @param {string} [supplementNumber]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public getFdaSubmissionByNumber(number: string, supplementNumber?: string, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .getFdaSubmissionByNumber(number, supplementNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {FdaSubmission} fdaSubmission
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public partialUpdateFdaSubmission(id: number, fdaSubmission: FdaSubmission, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .partialUpdateFdaSubmission(id, fdaSubmission, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public searchFdaSubmissions(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .searchFdaSubmissions(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {FdaSubmission} fdaSubmission
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionResourceApi
   */
  public updateFdaSubmission(id: number, fdaSubmission: FdaSubmission, options?: AxiosRequestConfig) {
    return FdaSubmissionResourceApiFp(this.configuration)
      .updateFdaSubmission(id, fdaSubmission, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FdaSubmissionTypeResourceApi - axios parameter creator
 * @export
 */
export const FdaSubmissionTypeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {FdaSubmissionType} fdaSubmissionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFdaSubmissionType: async (fdaSubmissionType: FdaSubmissionType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'fdaSubmissionType' is not null or undefined
      assertParamExists('createFdaSubmissionType', 'fdaSubmissionType', fdaSubmissionType);
      const localVarPath = `/api/fda-submission-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fdaSubmissionType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFdaSubmissionType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteFdaSubmissionType', 'id', id);
      const localVarPath = `/api/fda-submission-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFdaSubmissionTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/fda-submission-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFdaSubmissionType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getFdaSubmissionType', 'id', id);
      const localVarPath = `/api/fda-submission-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmissionType} fdaSubmissionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateFdaSubmissionType: async (
      id: number,
      fdaSubmissionType: FdaSubmissionType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateFdaSubmissionType', 'id', id);
      // verify required parameter 'fdaSubmissionType' is not null or undefined
      assertParamExists('partialUpdateFdaSubmissionType', 'fdaSubmissionType', fdaSubmissionType);
      const localVarPath = `/api/fda-submission-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fdaSubmissionType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmissionType} fdaSubmissionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFdaSubmissionType: async (
      id: number,
      fdaSubmissionType: FdaSubmissionType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateFdaSubmissionType', 'id', id);
      // verify required parameter 'fdaSubmissionType' is not null or undefined
      assertParamExists('updateFdaSubmissionType', 'fdaSubmissionType', fdaSubmissionType);
      const localVarPath = `/api/fda-submission-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fdaSubmissionType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FdaSubmissionTypeResourceApi - functional programming interface
 * @export
 */
export const FdaSubmissionTypeResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FdaSubmissionTypeResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {FdaSubmissionType} fdaSubmissionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFdaSubmissionType(
      fdaSubmissionType: FdaSubmissionType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaSubmissionType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFdaSubmissionType(fdaSubmissionType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFdaSubmissionType(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFdaSubmissionType(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllFdaSubmissionTypes(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FdaSubmissionType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFdaSubmissionTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFdaSubmissionType(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaSubmissionType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFdaSubmissionType(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmissionType} fdaSubmissionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateFdaSubmissionType(
      id: number,
      fdaSubmissionType: FdaSubmissionType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaSubmissionType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateFdaSubmissionType(id, fdaSubmissionType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmissionType} fdaSubmissionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFdaSubmissionType(
      id: number,
      fdaSubmissionType: FdaSubmissionType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FdaSubmissionType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFdaSubmissionType(id, fdaSubmissionType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FdaSubmissionTypeResourceApi - factory interface
 * @export
 */
export const FdaSubmissionTypeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FdaSubmissionTypeResourceApiFp(configuration);
  return {
    /**
     *
     * @param {FdaSubmissionType} fdaSubmissionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFdaSubmissionType(fdaSubmissionType: FdaSubmissionType, options?: any): AxiosPromise<FdaSubmissionType> {
      return localVarFp.createFdaSubmissionType(fdaSubmissionType, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFdaSubmissionType(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteFdaSubmissionType(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFdaSubmissionTypes(options?: any): AxiosPromise<Array<FdaSubmissionType>> {
      return localVarFp.getAllFdaSubmissionTypes(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFdaSubmissionType(id: number, options?: any): AxiosPromise<FdaSubmissionType> {
      return localVarFp.getFdaSubmissionType(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmissionType} fdaSubmissionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateFdaSubmissionType(id: number, fdaSubmissionType: FdaSubmissionType, options?: any): AxiosPromise<FdaSubmissionType> {
      return localVarFp.partialUpdateFdaSubmissionType(id, fdaSubmissionType, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {FdaSubmissionType} fdaSubmissionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFdaSubmissionType(id: number, fdaSubmissionType: FdaSubmissionType, options?: any): AxiosPromise<FdaSubmissionType> {
      return localVarFp.updateFdaSubmissionType(id, fdaSubmissionType, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * FdaSubmissionTypeResourceApi - object-oriented interface
 * @export
 * @class FdaSubmissionTypeResourceApi
 * @extends {BaseAPI}
 */
export class FdaSubmissionTypeResourceApi extends BaseAPI {
  /**
   *
   * @param {FdaSubmissionType} fdaSubmissionType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionTypeResourceApi
   */
  public createFdaSubmissionType(fdaSubmissionType: FdaSubmissionType, options?: AxiosRequestConfig) {
    return FdaSubmissionTypeResourceApiFp(this.configuration)
      .createFdaSubmissionType(fdaSubmissionType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionTypeResourceApi
   */
  public deleteFdaSubmissionType(id: number, options?: AxiosRequestConfig) {
    return FdaSubmissionTypeResourceApiFp(this.configuration)
      .deleteFdaSubmissionType(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionTypeResourceApi
   */
  public getAllFdaSubmissionTypes(options?: AxiosRequestConfig) {
    return FdaSubmissionTypeResourceApiFp(this.configuration)
      .getAllFdaSubmissionTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionTypeResourceApi
   */
  public getFdaSubmissionType(id: number, options?: AxiosRequestConfig) {
    return FdaSubmissionTypeResourceApiFp(this.configuration)
      .getFdaSubmissionType(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {FdaSubmissionType} fdaSubmissionType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionTypeResourceApi
   */
  public partialUpdateFdaSubmissionType(id: number, fdaSubmissionType: FdaSubmissionType, options?: AxiosRequestConfig) {
    return FdaSubmissionTypeResourceApiFp(this.configuration)
      .partialUpdateFdaSubmissionType(id, fdaSubmissionType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {FdaSubmissionType} fdaSubmissionType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FdaSubmissionTypeResourceApi
   */
  public updateFdaSubmissionType(id: number, fdaSubmissionType: FdaSubmissionType, options?: AxiosRequestConfig) {
    return FdaSubmissionTypeResourceApiFp(this.configuration)
      .updateFdaSubmissionType(id, fdaSubmissionType, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FlagResourceApi - axios parameter creator
 * @export
 */
export const FlagResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {FlagCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countFlags: async (criteria: FlagCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countFlags', 'criteria', criteria);
      const localVarPath = `/api/flags/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Flag} flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFlag: async (flag: Flag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'flag' is not null or undefined
      assertParamExists('createFlag', 'flag', flag);
      const localVarPath = `/api/flags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(flag, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFlag: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteFlag', 'id', id);
      const localVarPath = `/api/flags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FlagCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFlags: async (criteria: FlagCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllFlags', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllFlags', 'pageable', pageable);
      const localVarPath = `/api/flags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlag: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getFlag', 'id', id);
      const localVarPath = `/api/flags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Flag} flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateFlag: async (id: number, flag: Flag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateFlag', 'id', id);
      // verify required parameter 'flag' is not null or undefined
      assertParamExists('partialUpdateFlag', 'flag', flag);
      const localVarPath = `/api/flags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(flag, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchFlags: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchFlags', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchFlags', 'pageable', pageable);
      const localVarPath = `/api/flags/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Flag} flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFlag: async (id: number, flag: Flag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateFlag', 'id', id);
      // verify required parameter 'flag' is not null or undefined
      assertParamExists('updateFlag', 'flag', flag);
      const localVarPath = `/api/flags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(flag, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FlagResourceApi - functional programming interface
 * @export
 */
export const FlagResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FlagResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {FlagCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countFlags(
      criteria: FlagCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countFlags(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Flag} flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFlag(flag: Flag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flag>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFlag(flag, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFlag(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFlag(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FlagCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllFlags(
      criteria: FlagCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Flag>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFlags(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFlag(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flag>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFlag(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Flag} flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateFlag(
      id: number,
      flag: Flag,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flag>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateFlag(id, flag, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchFlags(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Flag>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchFlags(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Flag} flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFlag(
      id: number,
      flag: Flag,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flag>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlag(id, flag, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FlagResourceApi - factory interface
 * @export
 */
export const FlagResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FlagResourceApiFp(configuration);
  return {
    /**
     *
     * @param {FlagCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countFlags(criteria: FlagCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countFlags(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Flag} flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFlag(flag: Flag, options?: any): AxiosPromise<Flag> {
      return localVarFp.createFlag(flag, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFlag(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteFlag(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {FlagCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFlags(criteria: FlagCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<Flag>> {
      return localVarFp.getAllFlags(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlag(id: number, options?: any): AxiosPromise<Flag> {
      return localVarFp.getFlag(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Flag} flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateFlag(id: number, flag: Flag, options?: any): AxiosPromise<Flag> {
      return localVarFp.partialUpdateFlag(id, flag, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchFlags(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<Flag>> {
      return localVarFp.searchFlags(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Flag} flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFlag(id: number, flag: Flag, options?: any): AxiosPromise<Flag> {
      return localVarFp.updateFlag(id, flag, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * FlagResourceApi - object-oriented interface
 * @export
 * @class FlagResourceApi
 * @extends {BaseAPI}
 */
export class FlagResourceApi extends BaseAPI {
  /**
   *
   * @param {FlagCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagResourceApi
   */
  public countFlags(criteria: FlagCriteria, options?: AxiosRequestConfig) {
    return FlagResourceApiFp(this.configuration)
      .countFlags(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Flag} flag
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagResourceApi
   */
  public createFlag(flag: Flag, options?: AxiosRequestConfig) {
    return FlagResourceApiFp(this.configuration)
      .createFlag(flag, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagResourceApi
   */
  public deleteFlag(id: number, options?: AxiosRequestConfig) {
    return FlagResourceApiFp(this.configuration)
      .deleteFlag(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FlagCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagResourceApi
   */
  public getAllFlags(criteria: FlagCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return FlagResourceApiFp(this.configuration)
      .getAllFlags(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagResourceApi
   */
  public getFlag(id: number, options?: AxiosRequestConfig) {
    return FlagResourceApiFp(this.configuration)
      .getFlag(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Flag} flag
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagResourceApi
   */
  public partialUpdateFlag(id: number, flag: Flag, options?: AxiosRequestConfig) {
    return FlagResourceApiFp(this.configuration)
      .partialUpdateFlag(id, flag, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagResourceApi
   */
  public searchFlags(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return FlagResourceApiFp(this.configuration)
      .searchFlags(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Flag} flag
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagResourceApi
   */
  public updateFlag(id: number, flag: Flag, options?: AxiosRequestConfig) {
    return FlagResourceApiFp(this.configuration)
      .updateFlag(id, flag, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GeneControllerApi - axios parameter creator
 * @export
 */
export const GeneControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findGeneBySymbol: async (symbol: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'symbol' is not null or undefined
      assertParamExists('findGeneBySymbol', 'symbol', symbol);
      const localVarPath = `/api/find-genes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (symbol !== undefined) {
        localVarQueryParameter['symbol'] = symbol;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findGenesBySymbols: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('findGenesBySymbols', 'requestBody', requestBody);
      const localVarPath = `/api/find-genes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GeneControllerApi - functional programming interface
 * @export
 */
export const GeneControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GeneControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findGeneBySymbol(
      symbol: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Gene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findGeneBySymbol(symbol, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findGenesBySymbols(
      requestBody: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Gene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findGenesBySymbols(requestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GeneControllerApi - factory interface
 * @export
 */
export const GeneControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = GeneControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findGeneBySymbol(symbol: string, options?: any): AxiosPromise<Gene> {
      return localVarFp.findGeneBySymbol(symbol, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findGenesBySymbols(requestBody: Array<string>, options?: any): AxiosPromise<Array<Gene>> {
      return localVarFp.findGenesBySymbols(requestBody, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * GeneControllerApi - object-oriented interface
 * @export
 * @class GeneControllerApi
 * @extends {BaseAPI}
 */
export class GeneControllerApi extends BaseAPI {
  /**
   *
   * @param {string} symbol
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneControllerApi
   */
  public findGeneBySymbol(symbol: string, options?: AxiosRequestConfig) {
    return GeneControllerApiFp(this.configuration)
      .findGeneBySymbol(symbol, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneControllerApi
   */
  public findGenesBySymbols(requestBody: Array<string>, options?: AxiosRequestConfig) {
    return GeneControllerApiFp(this.configuration)
      .findGenesBySymbols(requestBody, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GeneResourceApi - axios parameter creator
 * @export
 */
export const GeneResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {GeneCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countGenes: async (criteria: GeneCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countGenes', 'criteria', criteria);
      const localVarPath = `/api/genes/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Gene} gene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGene: async (gene: Gene, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'gene' is not null or undefined
      assertParamExists('createGene', 'gene', gene);
      const localVarPath = `/api/genes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(gene, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGene: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteGene', 'id', id);
      const localVarPath = `/api/genes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GeneCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllGenes: async (criteria: GeneCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllGenes', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllGenes', 'pageable', pageable);
      const localVarPath = `/api/genes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGene: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getGene', 'id', id);
      const localVarPath = `/api/genes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Gene} gene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateGene: async (id: number, gene: Gene, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateGene', 'id', id);
      // verify required parameter 'gene' is not null or undefined
      assertParamExists('partialUpdateGene', 'gene', gene);
      const localVarPath = `/api/genes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(gene, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {boolean} [exact]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGenes: async (query: string, pageable: Pageable, exact?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchGenes', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchGenes', 'pageable', pageable);
      const localVarPath = `/api/genes/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (exact !== undefined) {
        localVarQueryParameter['exact'] = exact;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Gene} gene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGene: async (id: number, gene: Gene, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateGene', 'id', id);
      // verify required parameter 'gene' is not null or undefined
      assertParamExists('updateGene', 'gene', gene);
      const localVarPath = `/api/genes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(gene, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GeneResourceApi - functional programming interface
 * @export
 */
export const GeneResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GeneResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {GeneCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countGenes(
      criteria: GeneCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countGenes(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Gene} gene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGene(gene: Gene, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Gene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createGene(gene, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGene(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGene(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GeneCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllGenes(
      criteria: GeneCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Gene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGenes(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGene(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Gene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGene(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Gene} gene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateGene(
      id: number,
      gene: Gene,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Gene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateGene(id, gene, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {boolean} [exact]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchGenes(
      query: string,
      pageable: Pageable,
      exact?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Gene>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchGenes(query, pageable, exact, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Gene} gene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGene(
      id: number,
      gene: Gene,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Gene>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGene(id, gene, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GeneResourceApi - factory interface
 * @export
 */
export const GeneResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = GeneResourceApiFp(configuration);
  return {
    /**
     *
     * @param {GeneCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countGenes(criteria: GeneCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countGenes(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Gene} gene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGene(gene: Gene, options?: any): AxiosPromise<Gene> {
      return localVarFp.createGene(gene, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGene(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteGene(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GeneCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllGenes(criteria: GeneCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<Gene>> {
      return localVarFp.getAllGenes(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGene(id: number, options?: any): AxiosPromise<Gene> {
      return localVarFp.getGene(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Gene} gene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateGene(id: number, gene: Gene, options?: any): AxiosPromise<Gene> {
      return localVarFp.partialUpdateGene(id, gene, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {boolean} [exact]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGenes(query: string, pageable: Pageable, exact?: boolean, options?: any): AxiosPromise<Array<Gene>> {
      return localVarFp.searchGenes(query, pageable, exact, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Gene} gene
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGene(id: number, gene: Gene, options?: any): AxiosPromise<Gene> {
      return localVarFp.updateGene(id, gene, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * GeneResourceApi - object-oriented interface
 * @export
 * @class GeneResourceApi
 * @extends {BaseAPI}
 */
export class GeneResourceApi extends BaseAPI {
  /**
   *
   * @param {GeneCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneResourceApi
   */
  public countGenes(criteria: GeneCriteria, options?: AxiosRequestConfig) {
    return GeneResourceApiFp(this.configuration)
      .countGenes(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Gene} gene
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneResourceApi
   */
  public createGene(gene: Gene, options?: AxiosRequestConfig) {
    return GeneResourceApiFp(this.configuration)
      .createGene(gene, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneResourceApi
   */
  public deleteGene(id: number, options?: AxiosRequestConfig) {
    return GeneResourceApiFp(this.configuration)
      .deleteGene(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GeneCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneResourceApi
   */
  public getAllGenes(criteria: GeneCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return GeneResourceApiFp(this.configuration)
      .getAllGenes(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneResourceApi
   */
  public getGene(id: number, options?: AxiosRequestConfig) {
    return GeneResourceApiFp(this.configuration)
      .getGene(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Gene} gene
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneResourceApi
   */
  public partialUpdateGene(id: number, gene: Gene, options?: AxiosRequestConfig) {
    return GeneResourceApiFp(this.configuration)
      .partialUpdateGene(id, gene, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {boolean} [exact]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneResourceApi
   */
  public searchGenes(query: string, pageable: Pageable, exact?: boolean, options?: AxiosRequestConfig) {
    return GeneResourceApiFp(this.configuration)
      .searchGenes(query, pageable, exact, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Gene} gene
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneResourceApi
   */
  public updateGene(id: number, gene: Gene, options?: AxiosRequestConfig) {
    return GeneResourceApiFp(this.configuration)
      .updateGene(id, gene, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GenomeFragmentResourceApi - axios parameter creator
 * @export
 */
export const GenomeFragmentResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {GenomeFragmentCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countGenomeFragments: async (criteria: GenomeFragmentCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countGenomeFragments', 'criteria', criteria);
      const localVarPath = `/api/genome-fragments/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GenomeFragment} genomeFragment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGenomeFragment: async (genomeFragment: GenomeFragment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'genomeFragment' is not null or undefined
      assertParamExists('createGenomeFragment', 'genomeFragment', genomeFragment);
      const localVarPath = `/api/genome-fragments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(genomeFragment, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGenomeFragment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteGenomeFragment', 'id', id);
      const localVarPath = `/api/genome-fragments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GenomeFragmentCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllGenomeFragments: async (
      criteria: GenomeFragmentCriteria,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllGenomeFragments', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllGenomeFragments', 'pageable', pageable);
      const localVarPath = `/api/genome-fragments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenomeFragment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getGenomeFragment', 'id', id);
      const localVarPath = `/api/genome-fragments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {GenomeFragment} genomeFragment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateGenomeFragment: async (
      id: number,
      genomeFragment: GenomeFragment,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateGenomeFragment', 'id', id);
      // verify required parameter 'genomeFragment' is not null or undefined
      assertParamExists('partialUpdateGenomeFragment', 'genomeFragment', genomeFragment);
      const localVarPath = `/api/genome-fragments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(genomeFragment, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGenomeFragments: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchGenomeFragments', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchGenomeFragments', 'pageable', pageable);
      const localVarPath = `/api/genome-fragments/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {GenomeFragment} genomeFragment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGenomeFragment: async (id: number, genomeFragment: GenomeFragment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateGenomeFragment', 'id', id);
      // verify required parameter 'genomeFragment' is not null or undefined
      assertParamExists('updateGenomeFragment', 'genomeFragment', genomeFragment);
      const localVarPath = `/api/genome-fragments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(genomeFragment, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GenomeFragmentResourceApi - functional programming interface
 * @export
 */
export const GenomeFragmentResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GenomeFragmentResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {GenomeFragmentCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countGenomeFragments(
      criteria: GenomeFragmentCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countGenomeFragments(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GenomeFragment} genomeFragment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGenomeFragment(
      genomeFragment: GenomeFragment,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenomeFragment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createGenomeFragment(genomeFragment, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGenomeFragment(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGenomeFragment(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GenomeFragmentCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllGenomeFragments(
      criteria: GenomeFragmentCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenomeFragment>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGenomeFragments(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGenomeFragment(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenomeFragment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGenomeFragment(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {GenomeFragment} genomeFragment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateGenomeFragment(
      id: number,
      genomeFragment: GenomeFragment,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenomeFragment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateGenomeFragment(id, genomeFragment, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchGenomeFragments(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenomeFragment>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchGenomeFragments(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {GenomeFragment} genomeFragment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGenomeFragment(
      id: number,
      genomeFragment: GenomeFragment,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenomeFragment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGenomeFragment(id, genomeFragment, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GenomeFragmentResourceApi - factory interface
 * @export
 */
export const GenomeFragmentResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = GenomeFragmentResourceApiFp(configuration);
  return {
    /**
     *
     * @param {GenomeFragmentCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countGenomeFragments(criteria: GenomeFragmentCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countGenomeFragments(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GenomeFragment} genomeFragment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGenomeFragment(genomeFragment: GenomeFragment, options?: any): AxiosPromise<GenomeFragment> {
      return localVarFp.createGenomeFragment(genomeFragment, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGenomeFragment(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteGenomeFragment(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GenomeFragmentCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllGenomeFragments(criteria: GenomeFragmentCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<GenomeFragment>> {
      return localVarFp.getAllGenomeFragments(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenomeFragment(id: number, options?: any): AxiosPromise<GenomeFragment> {
      return localVarFp.getGenomeFragment(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {GenomeFragment} genomeFragment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateGenomeFragment(id: number, genomeFragment: GenomeFragment, options?: any): AxiosPromise<GenomeFragment> {
      return localVarFp.partialUpdateGenomeFragment(id, genomeFragment, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGenomeFragments(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<GenomeFragment>> {
      return localVarFp.searchGenomeFragments(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {GenomeFragment} genomeFragment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGenomeFragment(id: number, genomeFragment: GenomeFragment, options?: any): AxiosPromise<GenomeFragment> {
      return localVarFp.updateGenomeFragment(id, genomeFragment, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * GenomeFragmentResourceApi - object-oriented interface
 * @export
 * @class GenomeFragmentResourceApi
 * @extends {BaseAPI}
 */
export class GenomeFragmentResourceApi extends BaseAPI {
  /**
   *
   * @param {GenomeFragmentCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomeFragmentResourceApi
   */
  public countGenomeFragments(criteria: GenomeFragmentCriteria, options?: AxiosRequestConfig) {
    return GenomeFragmentResourceApiFp(this.configuration)
      .countGenomeFragments(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GenomeFragment} genomeFragment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomeFragmentResourceApi
   */
  public createGenomeFragment(genomeFragment: GenomeFragment, options?: AxiosRequestConfig) {
    return GenomeFragmentResourceApiFp(this.configuration)
      .createGenomeFragment(genomeFragment, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomeFragmentResourceApi
   */
  public deleteGenomeFragment(id: number, options?: AxiosRequestConfig) {
    return GenomeFragmentResourceApiFp(this.configuration)
      .deleteGenomeFragment(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GenomeFragmentCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomeFragmentResourceApi
   */
  public getAllGenomeFragments(criteria: GenomeFragmentCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return GenomeFragmentResourceApiFp(this.configuration)
      .getAllGenomeFragments(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomeFragmentResourceApi
   */
  public getGenomeFragment(id: number, options?: AxiosRequestConfig) {
    return GenomeFragmentResourceApiFp(this.configuration)
      .getGenomeFragment(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {GenomeFragment} genomeFragment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomeFragmentResourceApi
   */
  public partialUpdateGenomeFragment(id: number, genomeFragment: GenomeFragment, options?: AxiosRequestConfig) {
    return GenomeFragmentResourceApiFp(this.configuration)
      .partialUpdateGenomeFragment(id, genomeFragment, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomeFragmentResourceApi
   */
  public searchGenomeFragments(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return GenomeFragmentResourceApiFp(this.configuration)
      .searchGenomeFragments(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {GenomeFragment} genomeFragment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomeFragmentResourceApi
   */
  public updateGenomeFragment(id: number, genomeFragment: GenomeFragment, options?: AxiosRequestConfig) {
    return GenomeFragmentResourceApiFp(this.configuration)
      .updateGenomeFragment(id, genomeFragment, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GenomicIndicatorResourceApi - axios parameter creator
 * @export
 */
export const GenomicIndicatorResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {GenomicIndicatorCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countGenomicIndicators: async (criteria: GenomicIndicatorCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countGenomicIndicators', 'criteria', criteria);
      const localVarPath = `/api/genomic-indicators/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GenomicIndicator} genomicIndicator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGenomicIndicator: async (genomicIndicator: GenomicIndicator, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'genomicIndicator' is not null or undefined
      assertParamExists('createGenomicIndicator', 'genomicIndicator', genomicIndicator);
      const localVarPath = `/api/genomic-indicators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(genomicIndicator, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGenomicIndicator: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteGenomicIndicator', 'id', id);
      const localVarPath = `/api/genomic-indicators/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GenomicIndicatorCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllGenomicIndicators: async (criteria: GenomicIndicatorCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllGenomicIndicators', 'criteria', criteria);
      const localVarPath = `/api/genomic-indicators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenomicIndicator: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getGenomicIndicator', 'id', id);
      const localVarPath = `/api/genomic-indicators/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {GenomicIndicator} genomicIndicator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateGenomicIndicator: async (
      id: number,
      genomicIndicator: GenomicIndicator,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateGenomicIndicator', 'id', id);
      // verify required parameter 'genomicIndicator' is not null or undefined
      assertParamExists('partialUpdateGenomicIndicator', 'genomicIndicator', genomicIndicator);
      const localVarPath = `/api/genomic-indicators/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(genomicIndicator, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGenomicIndicators: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchGenomicIndicators', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchGenomicIndicators', 'pageable', pageable);
      const localVarPath = `/api/genomic-indicators/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {GenomicIndicator} genomicIndicator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGenomicIndicator: async (
      id: number,
      genomicIndicator: GenomicIndicator,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateGenomicIndicator', 'id', id);
      // verify required parameter 'genomicIndicator' is not null or undefined
      assertParamExists('updateGenomicIndicator', 'genomicIndicator', genomicIndicator);
      const localVarPath = `/api/genomic-indicators/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(genomicIndicator, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GenomicIndicatorResourceApi - functional programming interface
 * @export
 */
export const GenomicIndicatorResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GenomicIndicatorResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {GenomicIndicatorCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countGenomicIndicators(
      criteria: GenomicIndicatorCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countGenomicIndicators(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GenomicIndicator} genomicIndicator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGenomicIndicator(
      genomicIndicator: GenomicIndicator,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenomicIndicator>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createGenomicIndicator(genomicIndicator, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGenomicIndicator(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGenomicIndicator(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GenomicIndicatorCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllGenomicIndicators(
      criteria: GenomicIndicatorCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenomicIndicator>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGenomicIndicators(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGenomicIndicator(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenomicIndicator>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGenomicIndicator(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {GenomicIndicator} genomicIndicator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateGenomicIndicator(
      id: number,
      genomicIndicator: GenomicIndicator,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenomicIndicator>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateGenomicIndicator(id, genomicIndicator, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchGenomicIndicators(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenomicIndicator>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchGenomicIndicators(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {GenomicIndicator} genomicIndicator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGenomicIndicator(
      id: number,
      genomicIndicator: GenomicIndicator,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenomicIndicator>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGenomicIndicator(id, genomicIndicator, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GenomicIndicatorResourceApi - factory interface
 * @export
 */
export const GenomicIndicatorResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = GenomicIndicatorResourceApiFp(configuration);
  return {
    /**
     *
     * @param {GenomicIndicatorCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countGenomicIndicators(criteria: GenomicIndicatorCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countGenomicIndicators(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GenomicIndicator} genomicIndicator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGenomicIndicator(genomicIndicator: GenomicIndicator, options?: any): AxiosPromise<GenomicIndicator> {
      return localVarFp.createGenomicIndicator(genomicIndicator, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGenomicIndicator(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteGenomicIndicator(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GenomicIndicatorCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllGenomicIndicators(criteria: GenomicIndicatorCriteria, options?: any): AxiosPromise<Array<GenomicIndicator>> {
      return localVarFp.getAllGenomicIndicators(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenomicIndicator(id: number, options?: any): AxiosPromise<GenomicIndicator> {
      return localVarFp.getGenomicIndicator(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {GenomicIndicator} genomicIndicator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateGenomicIndicator(id: number, genomicIndicator: GenomicIndicator, options?: any): AxiosPromise<GenomicIndicator> {
      return localVarFp.partialUpdateGenomicIndicator(id, genomicIndicator, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGenomicIndicators(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<GenomicIndicator>> {
      return localVarFp.searchGenomicIndicators(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {GenomicIndicator} genomicIndicator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGenomicIndicator(id: number, genomicIndicator: GenomicIndicator, options?: any): AxiosPromise<GenomicIndicator> {
      return localVarFp.updateGenomicIndicator(id, genomicIndicator, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * GenomicIndicatorResourceApi - object-oriented interface
 * @export
 * @class GenomicIndicatorResourceApi
 * @extends {BaseAPI}
 */
export class GenomicIndicatorResourceApi extends BaseAPI {
  /**
   *
   * @param {GenomicIndicatorCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomicIndicatorResourceApi
   */
  public countGenomicIndicators(criteria: GenomicIndicatorCriteria, options?: AxiosRequestConfig) {
    return GenomicIndicatorResourceApiFp(this.configuration)
      .countGenomicIndicators(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GenomicIndicator} genomicIndicator
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomicIndicatorResourceApi
   */
  public createGenomicIndicator(genomicIndicator: GenomicIndicator, options?: AxiosRequestConfig) {
    return GenomicIndicatorResourceApiFp(this.configuration)
      .createGenomicIndicator(genomicIndicator, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomicIndicatorResourceApi
   */
  public deleteGenomicIndicator(id: number, options?: AxiosRequestConfig) {
    return GenomicIndicatorResourceApiFp(this.configuration)
      .deleteGenomicIndicator(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GenomicIndicatorCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomicIndicatorResourceApi
   */
  public getAllGenomicIndicators(criteria: GenomicIndicatorCriteria, options?: AxiosRequestConfig) {
    return GenomicIndicatorResourceApiFp(this.configuration)
      .getAllGenomicIndicators(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomicIndicatorResourceApi
   */
  public getGenomicIndicator(id: number, options?: AxiosRequestConfig) {
    return GenomicIndicatorResourceApiFp(this.configuration)
      .getGenomicIndicator(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {GenomicIndicator} genomicIndicator
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomicIndicatorResourceApi
   */
  public partialUpdateGenomicIndicator(id: number, genomicIndicator: GenomicIndicator, options?: AxiosRequestConfig) {
    return GenomicIndicatorResourceApiFp(this.configuration)
      .partialUpdateGenomicIndicator(id, genomicIndicator, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomicIndicatorResourceApi
   */
  public searchGenomicIndicators(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return GenomicIndicatorResourceApiFp(this.configuration)
      .searchGenomicIndicators(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {GenomicIndicator} genomicIndicator
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GenomicIndicatorResourceApi
   */
  public updateGenomicIndicator(id: number, genomicIndicator: GenomicIndicator, options?: AxiosRequestConfig) {
    return GenomicIndicatorResourceApiFp(this.configuration)
      .updateGenomicIndicator(id, genomicIndicator, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InfoResourceApi - axios parameter creator
 * @export
 */
export const InfoResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Info} info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInfo: async (info: Info, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'info' is not null or undefined
      assertParamExists('createInfo', 'info', info);
      const localVarPath = `/api/infos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(info, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteInfo', 'id', id);
      const localVarPath = `/api/infos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInfos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/infos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getInfo', 'id', id);
      const localVarPath = `/api/infos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Info} info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateInfo: async (id: number, info: Info, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateInfo', 'id', id);
      // verify required parameter 'info' is not null or undefined
      assertParamExists('partialUpdateInfo', 'info', info);
      const localVarPath = `/api/infos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(info, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Info} info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInfo: async (id: number, info: Info, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateInfo', 'id', id);
      // verify required parameter 'info' is not null or undefined
      assertParamExists('updateInfo', 'info', info);
      const localVarPath = `/api/infos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(info, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InfoResourceApi - functional programming interface
 * @export
 */
export const InfoResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InfoResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Info} info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInfo(info: Info, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Info>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInfo(info, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInfo(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllInfos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Info>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInfos(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Info>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInfo(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Info} info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateInfo(
      id: number,
      info: Info,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Info>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateInfo(id, info, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Info} info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInfo(
      id: number,
      info: Info,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Info>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInfo(id, info, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InfoResourceApi - factory interface
 * @export
 */
export const InfoResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InfoResourceApiFp(configuration);
  return {
    /**
     *
     * @param {Info} info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInfo(info: Info, options?: any): AxiosPromise<Info> {
      return localVarFp.createInfo(info, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInfo(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteInfo(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInfos(options?: any): AxiosPromise<Array<Info>> {
      return localVarFp.getAllInfos(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo(id: number, options?: any): AxiosPromise<Info> {
      return localVarFp.getInfo(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Info} info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateInfo(id: number, info: Info, options?: any): AxiosPromise<Info> {
      return localVarFp.partialUpdateInfo(id, info, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Info} info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInfo(id: number, info: Info, options?: any): AxiosPromise<Info> {
      return localVarFp.updateInfo(id, info, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * InfoResourceApi - object-oriented interface
 * @export
 * @class InfoResourceApi
 * @extends {BaseAPI}
 */
export class InfoResourceApi extends BaseAPI {
  /**
   *
   * @param {Info} info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InfoResourceApi
   */
  public createInfo(info: Info, options?: AxiosRequestConfig) {
    return InfoResourceApiFp(this.configuration)
      .createInfo(info, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InfoResourceApi
   */
  public deleteInfo(id: number, options?: AxiosRequestConfig) {
    return InfoResourceApiFp(this.configuration)
      .deleteInfo(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InfoResourceApi
   */
  public getAllInfos(options?: AxiosRequestConfig) {
    return InfoResourceApiFp(this.configuration)
      .getAllInfos(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InfoResourceApi
   */
  public getInfo(id: number, options?: AxiosRequestConfig) {
    return InfoResourceApiFp(this.configuration)
      .getInfo(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Info} info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InfoResourceApi
   */
  public partialUpdateInfo(id: number, info: Info, options?: AxiosRequestConfig) {
    return InfoResourceApiFp(this.configuration)
      .partialUpdateInfo(id, info, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Info} info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InfoResourceApi
   */
  public updateInfo(id: number, info: Info, options?: AxiosRequestConfig) {
    return InfoResourceApiFp(this.configuration)
      .updateInfo(id, info, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LevelOfEvidenceResourceApi - axios parameter creator
 * @export
 */
export const LevelOfEvidenceResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {LevelOfEvidence} levelOfEvidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLevelOfEvidence: async (levelOfEvidence: LevelOfEvidence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'levelOfEvidence' is not null or undefined
      assertParamExists('createLevelOfEvidence', 'levelOfEvidence', levelOfEvidence);
      const localVarPath = `/api/level-of-evidences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(levelOfEvidence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLevelOfEvidence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteLevelOfEvidence', 'id', id);
      const localVarPath = `/api/level-of-evidences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLevelOfEvidences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/level-of-evidences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLevelOfEvidence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getLevelOfEvidence', 'id', id);
      const localVarPath = `/api/level-of-evidences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {LevelOfEvidence} levelOfEvidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateLevelOfEvidence: async (
      id: number,
      levelOfEvidence: LevelOfEvidence,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateLevelOfEvidence', 'id', id);
      // verify required parameter 'levelOfEvidence' is not null or undefined
      assertParamExists('partialUpdateLevelOfEvidence', 'levelOfEvidence', levelOfEvidence);
      const localVarPath = `/api/level-of-evidences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(levelOfEvidence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {LevelOfEvidence} levelOfEvidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLevelOfEvidence: async (id: number, levelOfEvidence: LevelOfEvidence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateLevelOfEvidence', 'id', id);
      // verify required parameter 'levelOfEvidence' is not null or undefined
      assertParamExists('updateLevelOfEvidence', 'levelOfEvidence', levelOfEvidence);
      const localVarPath = `/api/level-of-evidences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(levelOfEvidence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LevelOfEvidenceResourceApi - functional programming interface
 * @export
 */
export const LevelOfEvidenceResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LevelOfEvidenceResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {LevelOfEvidence} levelOfEvidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLevelOfEvidence(
      levelOfEvidence: LevelOfEvidence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LevelOfEvidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createLevelOfEvidence(levelOfEvidence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLevelOfEvidence(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLevelOfEvidence(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllLevelOfEvidences(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LevelOfEvidence>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLevelOfEvidences(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLevelOfEvidence(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LevelOfEvidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLevelOfEvidence(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {LevelOfEvidence} levelOfEvidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateLevelOfEvidence(
      id: number,
      levelOfEvidence: LevelOfEvidence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LevelOfEvidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateLevelOfEvidence(id, levelOfEvidence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {LevelOfEvidence} levelOfEvidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLevelOfEvidence(
      id: number,
      levelOfEvidence: LevelOfEvidence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LevelOfEvidence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateLevelOfEvidence(id, levelOfEvidence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LevelOfEvidenceResourceApi - factory interface
 * @export
 */
export const LevelOfEvidenceResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LevelOfEvidenceResourceApiFp(configuration);
  return {
    /**
     *
     * @param {LevelOfEvidence} levelOfEvidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLevelOfEvidence(levelOfEvidence: LevelOfEvidence, options?: any): AxiosPromise<LevelOfEvidence> {
      return localVarFp.createLevelOfEvidence(levelOfEvidence, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLevelOfEvidence(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteLevelOfEvidence(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLevelOfEvidences(options?: any): AxiosPromise<Array<LevelOfEvidence>> {
      return localVarFp.getAllLevelOfEvidences(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLevelOfEvidence(id: number, options?: any): AxiosPromise<LevelOfEvidence> {
      return localVarFp.getLevelOfEvidence(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {LevelOfEvidence} levelOfEvidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateLevelOfEvidence(id: number, levelOfEvidence: LevelOfEvidence, options?: any): AxiosPromise<LevelOfEvidence> {
      return localVarFp.partialUpdateLevelOfEvidence(id, levelOfEvidence, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {LevelOfEvidence} levelOfEvidence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLevelOfEvidence(id: number, levelOfEvidence: LevelOfEvidence, options?: any): AxiosPromise<LevelOfEvidence> {
      return localVarFp.updateLevelOfEvidence(id, levelOfEvidence, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * LevelOfEvidenceResourceApi - object-oriented interface
 * @export
 * @class LevelOfEvidenceResourceApi
 * @extends {BaseAPI}
 */
export class LevelOfEvidenceResourceApi extends BaseAPI {
  /**
   *
   * @param {LevelOfEvidence} levelOfEvidence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelOfEvidenceResourceApi
   */
  public createLevelOfEvidence(levelOfEvidence: LevelOfEvidence, options?: AxiosRequestConfig) {
    return LevelOfEvidenceResourceApiFp(this.configuration)
      .createLevelOfEvidence(levelOfEvidence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelOfEvidenceResourceApi
   */
  public deleteLevelOfEvidence(id: number, options?: AxiosRequestConfig) {
    return LevelOfEvidenceResourceApiFp(this.configuration)
      .deleteLevelOfEvidence(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelOfEvidenceResourceApi
   */
  public getAllLevelOfEvidences(options?: AxiosRequestConfig) {
    return LevelOfEvidenceResourceApiFp(this.configuration)
      .getAllLevelOfEvidences(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelOfEvidenceResourceApi
   */
  public getLevelOfEvidence(id: number, options?: AxiosRequestConfig) {
    return LevelOfEvidenceResourceApiFp(this.configuration)
      .getLevelOfEvidence(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {LevelOfEvidence} levelOfEvidence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelOfEvidenceResourceApi
   */
  public partialUpdateLevelOfEvidence(id: number, levelOfEvidence: LevelOfEvidence, options?: AxiosRequestConfig) {
    return LevelOfEvidenceResourceApiFp(this.configuration)
      .partialUpdateLevelOfEvidence(id, levelOfEvidence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {LevelOfEvidence} levelOfEvidence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelOfEvidenceResourceApi
   */
  public updateLevelOfEvidence(id: number, levelOfEvidence: LevelOfEvidence, options?: AxiosRequestConfig) {
    return LevelOfEvidenceResourceApiFp(this.configuration)
      .updateLevelOfEvidence(id, levelOfEvidence, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LogoutResourceApi - axios parameter creator
 * @export
 */
export const LogoutResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LogoutResourceApi - functional programming interface
 * @export
 */
export const LogoutResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LogoutResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LogoutResourceApi - factory interface
 * @export
 */
export const LogoutResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LogoutResourceApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options?: any): AxiosPromise<object> {
      return localVarFp.logout(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * LogoutResourceApi - object-oriented interface
 * @export
 * @class LogoutResourceApi
 * @extends {BaseAPI}
 */
export class LogoutResourceApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogoutResourceApi
   */
  public logout(options?: AxiosRequestConfig) {
    return LogoutResourceApiFp(this.configuration)
      .logout(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NciThesaurusResourceApi - axios parameter creator
 * @export
 */
export const NciThesaurusResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {NciThesaurusCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countNciThesauruses: async (criteria: NciThesaurusCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countNciThesauruses', 'criteria', criteria);
      const localVarPath = `/api/nci-thesauruses/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NciThesaurus} nciThesaurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNciThesaurus: async (nciThesaurus: NciThesaurus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'nciThesaurus' is not null or undefined
      assertParamExists('createNciThesaurus', 'nciThesaurus', nciThesaurus);
      const localVarPath = `/api/nci-thesauruses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(nciThesaurus, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNciThesaurus: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteNciThesaurus', 'id', id);
      const localVarPath = `/api/nci-thesauruses/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NciThesaurusCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNciThesauruses: async (
      criteria: NciThesaurusCriteria,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllNciThesauruses', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllNciThesauruses', 'pageable', pageable);
      const localVarPath = `/api/nci-thesauruses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNciThesaurus: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getNciThesaurus', 'id', id);
      const localVarPath = `/api/nci-thesauruses/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {NciThesaurus} nciThesaurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateNciThesaurus: async (id: number, nciThesaurus: NciThesaurus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateNciThesaurus', 'id', id);
      // verify required parameter 'nciThesaurus' is not null or undefined
      assertParamExists('partialUpdateNciThesaurus', 'nciThesaurus', nciThesaurus);
      const localVarPath = `/api/nci-thesauruses/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(nciThesaurus, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchNciThesaurus: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchNciThesaurus', 'query', query);
      const localVarPath = `/api/nci-thesauruses/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {NciThesaurus} nciThesaurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNciThesaurus: async (id: number, nciThesaurus: NciThesaurus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateNciThesaurus', 'id', id);
      // verify required parameter 'nciThesaurus' is not null or undefined
      assertParamExists('updateNciThesaurus', 'nciThesaurus', nciThesaurus);
      const localVarPath = `/api/nci-thesauruses/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(nciThesaurus, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NciThesaurusResourceApi - functional programming interface
 * @export
 */
export const NciThesaurusResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NciThesaurusResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {NciThesaurusCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countNciThesauruses(
      criteria: NciThesaurusCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countNciThesauruses(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {NciThesaurus} nciThesaurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNciThesaurus(
      nciThesaurus: NciThesaurus,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NciThesaurus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createNciThesaurus(nciThesaurus, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNciThesaurus(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNciThesaurus(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {NciThesaurusCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllNciThesauruses(
      criteria: NciThesaurusCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NciThesaurus>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNciThesauruses(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNciThesaurus(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NciThesaurus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNciThesaurus(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {NciThesaurus} nciThesaurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateNciThesaurus(
      id: number,
      nciThesaurus: NciThesaurus,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NciThesaurus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateNciThesaurus(id, nciThesaurus, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchNciThesaurus(
      query: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NciThesaurus>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchNciThesaurus(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {NciThesaurus} nciThesaurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNciThesaurus(
      id: number,
      nciThesaurus: NciThesaurus,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NciThesaurus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateNciThesaurus(id, nciThesaurus, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NciThesaurusResourceApi - factory interface
 * @export
 */
export const NciThesaurusResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = NciThesaurusResourceApiFp(configuration);
  return {
    /**
     *
     * @param {NciThesaurusCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countNciThesauruses(criteria: NciThesaurusCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countNciThesauruses(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {NciThesaurus} nciThesaurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNciThesaurus(nciThesaurus: NciThesaurus, options?: any): AxiosPromise<NciThesaurus> {
      return localVarFp.createNciThesaurus(nciThesaurus, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNciThesaurus(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteNciThesaurus(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {NciThesaurusCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNciThesauruses(criteria: NciThesaurusCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<NciThesaurus>> {
      return localVarFp.getAllNciThesauruses(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNciThesaurus(id: number, options?: any): AxiosPromise<NciThesaurus> {
      return localVarFp.getNciThesaurus(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {NciThesaurus} nciThesaurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateNciThesaurus(id: number, nciThesaurus: NciThesaurus, options?: any): AxiosPromise<NciThesaurus> {
      return localVarFp.partialUpdateNciThesaurus(id, nciThesaurus, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchNciThesaurus(query: string, options?: any): AxiosPromise<Array<NciThesaurus>> {
      return localVarFp.searchNciThesaurus(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {NciThesaurus} nciThesaurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNciThesaurus(id: number, nciThesaurus: NciThesaurus, options?: any): AxiosPromise<NciThesaurus> {
      return localVarFp.updateNciThesaurus(id, nciThesaurus, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * NciThesaurusResourceApi - object-oriented interface
 * @export
 * @class NciThesaurusResourceApi
 * @extends {BaseAPI}
 */
export class NciThesaurusResourceApi extends BaseAPI {
  /**
   *
   * @param {NciThesaurusCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NciThesaurusResourceApi
   */
  public countNciThesauruses(criteria: NciThesaurusCriteria, options?: AxiosRequestConfig) {
    return NciThesaurusResourceApiFp(this.configuration)
      .countNciThesauruses(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NciThesaurus} nciThesaurus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NciThesaurusResourceApi
   */
  public createNciThesaurus(nciThesaurus: NciThesaurus, options?: AxiosRequestConfig) {
    return NciThesaurusResourceApiFp(this.configuration)
      .createNciThesaurus(nciThesaurus, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NciThesaurusResourceApi
   */
  public deleteNciThesaurus(id: number, options?: AxiosRequestConfig) {
    return NciThesaurusResourceApiFp(this.configuration)
      .deleteNciThesaurus(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NciThesaurusCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NciThesaurusResourceApi
   */
  public getAllNciThesauruses(criteria: NciThesaurusCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return NciThesaurusResourceApiFp(this.configuration)
      .getAllNciThesauruses(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NciThesaurusResourceApi
   */
  public getNciThesaurus(id: number, options?: AxiosRequestConfig) {
    return NciThesaurusResourceApiFp(this.configuration)
      .getNciThesaurus(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {NciThesaurus} nciThesaurus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NciThesaurusResourceApi
   */
  public partialUpdateNciThesaurus(id: number, nciThesaurus: NciThesaurus, options?: AxiosRequestConfig) {
    return NciThesaurusResourceApiFp(this.configuration)
      .partialUpdateNciThesaurus(id, nciThesaurus, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NciThesaurusResourceApi
   */
  public searchNciThesaurus(query: string, options?: AxiosRequestConfig) {
    return NciThesaurusResourceApiFp(this.configuration)
      .searchNciThesaurus(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {NciThesaurus} nciThesaurus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NciThesaurusResourceApi
   */
  public updateNciThesaurus(id: number, nciThesaurus: NciThesaurus, options?: AxiosRequestConfig) {
    return NciThesaurusResourceApiFp(this.configuration)
      .updateNciThesaurus(id, nciThesaurus, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PipelineControllerApi - axios parameter creator
 * @export
 */
export const PipelineControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNcit: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/pipeline/update-ncit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PipelineControllerApi - functional programming interface
 * @export
 */
export const PipelineControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PipelineControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNcit(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateNcit(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PipelineControllerApi - factory interface
 * @export
 */
export const PipelineControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PipelineControllerApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNcit(options?: any): AxiosPromise<void> {
      return localVarFp.updateNcit(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * PipelineControllerApi - object-oriented interface
 * @export
 * @class PipelineControllerApi
 * @extends {BaseAPI}
 */
export class PipelineControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineControllerApi
   */
  public updateNcit(options?: AxiosRequestConfig) {
    return PipelineControllerApiFp(this.configuration)
      .updateNcit(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * RuleResourceApi - axios parameter creator
 * @export
 */
export const RuleResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Rule} rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRule: async (rule: Rule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'rule' is not null or undefined
      assertParamExists('createRule', 'rule', rule);
      const localVarPath = `/api/rules`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(rule, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRule: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteRule', 'id', id);
      const localVarPath = `/api/rules/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/rules`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRule: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getRule', 'id', id);
      const localVarPath = `/api/rules/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Rule} rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateRule: async (id: number, rule: Rule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateRule', 'id', id);
      // verify required parameter 'rule' is not null or undefined
      assertParamExists('partialUpdateRule', 'rule', rule);
      const localVarPath = `/api/rules/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(rule, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Rule} rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRule: async (id: number, rule: Rule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateRule', 'id', id);
      // verify required parameter 'rule' is not null or undefined
      assertParamExists('updateRule', 'rule', rule);
      const localVarPath = `/api/rules/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(rule, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RuleResourceApi - functional programming interface
 * @export
 */
export const RuleResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RuleResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Rule} rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRule(rule: Rule, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rule>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRule(rule, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRule(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRule(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllRules(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rule>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRules(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRule(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rule>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRule(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Rule} rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateRule(
      id: number,
      rule: Rule,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rule>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateRule(id, rule, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Rule} rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRule(
      id: number,
      rule: Rule,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rule>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRule(id, rule, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RuleResourceApi - factory interface
 * @export
 */
export const RuleResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = RuleResourceApiFp(configuration);
  return {
    /**
     *
     * @param {Rule} rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRule(rule: Rule, options?: any): AxiosPromise<Rule> {
      return localVarFp.createRule(rule, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRule(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteRule(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRules(options?: any): AxiosPromise<Array<Rule>> {
      return localVarFp.getAllRules(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRule(id: number, options?: any): AxiosPromise<Rule> {
      return localVarFp.getRule(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Rule} rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateRule(id: number, rule: Rule, options?: any): AxiosPromise<Rule> {
      return localVarFp.partialUpdateRule(id, rule, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Rule} rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRule(id: number, rule: Rule, options?: any): AxiosPromise<Rule> {
      return localVarFp.updateRule(id, rule, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * RuleResourceApi - object-oriented interface
 * @export
 * @class RuleResourceApi
 * @extends {BaseAPI}
 */
export class RuleResourceApi extends BaseAPI {
  /**
   *
   * @param {Rule} rule
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RuleResourceApi
   */
  public createRule(rule: Rule, options?: AxiosRequestConfig) {
    return RuleResourceApiFp(this.configuration)
      .createRule(rule, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RuleResourceApi
   */
  public deleteRule(id: number, options?: AxiosRequestConfig) {
    return RuleResourceApiFp(this.configuration)
      .deleteRule(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RuleResourceApi
   */
  public getAllRules(options?: AxiosRequestConfig) {
    return RuleResourceApiFp(this.configuration)
      .getAllRules(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RuleResourceApi
   */
  public getRule(id: number, options?: AxiosRequestConfig) {
    return RuleResourceApiFp(this.configuration)
      .getRule(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Rule} rule
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RuleResourceApi
   */
  public partialUpdateRule(id: number, rule: Rule, options?: AxiosRequestConfig) {
    return RuleResourceApiFp(this.configuration)
      .partialUpdateRule(id, rule, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Rule} rule
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RuleResourceApi
   */
  public updateRule(id: number, rule: Rule, options?: AxiosRequestConfig) {
    return RuleResourceApiFp(this.configuration)
      .updateRule(id, rule, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SearchControllerApi - axios parameter creator
 * @export
 */
export const SearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('search', 'query', query);
      const localVarPath = `/api/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchControllerApi - functional programming interface
 * @export
 */
export const SearchControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async search(
      query: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.search(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SearchControllerApi - factory interface
 * @export
 */
export const SearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SearchControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(query: string, options?: any): AxiosPromise<SearchResultDTO> {
      return localVarFp.search(query, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SearchControllerApi - object-oriented interface
 * @export
 * @class SearchControllerApi
 * @extends {BaseAPI}
 */
export class SearchControllerApi extends BaseAPI {
  /**
   *
   * @param {string} query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchControllerApi
   */
  public search(query: string, options?: AxiosRequestConfig) {
    return SearchControllerApiFp(this.configuration)
      .search(query, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SeqRegionResourceApi - axios parameter creator
 * @export
 */
export const SeqRegionResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SeqRegion} seqRegion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSeqRegion: async (seqRegion: SeqRegion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'seqRegion' is not null or undefined
      assertParamExists('createSeqRegion', 'seqRegion', seqRegion);
      const localVarPath = `/api/seq-regions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(seqRegion, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSeqRegion: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteSeqRegion', 'id', id);
      const localVarPath = `/api/seq-regions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSeqRegions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/seq-regions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSeqRegion: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSeqRegion', 'id', id);
      const localVarPath = `/api/seq-regions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {SeqRegion} seqRegion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSeqRegion: async (id: number, seqRegion: SeqRegion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateSeqRegion', 'id', id);
      // verify required parameter 'seqRegion' is not null or undefined
      assertParamExists('partialUpdateSeqRegion', 'seqRegion', seqRegion);
      const localVarPath = `/api/seq-regions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(seqRegion, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSeqRegions: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchSeqRegions', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchSeqRegions', 'pageable', pageable);
      const localVarPath = `/api/seq-regions/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {SeqRegion} seqRegion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSeqRegion: async (id: number, seqRegion: SeqRegion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSeqRegion', 'id', id);
      // verify required parameter 'seqRegion' is not null or undefined
      assertParamExists('updateSeqRegion', 'seqRegion', seqRegion);
      const localVarPath = `/api/seq-regions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(seqRegion, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SeqRegionResourceApi - functional programming interface
 * @export
 */
export const SeqRegionResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SeqRegionResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SeqRegion} seqRegion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSeqRegion(
      seqRegion: SeqRegion,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeqRegion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSeqRegion(seqRegion, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSeqRegion(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSeqRegion(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSeqRegions(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SeqRegion>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSeqRegions(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSeqRegion(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeqRegion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSeqRegion(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {SeqRegion} seqRegion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateSeqRegion(
      id: number,
      seqRegion: SeqRegion,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeqRegion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSeqRegion(id, seqRegion, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchSeqRegions(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SeqRegion>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchSeqRegions(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {SeqRegion} seqRegion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSeqRegion(
      id: number,
      seqRegion: SeqRegion,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeqRegion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSeqRegion(id, seqRegion, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SeqRegionResourceApi - factory interface
 * @export
 */
export const SeqRegionResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SeqRegionResourceApiFp(configuration);
  return {
    /**
     *
     * @param {SeqRegion} seqRegion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSeqRegion(seqRegion: SeqRegion, options?: any): AxiosPromise<SeqRegion> {
      return localVarFp.createSeqRegion(seqRegion, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSeqRegion(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteSeqRegion(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSeqRegions(options?: any): AxiosPromise<Array<SeqRegion>> {
      return localVarFp.getAllSeqRegions(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSeqRegion(id: number, options?: any): AxiosPromise<SeqRegion> {
      return localVarFp.getSeqRegion(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {SeqRegion} seqRegion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSeqRegion(id: number, seqRegion: SeqRegion, options?: any): AxiosPromise<SeqRegion> {
      return localVarFp.partialUpdateSeqRegion(id, seqRegion, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSeqRegions(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<SeqRegion>> {
      return localVarFp.searchSeqRegions(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {SeqRegion} seqRegion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSeqRegion(id: number, seqRegion: SeqRegion, options?: any): AxiosPromise<SeqRegion> {
      return localVarFp.updateSeqRegion(id, seqRegion, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SeqRegionResourceApi - object-oriented interface
 * @export
 * @class SeqRegionResourceApi
 * @extends {BaseAPI}
 */
export class SeqRegionResourceApi extends BaseAPI {
  /**
   *
   * @param {SeqRegion} seqRegion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SeqRegionResourceApi
   */
  public createSeqRegion(seqRegion: SeqRegion, options?: AxiosRequestConfig) {
    return SeqRegionResourceApiFp(this.configuration)
      .createSeqRegion(seqRegion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SeqRegionResourceApi
   */
  public deleteSeqRegion(id: number, options?: AxiosRequestConfig) {
    return SeqRegionResourceApiFp(this.configuration)
      .deleteSeqRegion(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SeqRegionResourceApi
   */
  public getAllSeqRegions(options?: AxiosRequestConfig) {
    return SeqRegionResourceApiFp(this.configuration)
      .getAllSeqRegions(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SeqRegionResourceApi
   */
  public getSeqRegion(id: number, options?: AxiosRequestConfig) {
    return SeqRegionResourceApiFp(this.configuration)
      .getSeqRegion(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {SeqRegion} seqRegion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SeqRegionResourceApi
   */
  public partialUpdateSeqRegion(id: number, seqRegion: SeqRegion, options?: AxiosRequestConfig) {
    return SeqRegionResourceApiFp(this.configuration)
      .partialUpdateSeqRegion(id, seqRegion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SeqRegionResourceApi
   */
  public searchSeqRegions(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return SeqRegionResourceApiFp(this.configuration)
      .searchSeqRegions(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {SeqRegion} seqRegion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SeqRegionResourceApi
   */
  public updateSeqRegion(id: number, seqRegion: SeqRegion, options?: AxiosRequestConfig) {
    return SeqRegionResourceApiFp(this.configuration)
      .updateSeqRegion(id, seqRegion, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SequenceControllerApi - axios parameter creator
 * @export
 */
export const SequenceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {'GRCh37' | 'GRCh38'} referenceGenome
     * @param {number} entrezGeneId
     * @param {'PROTEIN' | 'CDNA' | 'GENOMIC'} [sequenceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCanonicalSequence: async (
      referenceGenome: 'GRCh37' | 'GRCh38',
      entrezGeneId: number,
      sequenceType?: 'PROTEIN' | 'CDNA' | 'GENOMIC',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'referenceGenome' is not null or undefined
      assertParamExists('findCanonicalSequence', 'referenceGenome', referenceGenome);
      // verify required parameter 'entrezGeneId' is not null or undefined
      assertParamExists('findCanonicalSequence', 'entrezGeneId', entrezGeneId);
      const localVarPath = `/api/find-canonical-sequences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      if (entrezGeneId !== undefined) {
        localVarQueryParameter['entrezGeneId'] = entrezGeneId;
      }

      if (sequenceType !== undefined) {
        localVarQueryParameter['sequenceType'] = sequenceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {'GRCh37' | 'GRCh38'} referenceGenome
     * @param {Array<number>} requestBody
     * @param {'PROTEIN' | 'CDNA' | 'GENOMIC'} [sequenceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCanonicalSequences: async (
      referenceGenome: 'GRCh37' | 'GRCh38',
      requestBody: Array<number>,
      sequenceType?: 'PROTEIN' | 'CDNA' | 'GENOMIC',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'referenceGenome' is not null or undefined
      assertParamExists('findCanonicalSequences', 'referenceGenome', referenceGenome);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('findCanonicalSequences', 'requestBody', requestBody);
      const localVarPath = `/api/find-canonical-sequences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      if (sequenceType !== undefined) {
        localVarQueryParameter['sequenceType'] = sequenceType;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SequenceControllerApi - functional programming interface
 * @export
 */
export const SequenceControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SequenceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {'GRCh37' | 'GRCh38'} referenceGenome
     * @param {number} entrezGeneId
     * @param {'PROTEIN' | 'CDNA' | 'GENOMIC'} [sequenceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCanonicalSequence(
      referenceGenome: 'GRCh37' | 'GRCh38',
      entrezGeneId: number,
      sequenceType?: 'PROTEIN' | 'CDNA' | 'GENOMIC',
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sequence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findCanonicalSequence(referenceGenome, entrezGeneId, sequenceType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {'GRCh37' | 'GRCh38'} referenceGenome
     * @param {Array<number>} requestBody
     * @param {'PROTEIN' | 'CDNA' | 'GENOMIC'} [sequenceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCanonicalSequences(
      referenceGenome: 'GRCh37' | 'GRCh38',
      requestBody: Array<number>,
      sequenceType?: 'PROTEIN' | 'CDNA' | 'GENOMIC',
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sequence>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findCanonicalSequences(referenceGenome, requestBody, sequenceType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SequenceControllerApi - factory interface
 * @export
 */
export const SequenceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SequenceControllerApiFp(configuration);
  return {
    /**
     *
     * @param {'GRCh37' | 'GRCh38'} referenceGenome
     * @param {number} entrezGeneId
     * @param {'PROTEIN' | 'CDNA' | 'GENOMIC'} [sequenceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCanonicalSequence(
      referenceGenome: 'GRCh37' | 'GRCh38',
      entrezGeneId: number,
      sequenceType?: 'PROTEIN' | 'CDNA' | 'GENOMIC',
      options?: any,
    ): AxiosPromise<Sequence> {
      return localVarFp
        .findCanonicalSequence(referenceGenome, entrezGeneId, sequenceType, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {'GRCh37' | 'GRCh38'} referenceGenome
     * @param {Array<number>} requestBody
     * @param {'PROTEIN' | 'CDNA' | 'GENOMIC'} [sequenceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCanonicalSequences(
      referenceGenome: 'GRCh37' | 'GRCh38',
      requestBody: Array<number>,
      sequenceType?: 'PROTEIN' | 'CDNA' | 'GENOMIC',
      options?: any,
    ): AxiosPromise<Array<Sequence>> {
      return localVarFp
        .findCanonicalSequences(referenceGenome, requestBody, sequenceType, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SequenceControllerApi - object-oriented interface
 * @export
 * @class SequenceControllerApi
 * @extends {BaseAPI}
 */
export class SequenceControllerApi extends BaseAPI {
  /**
   *
   * @param {'GRCh37' | 'GRCh38'} referenceGenome
   * @param {number} entrezGeneId
   * @param {'PROTEIN' | 'CDNA' | 'GENOMIC'} [sequenceType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceControllerApi
   */
  public findCanonicalSequence(
    referenceGenome: 'GRCh37' | 'GRCh38',
    entrezGeneId: number,
    sequenceType?: 'PROTEIN' | 'CDNA' | 'GENOMIC',
    options?: AxiosRequestConfig,
  ) {
    return SequenceControllerApiFp(this.configuration)
      .findCanonicalSequence(referenceGenome, entrezGeneId, sequenceType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {'GRCh37' | 'GRCh38'} referenceGenome
   * @param {Array<number>} requestBody
   * @param {'PROTEIN' | 'CDNA' | 'GENOMIC'} [sequenceType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceControllerApi
   */
  public findCanonicalSequences(
    referenceGenome: 'GRCh37' | 'GRCh38',
    requestBody: Array<number>,
    sequenceType?: 'PROTEIN' | 'CDNA' | 'GENOMIC',
    options?: AxiosRequestConfig,
  ) {
    return SequenceControllerApiFp(this.configuration)
      .findCanonicalSequences(referenceGenome, requestBody, sequenceType, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SequenceResourceApi - axios parameter creator
 * @export
 */
export const SequenceResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SequenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countSequences: async (criteria: SequenceCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countSequences', 'criteria', criteria);
      const localVarPath = `/api/sequences/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Sequence} sequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSequence: async (sequence: Sequence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'sequence' is not null or undefined
      assertParamExists('createSequence', 'sequence', sequence);
      const localVarPath = `/api/sequences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sequence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSequence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteSequence', 'id', id);
      const localVarPath = `/api/sequences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SequenceCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSequences: async (criteria: SequenceCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllSequences', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllSequences', 'pageable', pageable);
      const localVarPath = `/api/sequences`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSequence: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSequence', 'id', id);
      const localVarPath = `/api/sequences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Sequence} sequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSequence: async (id: number, sequence: Sequence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateSequence', 'id', id);
      // verify required parameter 'sequence' is not null or undefined
      assertParamExists('partialUpdateSequence', 'sequence', sequence);
      const localVarPath = `/api/sequences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sequence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSequences: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchSequences', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchSequences', 'pageable', pageable);
      const localVarPath = `/api/sequences/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Sequence} sequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSequence: async (id: number, sequence: Sequence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSequence', 'id', id);
      // verify required parameter 'sequence' is not null or undefined
      assertParamExists('updateSequence', 'sequence', sequence);
      const localVarPath = `/api/sequences/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sequence, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SequenceResourceApi - functional programming interface
 * @export
 */
export const SequenceResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SequenceResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SequenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countSequences(
      criteria: SequenceCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countSequences(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Sequence} sequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSequence(
      sequence: Sequence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sequence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSequence(sequence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSequence(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSequence(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SequenceCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSequences(
      criteria: SequenceCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sequence>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSequences(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSequence(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sequence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSequence(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Sequence} sequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateSequence(
      id: number,
      sequence: Sequence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sequence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSequence(id, sequence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchSequences(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sequence>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchSequences(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Sequence} sequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSequence(
      id: number,
      sequence: Sequence,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sequence>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSequence(id, sequence, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SequenceResourceApi - factory interface
 * @export
 */
export const SequenceResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SequenceResourceApiFp(configuration);
  return {
    /**
     *
     * @param {SequenceCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countSequences(criteria: SequenceCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countSequences(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Sequence} sequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSequence(sequence: Sequence, options?: any): AxiosPromise<Sequence> {
      return localVarFp.createSequence(sequence, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSequence(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteSequence(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {SequenceCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSequences(criteria: SequenceCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<Sequence>> {
      return localVarFp.getAllSequences(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSequence(id: number, options?: any): AxiosPromise<Sequence> {
      return localVarFp.getSequence(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Sequence} sequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSequence(id: number, sequence: Sequence, options?: any): AxiosPromise<Sequence> {
      return localVarFp.partialUpdateSequence(id, sequence, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSequences(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<Sequence>> {
      return localVarFp.searchSequences(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Sequence} sequence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSequence(id: number, sequence: Sequence, options?: any): AxiosPromise<Sequence> {
      return localVarFp.updateSequence(id, sequence, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SequenceResourceApi - object-oriented interface
 * @export
 * @class SequenceResourceApi
 * @extends {BaseAPI}
 */
export class SequenceResourceApi extends BaseAPI {
  /**
   *
   * @param {SequenceCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceResourceApi
   */
  public countSequences(criteria: SequenceCriteria, options?: AxiosRequestConfig) {
    return SequenceResourceApiFp(this.configuration)
      .countSequences(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Sequence} sequence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceResourceApi
   */
  public createSequence(sequence: Sequence, options?: AxiosRequestConfig) {
    return SequenceResourceApiFp(this.configuration)
      .createSequence(sequence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceResourceApi
   */
  public deleteSequence(id: number, options?: AxiosRequestConfig) {
    return SequenceResourceApiFp(this.configuration)
      .deleteSequence(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SequenceCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceResourceApi
   */
  public getAllSequences(criteria: SequenceCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return SequenceResourceApiFp(this.configuration)
      .getAllSequences(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceResourceApi
   */
  public getSequence(id: number, options?: AxiosRequestConfig) {
    return SequenceResourceApiFp(this.configuration)
      .getSequence(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Sequence} sequence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceResourceApi
   */
  public partialUpdateSequence(id: number, sequence: Sequence, options?: AxiosRequestConfig) {
    return SequenceResourceApiFp(this.configuration)
      .partialUpdateSequence(id, sequence, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceResourceApi
   */
  public searchSequences(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return SequenceResourceApiFp(this.configuration)
      .searchSequences(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Sequence} sequence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SequenceResourceApi
   */
  public updateSequence(id: number, sequence: Sequence, options?: AxiosRequestConfig) {
    return SequenceResourceApiFp(this.configuration)
      .updateSequence(id, sequence, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SpecimenTypeResourceApi - axios parameter creator
 * @export
 */
export const SpecimenTypeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SpecimenType} specimenType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSpecimenType: async (specimenType: SpecimenType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'specimenType' is not null or undefined
      assertParamExists('createSpecimenType', 'specimenType', specimenType);
      const localVarPath = `/api/specimen-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(specimenType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSpecimenType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteSpecimenType', 'id', id);
      const localVarPath = `/api/specimen-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSpecimenTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/specimen-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecimenType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSpecimenType', 'id', id);
      const localVarPath = `/api/specimen-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {SpecimenType} specimenType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSpecimenType: async (id: number, specimenType: SpecimenType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateSpecimenType', 'id', id);
      // verify required parameter 'specimenType' is not null or undefined
      assertParamExists('partialUpdateSpecimenType', 'specimenType', specimenType);
      const localVarPath = `/api/specimen-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(specimenType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {SpecimenType} specimenType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSpecimenType: async (id: number, specimenType: SpecimenType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSpecimenType', 'id', id);
      // verify required parameter 'specimenType' is not null or undefined
      assertParamExists('updateSpecimenType', 'specimenType', specimenType);
      const localVarPath = `/api/specimen-types/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(specimenType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpecimenTypeResourceApi - functional programming interface
 * @export
 */
export const SpecimenTypeResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SpecimenTypeResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SpecimenType} specimenType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSpecimenType(
      specimenType: SpecimenType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecimenType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSpecimenType(specimenType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSpecimenType(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSpecimenType(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSpecimenTypes(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecimenType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSpecimenTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSpecimenType(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecimenType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecimenType(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {SpecimenType} specimenType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateSpecimenType(
      id: number,
      specimenType: SpecimenType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecimenType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSpecimenType(id, specimenType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {SpecimenType} specimenType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSpecimenType(
      id: number,
      specimenType: SpecimenType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecimenType>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSpecimenType(id, specimenType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SpecimenTypeResourceApi - factory interface
 * @export
 */
export const SpecimenTypeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SpecimenTypeResourceApiFp(configuration);
  return {
    /**
     *
     * @param {SpecimenType} specimenType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSpecimenType(specimenType: SpecimenType, options?: any): AxiosPromise<SpecimenType> {
      return localVarFp.createSpecimenType(specimenType, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSpecimenType(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteSpecimenType(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSpecimenTypes(options?: any): AxiosPromise<Array<SpecimenType>> {
      return localVarFp.getAllSpecimenTypes(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecimenType(id: number, options?: any): AxiosPromise<SpecimenType> {
      return localVarFp.getSpecimenType(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {SpecimenType} specimenType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSpecimenType(id: number, specimenType: SpecimenType, options?: any): AxiosPromise<SpecimenType> {
      return localVarFp.partialUpdateSpecimenType(id, specimenType, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {SpecimenType} specimenType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSpecimenType(id: number, specimenType: SpecimenType, options?: any): AxiosPromise<SpecimenType> {
      return localVarFp.updateSpecimenType(id, specimenType, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SpecimenTypeResourceApi - object-oriented interface
 * @export
 * @class SpecimenTypeResourceApi
 * @extends {BaseAPI}
 */
export class SpecimenTypeResourceApi extends BaseAPI {
  /**
   *
   * @param {SpecimenType} specimenType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecimenTypeResourceApi
   */
  public createSpecimenType(specimenType: SpecimenType, options?: AxiosRequestConfig) {
    return SpecimenTypeResourceApiFp(this.configuration)
      .createSpecimenType(specimenType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecimenTypeResourceApi
   */
  public deleteSpecimenType(id: number, options?: AxiosRequestConfig) {
    return SpecimenTypeResourceApiFp(this.configuration)
      .deleteSpecimenType(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecimenTypeResourceApi
   */
  public getAllSpecimenTypes(options?: AxiosRequestConfig) {
    return SpecimenTypeResourceApiFp(this.configuration)
      .getAllSpecimenTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecimenTypeResourceApi
   */
  public getSpecimenType(id: number, options?: AxiosRequestConfig) {
    return SpecimenTypeResourceApiFp(this.configuration)
      .getSpecimenType(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {SpecimenType} specimenType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecimenTypeResourceApi
   */
  public partialUpdateSpecimenType(id: number, specimenType: SpecimenType, options?: AxiosRequestConfig) {
    return SpecimenTypeResourceApiFp(this.configuration)
      .partialUpdateSpecimenType(id, specimenType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {SpecimenType} specimenType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecimenTypeResourceApi
   */
  public updateSpecimenType(id: number, specimenType: SpecimenType, options?: AxiosRequestConfig) {
    return SpecimenTypeResourceApiFp(this.configuration)
      .updateSpecimenType(id, specimenType, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SynonymResourceApi - axios parameter creator
 * @export
 */
export const SynonymResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SynonymCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countSynonyms: async (criteria: SynonymCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countSynonyms', 'criteria', criteria);
      const localVarPath = `/api/synonyms/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Synonym} synonym
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSynonym: async (synonym: Synonym, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'synonym' is not null or undefined
      assertParamExists('createSynonym', 'synonym', synonym);
      const localVarPath = `/api/synonyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(synonym, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSynonym: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteSynonym', 'id', id);
      const localVarPath = `/api/synonyms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SynonymCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSynonyms: async (criteria: SynonymCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllSynonyms', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllSynonyms', 'pageable', pageable);
      const localVarPath = `/api/synonyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSynonym: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSynonym', 'id', id);
      const localVarPath = `/api/synonyms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Synonym} synonym
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSynonym: async (id: number, synonym: Synonym, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateSynonym', 'id', id);
      // verify required parameter 'synonym' is not null or undefined
      assertParamExists('partialUpdateSynonym', 'synonym', synonym);
      const localVarPath = `/api/synonyms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(synonym, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSynonymss: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchSynonymss', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchSynonymss', 'pageable', pageable);
      const localVarPath = `/api/synonyms/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Synonym} synonym
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSynonym: async (id: number, synonym: Synonym, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSynonym', 'id', id);
      // verify required parameter 'synonym' is not null or undefined
      assertParamExists('updateSynonym', 'synonym', synonym);
      const localVarPath = `/api/synonyms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(synonym, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SynonymResourceApi - functional programming interface
 * @export
 */
export const SynonymResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SynonymResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SynonymCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countSynonyms(
      criteria: SynonymCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countSynonyms(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Synonym} synonym
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSynonym(
      synonym: Synonym,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Synonym>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSynonym(synonym, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSynonym(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSynonym(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SynonymCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSynonyms(
      criteria: SynonymCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Synonym>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSynonyms(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSynonym(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Synonym>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSynonym(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Synonym} synonym
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateSynonym(
      id: number,
      synonym: Synonym,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Synonym>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSynonym(id, synonym, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchSynonymss(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Synonym>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchSynonymss(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Synonym} synonym
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSynonym(
      id: number,
      synonym: Synonym,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Synonym>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSynonym(id, synonym, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SynonymResourceApi - factory interface
 * @export
 */
export const SynonymResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SynonymResourceApiFp(configuration);
  return {
    /**
     *
     * @param {SynonymCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countSynonyms(criteria: SynonymCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countSynonyms(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {Synonym} synonym
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSynonym(synonym: Synonym, options?: any): AxiosPromise<Synonym> {
      return localVarFp.createSynonym(synonym, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSynonym(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteSynonym(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {SynonymCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSynonyms(criteria: SynonymCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<Synonym>> {
      return localVarFp.getAllSynonyms(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSynonym(id: number, options?: any): AxiosPromise<Synonym> {
      return localVarFp.getSynonym(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Synonym} synonym
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSynonym(id: number, synonym: Synonym, options?: any): AxiosPromise<Synonym> {
      return localVarFp.partialUpdateSynonym(id, synonym, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSynonymss(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<Synonym>> {
      return localVarFp.searchSynonymss(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Synonym} synonym
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSynonym(id: number, synonym: Synonym, options?: any): AxiosPromise<Synonym> {
      return localVarFp.updateSynonym(id, synonym, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SynonymResourceApi - object-oriented interface
 * @export
 * @class SynonymResourceApi
 * @extends {BaseAPI}
 */
export class SynonymResourceApi extends BaseAPI {
  /**
   *
   * @param {SynonymCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymResourceApi
   */
  public countSynonyms(criteria: SynonymCriteria, options?: AxiosRequestConfig) {
    return SynonymResourceApiFp(this.configuration)
      .countSynonyms(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Synonym} synonym
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymResourceApi
   */
  public createSynonym(synonym: Synonym, options?: AxiosRequestConfig) {
    return SynonymResourceApiFp(this.configuration)
      .createSynonym(synonym, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymResourceApi
   */
  public deleteSynonym(id: number, options?: AxiosRequestConfig) {
    return SynonymResourceApiFp(this.configuration)
      .deleteSynonym(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SynonymCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymResourceApi
   */
  public getAllSynonyms(criteria: SynonymCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return SynonymResourceApiFp(this.configuration)
      .getAllSynonyms(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymResourceApi
   */
  public getSynonym(id: number, options?: AxiosRequestConfig) {
    return SynonymResourceApiFp(this.configuration)
      .getSynonym(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Synonym} synonym
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymResourceApi
   */
  public partialUpdateSynonym(id: number, synonym: Synonym, options?: AxiosRequestConfig) {
    return SynonymResourceApiFp(this.configuration)
      .partialUpdateSynonym(id, synonym, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymResourceApi
   */
  public searchSynonymss(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return SynonymResourceApiFp(this.configuration)
      .searchSynonymss(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {Synonym} synonym
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymResourceApi
   */
  public updateSynonym(id: number, synonym: Synonym, options?: AxiosRequestConfig) {
    return SynonymResourceApiFp(this.configuration)
      .updateSynonym(id, synonym, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TranscriptControllerApi - axios parameter creator
 * @export
 */
export const TranscriptControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AddTranscriptBody} addTranscriptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTranscript: async (addTranscriptBody: AddTranscriptBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'addTranscriptBody' is not null or undefined
      assertParamExists('addTranscript', 'addTranscriptBody', addTranscriptBody);
      const localVarPath = `/api/add-transcript`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(addTranscriptBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} hugoSymbol
     * @param {TranscriptComparisonVM} transcriptComparisonVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    compareTranscript: async (
      hugoSymbol: string,
      transcriptComparisonVM: TranscriptComparisonVM,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hugoSymbol' is not null or undefined
      assertParamExists('compareTranscript', 'hugoSymbol', hugoSymbol);
      // verify required parameter 'transcriptComparisonVM' is not null or undefined
      assertParamExists('compareTranscript', 'transcriptComparisonVM', transcriptComparisonVM);
      const localVarPath = `/api/compare-transcript/{hugoSymbol}`.replace(`{${'hugoSymbol'}}`, encodeURIComponent(String(hugoSymbol)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(transcriptComparisonVM, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {'GRCh37' | 'GRCh38'} referenceGenome
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findTranscriptsByEnsemblIds: async (
      referenceGenome: 'GRCh37' | 'GRCh38',
      requestBody: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'referenceGenome' is not null or undefined
      assertParamExists('findTranscriptsByEnsemblIds', 'referenceGenome', referenceGenome);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('findTranscriptsByEnsemblIds', 'requestBody', requestBody);
      const localVarPath = `/api/find-transcripts-by-ensembl-ids`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (referenceGenome !== undefined) {
        localVarQueryParameter['referenceGenome'] = referenceGenome;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} hugoSymbol
     * @param {MatchTranscriptVM} matchTranscriptVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matchTranscript: async (
      hugoSymbol: string,
      matchTranscriptVM: MatchTranscriptVM,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hugoSymbol' is not null or undefined
      assertParamExists('matchTranscript', 'hugoSymbol', hugoSymbol);
      // verify required parameter 'matchTranscriptVM' is not null or undefined
      assertParamExists('matchTranscript', 'matchTranscriptVM', matchTranscriptVM);
      const localVarPath = `/api/match-transcript/{hugoSymbol}`.replace(`{${'hugoSymbol'}}`, encodeURIComponent(String(hugoSymbol)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(matchTranscriptVM, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} hugoSymbol
     * @param {number} proteinPosition
     * @param {string} curatedResidue
     * @param {string} grch37Transcript
     * @param {string} grch38Transcript
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestVariant: async (
      hugoSymbol: string,
      proteinPosition: number,
      curatedResidue: string,
      grch37Transcript: string,
      grch38Transcript: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hugoSymbol' is not null or undefined
      assertParamExists('suggestVariant', 'hugoSymbol', hugoSymbol);
      // verify required parameter 'proteinPosition' is not null or undefined
      assertParamExists('suggestVariant', 'proteinPosition', proteinPosition);
      // verify required parameter 'curatedResidue' is not null or undefined
      assertParamExists('suggestVariant', 'curatedResidue', curatedResidue);
      // verify required parameter 'grch37Transcript' is not null or undefined
      assertParamExists('suggestVariant', 'grch37Transcript', grch37Transcript);
      // verify required parameter 'grch38Transcript' is not null or undefined
      assertParamExists('suggestVariant', 'grch38Transcript', grch38Transcript);
      const localVarPath = `/api/suggest-variant/{hugoSymbol}`.replace(`{${'hugoSymbol'}}`, encodeURIComponent(String(hugoSymbol)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (proteinPosition !== undefined) {
        localVarQueryParameter['proteinPosition'] = proteinPosition;
      }

      if (curatedResidue !== undefined) {
        localVarQueryParameter['curatedResidue'] = curatedResidue;
      }

      if (grch37Transcript !== undefined) {
        localVarQueryParameter['grch37Transcript'] = grch37Transcript;
      }

      if (grch38Transcript !== undefined) {
        localVarQueryParameter['grch38Transcript'] = grch38Transcript;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TranscriptControllerApi - functional programming interface
 * @export
 */
export const TranscriptControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TranscriptControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AddTranscriptBody} addTranscriptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addTranscript(
      addTranscriptBody: AddTranscriptBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranscriptDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addTranscript(addTranscriptBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} hugoSymbol
     * @param {TranscriptComparisonVM} transcriptComparisonVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async compareTranscript(
      hugoSymbol: string,
      transcriptComparisonVM: TranscriptComparisonVM,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranscriptComparisonResultVM>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.compareTranscript(hugoSymbol, transcriptComparisonVM, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {'GRCh37' | 'GRCh38'} referenceGenome
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findTranscriptsByEnsemblIds(
      referenceGenome: 'GRCh37' | 'GRCh38',
      requestBody: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TranscriptDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findTranscriptsByEnsemblIds(referenceGenome, requestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} hugoSymbol
     * @param {MatchTranscriptVM} matchTranscriptVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matchTranscript(
      hugoSymbol: string,
      matchTranscriptVM: MatchTranscriptVM,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranscriptMatchResultVM>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.matchTranscript(hugoSymbol, matchTranscriptVM, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} hugoSymbol
     * @param {number} proteinPosition
     * @param {string} curatedResidue
     * @param {string} grch37Transcript
     * @param {string} grch38Transcript
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suggestVariant(
      hugoSymbol: string,
      proteinPosition: number,
      curatedResidue: string,
      grch37Transcript: string,
      grch38Transcript: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllReferenceTranscriptSuggestionVM>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.suggestVariant(
        hugoSymbol,
        proteinPosition,
        curatedResidue,
        grch37Transcript,
        grch38Transcript,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TranscriptControllerApi - factory interface
 * @export
 */
export const TranscriptControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TranscriptControllerApiFp(configuration);
  return {
    /**
     *
     * @param {AddTranscriptBody} addTranscriptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTranscript(addTranscriptBody: AddTranscriptBody, options?: any): AxiosPromise<TranscriptDTO> {
      return localVarFp.addTranscript(addTranscriptBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} hugoSymbol
     * @param {TranscriptComparisonVM} transcriptComparisonVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    compareTranscript(
      hugoSymbol: string,
      transcriptComparisonVM: TranscriptComparisonVM,
      options?: any,
    ): AxiosPromise<TranscriptComparisonResultVM> {
      return localVarFp.compareTranscript(hugoSymbol, transcriptComparisonVM, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {'GRCh37' | 'GRCh38'} referenceGenome
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findTranscriptsByEnsemblIds(
      referenceGenome: 'GRCh37' | 'GRCh38',
      requestBody: Array<string>,
      options?: any,
    ): AxiosPromise<Array<TranscriptDTO>> {
      return localVarFp.findTranscriptsByEnsemblIds(referenceGenome, requestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} hugoSymbol
     * @param {MatchTranscriptVM} matchTranscriptVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matchTranscript(hugoSymbol: string, matchTranscriptVM: MatchTranscriptVM, options?: any): AxiosPromise<TranscriptMatchResultVM> {
      return localVarFp.matchTranscript(hugoSymbol, matchTranscriptVM, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} hugoSymbol
     * @param {number} proteinPosition
     * @param {string} curatedResidue
     * @param {string} grch37Transcript
     * @param {string} grch38Transcript
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestVariant(
      hugoSymbol: string,
      proteinPosition: number,
      curatedResidue: string,
      grch37Transcript: string,
      grch38Transcript: string,
      options?: any,
    ): AxiosPromise<AllReferenceTranscriptSuggestionVM> {
      return localVarFp
        .suggestVariant(hugoSymbol, proteinPosition, curatedResidue, grch37Transcript, grch38Transcript, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * TranscriptControllerApi - object-oriented interface
 * @export
 * @class TranscriptControllerApi
 * @extends {BaseAPI}
 */
export class TranscriptControllerApi extends BaseAPI {
  /**
   *
   * @param {AddTranscriptBody} addTranscriptBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptControllerApi
   */
  public addTranscript(addTranscriptBody: AddTranscriptBody, options?: AxiosRequestConfig) {
    return TranscriptControllerApiFp(this.configuration)
      .addTranscript(addTranscriptBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} hugoSymbol
   * @param {TranscriptComparisonVM} transcriptComparisonVM
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptControllerApi
   */
  public compareTranscript(hugoSymbol: string, transcriptComparisonVM: TranscriptComparisonVM, options?: AxiosRequestConfig) {
    return TranscriptControllerApiFp(this.configuration)
      .compareTranscript(hugoSymbol, transcriptComparisonVM, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {'GRCh37' | 'GRCh38'} referenceGenome
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptControllerApi
   */
  public findTranscriptsByEnsemblIds(referenceGenome: 'GRCh37' | 'GRCh38', requestBody: Array<string>, options?: AxiosRequestConfig) {
    return TranscriptControllerApiFp(this.configuration)
      .findTranscriptsByEnsemblIds(referenceGenome, requestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} hugoSymbol
   * @param {MatchTranscriptVM} matchTranscriptVM
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptControllerApi
   */
  public matchTranscript(hugoSymbol: string, matchTranscriptVM: MatchTranscriptVM, options?: AxiosRequestConfig) {
    return TranscriptControllerApiFp(this.configuration)
      .matchTranscript(hugoSymbol, matchTranscriptVM, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} hugoSymbol
   * @param {number} proteinPosition
   * @param {string} curatedResidue
   * @param {string} grch37Transcript
   * @param {string} grch38Transcript
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptControllerApi
   */
  public suggestVariant(
    hugoSymbol: string,
    proteinPosition: number,
    curatedResidue: string,
    grch37Transcript: string,
    grch38Transcript: string,
    options?: AxiosRequestConfig,
  ) {
    return TranscriptControllerApiFp(this.configuration)
      .suggestVariant(hugoSymbol, proteinPosition, curatedResidue, grch37Transcript, grch38Transcript, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TranscriptResourceApi - axios parameter creator
 * @export
 */
export const TranscriptResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alignTranscripts: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('alignTranscripts', 'requestBody', requestBody);
      const localVarPath = `/api/transcripts/align`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TranscriptCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countTranscripts: async (criteria: TranscriptCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('countTranscripts', 'criteria', criteria);
      const localVarPath = `/api/transcripts/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TranscriptDTO} transcriptDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTranscript: async (transcriptDTO: TranscriptDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transcriptDTO' is not null or undefined
      assertParamExists('createTranscript', 'transcriptDTO', transcriptDTO);
      const localVarPath = `/api/transcripts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(transcriptDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTranscript: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteTranscript', 'id', id);
      const localVarPath = `/api/transcripts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TranscriptCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTranscripts: async (criteria: TranscriptCriteria, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'criteria' is not null or undefined
      assertParamExists('getAllTranscripts', 'criteria', criteria);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('getAllTranscripts', 'pageable', pageable);
      const localVarPath = `/api/transcripts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (criteria !== undefined) {
        localVarQueryParameter['criteria'] = criteria;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTranscript: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getTranscript', 'id', id);
      const localVarPath = `/api/transcripts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {TranscriptDTO} transcriptDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateTranscript: async (id: number, transcriptDTO: TranscriptDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateTranscript', 'id', id);
      // verify required parameter 'transcriptDTO' is not null or undefined
      assertParamExists('partialUpdateTranscript', 'transcriptDTO', transcriptDTO);
      const localVarPath = `/api/transcripts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(transcriptDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchTranscripts: async (query: string, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchTranscripts', 'query', query);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists('searchTranscripts', 'pageable', pageable);
      const localVarPath = `/api/transcripts/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (pageable !== undefined) {
        localVarQueryParameter['pageable'] = pageable;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {TranscriptDTO} transcriptDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTranscript: async (id: number, transcriptDTO: TranscriptDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateTranscript', 'id', id);
      // verify required parameter 'transcriptDTO' is not null or undefined
      assertParamExists('updateTranscript', 'transcriptDTO', transcriptDTO);
      const localVarPath = `/api/transcripts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(transcriptDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TranscriptResourceApi - functional programming interface
 * @export
 */
export const TranscriptResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TranscriptResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async alignTranscripts(
      requestBody: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClustalOResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.alignTranscripts(requestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {TranscriptCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countTranscripts(
      criteria: TranscriptCriteria,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countTranscripts(criteria, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {TranscriptDTO} transcriptDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTranscript(
      transcriptDTO: TranscriptDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranscriptDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTranscript(transcriptDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTranscript(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTranscript(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {TranscriptCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllTranscripts(
      criteria: TranscriptCriteria,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TranscriptDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTranscripts(criteria, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTranscript(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranscriptDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTranscript(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {TranscriptDTO} transcriptDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateTranscript(
      id: number,
      transcriptDTO: TranscriptDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranscriptDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateTranscript(id, transcriptDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchTranscripts(
      query: string,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TranscriptDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchTranscripts(query, pageable, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {TranscriptDTO} transcriptDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTranscript(
      id: number,
      transcriptDTO: TranscriptDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranscriptDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTranscript(id, transcriptDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TranscriptResourceApi - factory interface
 * @export
 */
export const TranscriptResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TranscriptResourceApiFp(configuration);
  return {
    /**
     *
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alignTranscripts(requestBody: Array<number>, options?: any): AxiosPromise<ClustalOResp> {
      return localVarFp.alignTranscripts(requestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {TranscriptCriteria} criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countTranscripts(criteria: TranscriptCriteria, options?: any): AxiosPromise<number> {
      return localVarFp.countTranscripts(criteria, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {TranscriptDTO} transcriptDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTranscript(transcriptDTO: TranscriptDTO, options?: any): AxiosPromise<TranscriptDTO> {
      return localVarFp.createTranscript(transcriptDTO, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTranscript(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteTranscript(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {TranscriptCriteria} criteria
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTranscripts(criteria: TranscriptCriteria, pageable: Pageable, options?: any): AxiosPromise<Array<TranscriptDTO>> {
      return localVarFp.getAllTranscripts(criteria, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTranscript(id: number, options?: any): AxiosPromise<TranscriptDTO> {
      return localVarFp.getTranscript(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {TranscriptDTO} transcriptDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateTranscript(id: number, transcriptDTO: TranscriptDTO, options?: any): AxiosPromise<TranscriptDTO> {
      return localVarFp.partialUpdateTranscript(id, transcriptDTO, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} query
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchTranscripts(query: string, pageable: Pageable, options?: any): AxiosPromise<Array<TranscriptDTO>> {
      return localVarFp.searchTranscripts(query, pageable, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {TranscriptDTO} transcriptDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTranscript(id: number, transcriptDTO: TranscriptDTO, options?: any): AxiosPromise<TranscriptDTO> {
      return localVarFp.updateTranscript(id, transcriptDTO, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * TranscriptResourceApi - object-oriented interface
 * @export
 * @class TranscriptResourceApi
 * @extends {BaseAPI}
 */
export class TranscriptResourceApi extends BaseAPI {
  /**
   *
   * @param {Array<number>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptResourceApi
   */
  public alignTranscripts(requestBody: Array<number>, options?: AxiosRequestConfig) {
    return TranscriptResourceApiFp(this.configuration)
      .alignTranscripts(requestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TranscriptCriteria} criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptResourceApi
   */
  public countTranscripts(criteria: TranscriptCriteria, options?: AxiosRequestConfig) {
    return TranscriptResourceApiFp(this.configuration)
      .countTranscripts(criteria, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TranscriptDTO} transcriptDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptResourceApi
   */
  public createTranscript(transcriptDTO: TranscriptDTO, options?: AxiosRequestConfig) {
    return TranscriptResourceApiFp(this.configuration)
      .createTranscript(transcriptDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptResourceApi
   */
  public deleteTranscript(id: number, options?: AxiosRequestConfig) {
    return TranscriptResourceApiFp(this.configuration)
      .deleteTranscript(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TranscriptCriteria} criteria
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptResourceApi
   */
  public getAllTranscripts(criteria: TranscriptCriteria, pageable: Pageable, options?: AxiosRequestConfig) {
    return TranscriptResourceApiFp(this.configuration)
      .getAllTranscripts(criteria, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptResourceApi
   */
  public getTranscript(id: number, options?: AxiosRequestConfig) {
    return TranscriptResourceApiFp(this.configuration)
      .getTranscript(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {TranscriptDTO} transcriptDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptResourceApi
   */
  public partialUpdateTranscript(id: number, transcriptDTO: TranscriptDTO, options?: AxiosRequestConfig) {
    return TranscriptResourceApiFp(this.configuration)
      .partialUpdateTranscript(id, transcriptDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} query
   * @param {Pageable} pageable
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptResourceApi
   */
  public searchTranscripts(query: string, pageable: Pageable, options?: AxiosRequestConfig) {
    return TranscriptResourceApiFp(this.configuration)
      .searchTranscripts(query, pageable, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {TranscriptDTO} transcriptDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TranscriptResourceApi
   */
  public updateTranscript(id: number, transcriptDTO: TranscriptDTO, options?: AxiosRequestConfig) {
    return TranscriptResourceApiFp(this.configuration)
      .updateTranscript(id, transcriptDTO, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UserResourceApi - axios parameter creator
 * @export
 */
export const UserResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {UserDTO} userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (userDTO: UserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userDTO' is not null or undefined
      assertParamExists('createUser', 'userDTO', userDTO);
      const localVarPath = `/api/admin/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser: async (login: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'login' is not null or undefined
      assertParamExists('deleteUser', 'login', login);
      const localVarPath = `/api/admin/users/{login}`.replace(`{${'login'}}`, encodeURIComponent(String(login)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (login: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'login' is not null or undefined
      assertParamExists('getUser', 'login', login);
      const localVarPath = `/api/admin/users/{login}`.replace(`{${'login'}}`, encodeURIComponent(String(login)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {UserDTO} userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (id: number, userDTO: UserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateUser', 'id', id);
      // verify required parameter 'userDTO' is not null or undefined
      assertParamExists('updateUser', 'userDTO', userDTO);
      const localVarPath = `/api/admin/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserResourceApi - functional programming interface
 * @export
 */
export const UserResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {UserDTO} userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      userDTO: UserDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUser(
      login: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(login, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      login: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(login, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {UserDTO} userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      id: number,
      userDTO: UserDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, userDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserResourceApi - factory interface
 * @export
 */
export const UserResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserResourceApiFp(configuration);
  return {
    /**
     *
     * @param {UserDTO} userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(userDTO: UserDTO, options?: any): AxiosPromise<User> {
      return localVarFp.createUser(userDTO, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(login: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteUser(login, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsers(options?: any): AxiosPromise<Array<UserDTO>> {
      return localVarFp.getAllUsers(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(login: string, options?: any): AxiosPromise<UserDTO> {
      return localVarFp.getUser(login, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {UserDTO} userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(id: number, userDTO: UserDTO, options?: any): AxiosPromise<UserDTO> {
      return localVarFp.updateUser(id, userDTO, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UserResourceApi - object-oriented interface
 * @export
 * @class UserResourceApi
 * @extends {BaseAPI}
 */
export class UserResourceApi extends BaseAPI {
  /**
   *
   * @param {UserDTO} userDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public createUser(userDTO: UserDTO, options?: AxiosRequestConfig) {
    return UserResourceApiFp(this.configuration)
      .createUser(userDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} login
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public deleteUser(login: string, options?: AxiosRequestConfig) {
    return UserResourceApiFp(this.configuration)
      .deleteUser(login, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public getAllUsers(options?: AxiosRequestConfig) {
    return UserResourceApiFp(this.configuration)
      .getAllUsers(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} login
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public getUser(login: string, options?: AxiosRequestConfig) {
    return UserResourceApiFp(this.configuration)
      .getUser(login, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {UserDTO} userDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public updateUser(id: number, userDTO: UserDTO, options?: AxiosRequestConfig) {
    return UserResourceApiFp(this.configuration)
      .updateUser(id, userDTO, options)
      .then(request => request(this.axios, this.basePath));
  }
}
